import { createSlice } from '@reduxjs/toolkit'

export interface TableOrder {
  id: string,
  desc: boolean
}

export interface TableFiltersValues {
  id: string,
  value: any
}

export interface TableFilters {
  enabled: boolean,
  values: TableFiltersValues[]
}

export interface TablePreferences {
  table: string,
  page: number,
  orders: TableOrder[],
  maxRows: number,
  filters: TableFilters
}

const slice = createSlice({
  name: 'tablePref',
  initialState: {
    data: []
  },
  reducers: {
    add: (state, action) => {
      state.data.push(action.payload);
    },
    update: (state, action) => {
      const index = state.data.findIndex(obj => obj.table === action.payload.table)
      let newState = [
        ...state.data.slice(0, index),
        action.payload,
        ...state.data.slice(index + 1),
      ]

      state.data = newState
    }
  },
})

export const actions = slice.actions;
export default slice.reducer;