import type { FC } from "react";
import { useEffect, useState } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box, Breadcrumbs, /* Button, */
  Container, Card,
  Grid,
  Link,
  IconButton,
  /*  Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow, */
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@material-ui/core';

import Scrollbar from '../../material/Scrollbar';
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import { useSelector as useReduxSelector } from '../../store'
import { deleteDocType, loadTypes } from './commands.tipodoc'
import { DocumentType } from "../../types/generated";
import DeleteIcon from '@material-ui/icons/Delete';
import dayjs from 'dayjs';


/*****************************************/
import Button_MUI from '@material-ui/core/Button';
import { Button, TextButton } from '../../components/Button/Button'
import { TextField } from '../../components/TextField/TextField'
import { Table, DatePickerRangeFilter, DatePickerRangeFilterMethod } from '../../components/Table/Table'
/*****************************************/
import moment from 'moment';
import { matchSorter } from 'match-sorter'
import { useSnackbar } from "notistack";
import ArrowRight from "src/icons/ArrowRight";


const PaginaDocTypes: FC = (props) => {
  const state = useReduxSelector(state => state.doctypes.data)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [toDelete, setToDelete] = useState("")
  const { settings } = useSettings();
  const handleOpenDeleteDialog = (name: string) => {
    setOpenDeleteDialog(true)
    setToDelete(name)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
    setToDelete("")
  }

  const deleteEntry = async (name: string) => {
    const res = await deleteDocType(name)
    if (res.error) {
      handleCloseDeleteDialog()
      enqueueSnackbar("Impossibile eliminare la tipologia", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
      console.error(res.message)
      return
    }

    handleCloseDeleteDialog()
    loadTypes()
    enqueueSnackbar("Tipologia eliminata", {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      variant: 'success'
    });
  }

  const { ...other } = props;

  useEffect(() => {
    loadTypes();
  }, [])

  return (
    <>
      <Helmet>
        <title>Documenti </title>
      </Helmet>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} aria-labelledby="delete-dialog">
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              Azione irreversibile, continuare?
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary" variant="contained" label='Annulla' />
          <Button onClick={() => deleteEntry(toDelete)} color="secondary" variant="contained" label='Elimina definitivamente' />
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Tipologie Documenti
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Tipologie Documenti
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <RouterLink
                  to={{
                    pathname: "/tipologie/documenti/nuova",
                  }}
                  style={{
                    textDecoration: 'none'
                  }}>
                  <Button
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    label='Nuova tipologia'
                  />
                  {/* Nuova tipologia
                  </Button> */}
                </RouterLink>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Scrollbar>
                <Box sx={{ minWidth: 1200 }}>


                  <Table
                    Data={state}
                    Columns={[
                      {
                        Header: 'Nome',
                        accessor: 'name',

                      },
                      {
                        Header: 'Data creazione',
                        accessor: 'createdAt',
                        width: 300,
                        id: 'data',
                        Cell: (row) => {
                          let date = moment(new Date(row.value)).format("DD/MM/YYYY");
                          return date
                        },
                        Filter: DatePickerRangeFilter,
                        filter: DatePickerRangeFilterMethod
                      },
                      {
                        Header: 'Rules associate',
                        accessor: 'documentRules',
                        id: 'regole',
                        Cell: (rows) => {
                          if (rows.value === null)
                            return '-'
                          return (
                            <div>
                              {
                                rows.value?.map(
                                  (rule) => rule.description + ', '
                                )
                              }
                            </div>
                          )
                        },
                        filter: 'array',

                      },
                      {
                        Header: 'Azioni',
                        width: 100,
                        Cell: (rows) => {
                          return (
                            <div>
                              <Tooltip title={"Dettagli"}>
                                <IconButton
                                  aria-label="Dettagli"
                                  href={`/tipologie/documenti/dettagli/${rows.row.values.name}`}
                                >
                                  <ArrowRight fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"Elimina"}>
                                <IconButton
                                  disabled={rows.row.original.documentRules}
                                  aria-label='Elimina'
                                  color="secondary"
                                  onClick={() => handleOpenDeleteDialog(rows.row.values.name)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )
                        }
                      },
                    ]}
                  />

                </Box>
              </Scrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default PaginaDocTypes
