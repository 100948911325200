import store from "../../store";

import {actions} from './redux.user';
import GraphqlClient from "../../client/graphql.client";
import {UserInputWithPassword} from "../../types/generated";

export const readAll = async () => {
    try {
        const res = await GraphqlClient.users({});
        store.dispatch(actions.readedAll(res.users));
        return {data: res.users}
    } catch (error) {
        store.dispatch(actions.readedAll([]));
        return {error: true, message: JSON.parse(JSON.stringify(error)).response.error}
    }
};

export const createUser = async (form: UserInputWithPassword) => {
    try {
        const res = await GraphqlClient.createUser({user: form});
        store.dispatch(actions.created(res.createUser));
        return {data: res.createUser}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};

export const deleteUserQuery = async (email: string) => {
    try {
        const res = await GraphqlClient.deleteUser({email})
        return { data: res.deleteUser }
    } catch (err) {
        return { error: true, message: err.toString()}
    }
}
