import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'dossiertypes',
  initialState: {
    data: [],
  },
  reducers: {
    load: (state, action) => {
      state.data = action.payload
    },
    created: (state, action) => {
      state.data.push(action.payload);
    }
  },
})

export const actions = slice.actions;
export default slice.reducer;