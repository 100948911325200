import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GraphqlClient from 'src/client/graphql.client'
import { TableInput } from 'src/types/generated'

const fetchTableData = createAsyncThunk(
  'table/dossier/logs',
  async (payload: TableInput, thunkAPI) => {
    const current = thunkAPI.getState()
    // @ts-ignore
    // if (current.dossierLogs.options.length > 0) {
    //   payload.selectOptions = []
    // }

    const response = await GraphqlClient.getPaginatedDossierCodeExecutions({ input: payload })
    return response.getPaginatedDossierCodeExecutions
  }
)

const slice = createSlice({
  name: 'dossierExecLogs',
  initialState: {
    loading: false,
    data: [],
    pages: 0,
    options: [],
    op: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchTableData.pending, (state, action) => {
      state.error = null
      state.loading = true
    })

    builder.addCase(fetchTableData.fulfilled, (state, action) => {
      state.data = action.payload.items
      state.pages = action.payload.pages
      // if (state.options.length == 0) {
      //   state.options = action.payload.options
      // }
      state.op = action.payload.op
      state.loading = false
    })

    builder.addCase(fetchTableData.rejected, (state, action) => {
      state.error = action.error
      state.loading = false
    })
  },
})

export const actions = slice.actions;
export default slice.reducer;
export const fetchAsyncData = fetchTableData
