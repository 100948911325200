import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'users',
    initialState: {
        data: []
    },
    reducers: {
        readedAll: (state, action) => {
            state.data = action.payload;
        },
        created: (state, action) => {
            state.data.push(action.payload);
        },
    },
})

export const actions = slice.actions;
export default slice.reducer;
