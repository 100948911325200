import type { FC } from "react";
import { ChangeEvent, useEffect, useState } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box, Breadcrumbs,
  Container, Card,
  Grid,
  Link,
  IconButton,
  Typography,
  Tooltip,
  CardContent, Chip,
  CardHeader,
  Divider,
  CircularProgress, Tabs, Tab,
} from '@material-ui/core';
import { useSnackbar } from "notistack";
import Scrollbar from '../../material/Scrollbar';
import ChevronRightIcon from "../../icons/ChevronRight";
import { fetchValidationLogs } from "./commands.validazione";
import { Table, DatePickerRangeFilter, DatePickerRangeFilterMethod } from "src/components/Table/Table";
import ArrowRightIcon from '../../icons/ArrowRight';

const DettagliValidazione: FC = (props) => {
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams()
  const id = params.id

  const [logs, setLogs] = useState([])

  useEffect(() => {
    const loadLogs = async () => {
      let res = await fetchValidationLogs(id)
      if (res.error) {
        console.error(res.message)
        enqueueSnackbar("Impossibile caricare i log", {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
        return
      }

      setLogs(res.data)
    }

    loadLogs()
  }, [])
  return (
    <>
      <Helmet>
        <title>Validazione Regole</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Validazione Regole
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/validazione"
                  variant="subtitle2"
                >
                  Validazione Regole
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {id}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card>
              <CardHeader title="Regole non valide" />
              <Table
                Data={logs || []}
                Columns={[
                  {
                    Header: "ID Regola",
                    accessor: "idRule"
                  },
                  {
                    Header: "Descrizione",
                    accessor: "description"
                  },
                  {
                    Header: 'Azioni',
                    width: 60,
                    Cell: (rows) => {
                      return (
                        <div>
                          <Tooltip title="Dettagli">
                            <IconButton
                              aria-label='Dettagli'
                              component={RouterLink}
                              to={`/playground/dossier/${rows.row.original.idRule}/aggiorna`}
                            >
                              <ArrowRightIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                    }
                  },
                ]}
              />
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default DettagliValidazione