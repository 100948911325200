import { FC, useEffect, useState } from 'react'
import { DataTable, DocumentRule, DocumentType } from 'src/types/generated'
import { useSnackbar } from 'notistack'
import GraphqlClient from 'src/client/graphql.client'
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../../hooks/useSettings';
import {
  Autocomplete,
  Box, Breadcrumbs, Button,
  Container, Card, Chip,
  Grid,
  Link,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  CardContent,
  TextField,
} from '@material-ui/core';

import ChevronRightIcon from "../../../icons/ChevronRight";
import PlusIcon from "../../../icons/Plus";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightIcon from '../../../icons/ArrowRight';
import { isSet } from 'lodash';
import { newDocumentRule } from '../commands.play';
import { height } from '@material-ui/system';

const defaultCode = `/*
    @LoanManager
    La variabile extractedData contiene:
    {
        rawText, // testo estratto dal documento
        tables, // tabelle estratte dal documento
        rawTextWithGeometry, // testo estratto dal documento con relativa posizione
        dataTables, // data tables scelte durante la creazione della regola
        formData: // dati in forma chiave:valore estratti dal documento
    }

    Per resituire un errore utilizzare una tra:
    - console.errore("errore qua...") // in questo caso il codice prosegue l'esecuzione
    - throw("erroe qua") // stoppa anche l'esecuzione del codice
*/

export default (extractedData) => {
    /* Codice regola */
    console.log('DataTables: ', JSON.stringify(extractedData.dataTables));
    console.log('RawText: ', extractedData.rawText);
    console.log('FormData: ', JSON.stringify(extractedData.formData));
    console.log('Raw text with geometry', JSON.stringify(extractedData.rawTextWithGeometry ));
    console.log('Tables', JSON.stringify(extractedData.tables));
    
    return {
        success: true 
    }
}`;

const defaultPackageJSON = `{
    "name": "rule",
    "version": "1.0.0",
    "main": "index.js",
    "license": "MIT",
    "type": "module"
}`

const PlaygroundNuovaDocRule: FC = (props) => {
  const { settings } = useSettings()
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState<string>("")
  const [ruleType, setRuleType] = useState<DocumentType>({} as DocumentType)
  const [selectedDatatable, setSelectedDatatables] = useState<DataTable[]>([] as DataTable[])

  const [datatables, setDatatables] = useState<DataTable[]>([] as DataTable[])
  const [types, setTypes] = useState<DocumentType[]>([] as DocumentType[])

  let returnUpdateValue: DocumentRule

  const { ...other } = props

  const handleSubmit = async (name: string, ruleType: DocumentType, datatables: DataTable[]) => {
    if (!name || !ruleType || !datatables) {
      enqueueSnackbar("Tutti i campi sono obbligatori", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
      return;
    }

    var dts = datatables.map(function (o) {
      return o.name
    });

    try {
      let res = await newDocumentRule({
        documentType: ruleType.name,
        description: name,
        code: defaultCode,
        dataTables: dts,
        packageJSON: defaultPackageJSON,
      })
      returnUpdateValue = res.data
      enqueueSnackbar("Regola salvata con successo", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
    } catch (e) {
      console.error('Impossibile salvare la regola: ', e);
      enqueueSnackbar("Impossibile salvare la regola", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    } finally {
      navigate(`/playground/documenti/${returnUpdateValue.id}/aggiorna`)
    }
    return
  }

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        setLoadingData(true)
        const res = await GraphqlClient.documentTypes();
        setTypes(res.documentTypes);
      } catch (e) {
        console.error('Impossibile ottenere le tipologie: ', e);
        enqueueSnackbar("Impossibile ottenere le tipologie", {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      } finally {
        setLoadingData(false);
      }
    }

    const fetchDatatables = async () => {
      try {
        setLoadingData(true)
        const res = await GraphqlClient.dataTables();
        setDatatables(res.dataTables);
      } catch (e) {
        console.error('Impossibile ottenere le datatables: ', e);
        enqueueSnackbar("Impossibile ottenere le datatables", {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      } finally {
        setLoadingData(false);
      }
    }

    fetchTypes();
    fetchDatatables()
  }, [])

  return (
    <>
      <Helmet>
        <title>Nuova regola </title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Nuova regola
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/playground/documenti"
                  variant="subtitle2"
                >
                  Playground Documenti
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Nuova regola
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }} height={400}>
            <Card {...other} style={{height: '100%'}} >
              <CardContent style={{height: '100%'}}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: '100%'
                  }}>
                    <TextField
                      required
                      value={name}
                      onChange={e => { setName(e.target.value) }}
                      label="Descrizione"
                      style={{ width: 300 }}
                    />
                    <Autocomplete
                      onChange={(_: any, newValue: DocumentType) => {
                        setRuleType(newValue);
                      }}
                      id="combo-box-rules"
                      options={types}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option?.name === value?.name}
                      style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} required label="Seleziona tipologia"
                        variant="outlined" />}
                    />
                    <Autocomplete
                      multiple
                      onChange={(_, newValues: DataTable[]) => {
                        setSelectedDatatables(newValues)
                      }}
                      id="combo-box-rules"
                      options={datatables}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option?.name === value?.name}
                      style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Seleziona datatable"
                        variant="outlined" />}
                    />
                    <Button
                      variant="contained"
                      onClick={() => { handleSubmit(name, ruleType, selectedDatatable) }}
                      style={{width: 200}}
                    >
                      Salva
                    </Button>
                  </div>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default PlaygroundNuovaDocRule
