import type { GraphQLClient } from 'graphql-request';
import type * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Byte: any;
  Json: any;
  Map: any;
  Time: any;
  UnixDate: any;
  Upload: any;
};






export type Callback = {
  __typename?: 'Callback';
  dossierId: Scalars['String'];
  url: Scalars['String'];
  success: Scalars['Boolean'];
  method: Scalars['String'];
  requestBody: Scalars['String'];
  responseCode: Scalars['Int'];
  responseBody: Scalars['String'];
  timestamp: Scalars['Int'];
  errorDescription: Scalars['String'];
  idValutazione: Scalars['String'];
};

export type CodeResponse = {
  __typename?: 'CodeResponse';
  result: Scalars['String'];
  logs: Array<Scalars['String']>;
};

export type CountReport = {
  __typename?: 'CountReport';
  processed: Scalars['Int'];
  total: Scalars['Int'];
};

export type DataTable = {
  __typename?: 'DataTable';
  name: Scalars['ID'];
  ordinamento: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
};

export type DocValidation = {
  __typename?: 'DocValidation';
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  istitutoAppartenenza?: Maybe<Scalars['String']>;
  urlFile?: Maybe<Scalars['String']>;
  urlOCR?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Int']>;
};

export type DocValidationUpload = {
  description?: Maybe<Scalars['String']>;
  istitutoAppartenenza?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['ID'];
  url: Scalars['String'];
  metas?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['Map']>;
  tables?: Maybe<Scalars['String']>;
  rawTextWithGeometry?: Maybe<Scalars['String']>;
  rawText?: Maybe<Scalars['String']>;
  documentType?: Maybe<DocumentType>;
  dossier?: Maybe<Dossier>;
};

export type DocumentCodeExecution = {
  __typename?: 'DocumentCodeExecution';
  id: Scalars['String'];
  documentId: Scalars['String'];
  ruleId: Scalars['String'];
  ruleName: Scalars['String'];
  executedAt: Scalars['Int'];
  code: Scalars['String'];
  rawText: Scalars['String'];
  rawTextWithGeometry: Scalars['String'];
  tables: Scalars['String'];
  dataTables: Scalars['String'];
  formData: Scalars['String'];
  state: Scalars['String'];
  result: Scalars['String'];
  errorLog?: Maybe<Array<Scalars['String']>>;
  logs?: Maybe<Array<Scalars['String']>>;
  platform?: Maybe<Scalars['String']>;
};

export type DocumentCodeExecutionTable = {
  __typename?: 'DocumentCodeExecutionTable';
  pages: Scalars['Int'];
  items?: Maybe<Array<Maybe<DocumentCodeExecution>>>;
  options?: Maybe<Array<Maybe<TableSelectOptions>>>;
  op?: Maybe<Array<Maybe<TableOperation>>>;
};

export type DocumentDownload = {
  __typename?: 'DocumentDownload';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  base64?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
};

export type DocumentName = {
  __typename?: 'DocumentName';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DocumentRule = {
  __typename?: 'DocumentRule';
  id: Scalars['ID'];
  description: Scalars['String'];
  code: Scalars['String'];
  dataTables?: Maybe<Array<Maybe<DataTable>>>;
  documentType?: Maybe<DocumentType>;
  packageJSON: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  nota?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
  documentRules?: Maybe<Array<Maybe<DocumentRule>>>;
};

export type Dossier = {
  __typename?: 'Dossier';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
  metas?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  fullDescription?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileTextContent?: Maybe<Scalars['String']>;
  input?: Maybe<Scalars['String']>;
  dossierTypeName?: Maybe<Scalars['String']>;
  dossierType?: Maybe<DossierType>;
  document?: Maybe<Array<Maybe<Document>>>;
};

export type DossierCodeExecuted = {
  __typename?: 'DossierCodeExecuted';
  id?: Maybe<Scalars['String']>;
  dossierId?: Maybe<Scalars['String']>;
  ruleName?: Maybe<Scalars['String']>;
  ruleId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  metas?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  executedAt?: Maybe<Scalars['Int']>;
  logs?: Maybe<Array<Maybe<Scalars['String']>>>;
  errorLog?: Maybe<Array<Maybe<Scalars['String']>>>;
  platform?: Maybe<Scalars['String']>;
};

export type DossierCodeExecution = {
  __typename?: 'DossierCodeExecution';
  id: Scalars['String'];
  dossierId: Scalars['String'];
  ruleId: Scalars['String'];
  ruleName: Scalars['String'];
  executedAt: Scalars['Int'];
  metas: Scalars['String'];
  code: Scalars['String'];
  state: Scalars['String'];
  result: Scalars['String'];
  errors?: Maybe<Array<Scalars['String']>>;
  logs?: Maybe<Array<Scalars['String']>>;
  platform?: Maybe<Scalars['String']>;
  input?: Maybe<Scalars['String']>;
};

export type DossierCodeExecutionTable = {
  __typename?: 'DossierCodeExecutionTable';
  pages: Scalars['Int'];
  items?: Maybe<Array<Maybe<DossierCodeExecuted>>>;
  options?: Maybe<Array<Maybe<TableSelectOptions>>>;
  op?: Maybe<Array<Maybe<TableOperation>>>;
};

export type DossierDownload = {
  __typename?: 'DossierDownload';
  base64?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
};

export type DossierInput = {
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
  metas?: Maybe<Scalars['String']>;
  dossierTypeName: Scalars['String'];
};

export type DossierRule = {
  __typename?: 'DossierRule';
  id: Scalars['ID'];
  description: Scalars['String'];
  istitutoAppartenenza: Scalars['String'];
  code: Scalars['String'];
  dataTables?: Maybe<Array<Maybe<Scalars['String']>>>;
  dossierType?: Maybe<DossierType>;
  packageJSON: Scalars['String'];
  valid: Scalars['Boolean'];
  mustContain?: Maybe<Array<Maybe<Scalars['String']>>>;
  notContain?: Maybe<Array<Maybe<Scalars['String']>>>;
  nota?: Maybe<Scalars['String']>;
  gruppo?: Maybe<Array<Maybe<Scalars['String']>>>;
  active?: Maybe<Scalars['Boolean']>;
};

export type DossierRuleExecTable = {
  __typename?: 'DossierRuleExecTable';
  pages: Scalars['Int'];
  items?: Maybe<Array<Maybe<DossierRuleExecution>>>;
  options?: Maybe<Array<Maybe<TableSelectOptions>>>;
  op?: Maybe<Array<Maybe<TableOperation>>>;
};

export type DossierRuleExecution = {
  __typename?: 'DossierRuleExecution';
  executionID?: Maybe<Scalars['String']>;
  ruleID: Scalars['String'];
  inputKey?: Maybe<Scalars['String']>;
  dossierID: Scalars['String'];
  inputIndex?: Maybe<Scalars['Int']>;
  input?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Int']>;
  finishedAt?: Maybe<Scalars['Int']>;
  ruleName?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  groupExecutionID?: Maybe<Scalars['String']>;
};

export type DossierTable = {
  __typename?: 'DossierTable';
  pages: Scalars['Int'];
  items?: Maybe<Array<Maybe<Dossier>>>;
  options?: Maybe<Array<Maybe<TableSelectOptions>>>;
  op?: Maybe<Array<Maybe<TableOperation>>>;
};

export type DossierType = {
  __typename?: 'DossierType';
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
  dossierRules?: Maybe<Array<Maybe<DossierRule>>>;
};

export type FilterInput = {
  column: Scalars['String'];
  value?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  subject: Scalars['String'];
  role: Scalars['String'];
  domain: Scalars['String'];
};

export type HelperCode = {
  __typename?: 'HelperCode';
  id: Scalars['ID'];
  description: Scalars['String'];
  code: Scalars['String'];
};


export type LogDocument = {
  __typename?: 'LogDocument';
  id: Scalars['ID'];
  event: Scalars['String'];
  timestamp?: Maybe<Scalars['Time']>;
  document?: Maybe<Document>;
};

export type LogDossier = {
  __typename?: 'LogDossier';
  id: Scalars['ID'];
  event: Scalars['String'];
  timestamp?: Maybe<Scalars['Time']>;
  dossier?: Maybe<Dossier>;
};

export type LogValidazione = {
  __typename?: 'LogValidazione';
  idValidazione?: Maybe<Scalars['String']>;
  idRule?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MetaConflict = {
  __typename?: 'MetaConflict';
  value: Scalars['String'];
  count: Scalars['Int'];
};

export type MetaConflicts = {
  __typename?: 'MetaConflicts';
  name: Scalars['String'];
  conflicts: Array<MetaConflict>;
};

export type MetaValue = {
  value: Scalars['String'];
  name: Scalars['String'];
};

export type MetasCheck = {
  __typename?: 'MetasCheck';
  valid: Scalars['Boolean'];
  missingRequired: Array<Scalars['String']>;
  conflicts: Array<MetaConflicts>;
};

export type Mutation = {
  __typename?: 'Mutation';
  pingMutation: Scalars['String'];
  AddPolicy?: Maybe<Policy>;
  AddGroup?: Maybe<Group>;
  createDataTable: DataTable;
  updateDataTable: DataTable;
  deleteDataTable: Scalars['Boolean'];
  createDocValidations?: Maybe<Array<Maybe<DocValidation>>>;
  deleteDocValidations: Scalars['Int'];
  createDocument: Document;
  updateDocument: Document;
  deleteDocument?: Maybe<Document>;
  deleteFormData: Document;
  /** appende metas a quelli gia' esistenti */
  addDocumentMetas?: Maybe<Document>;
  /** svuota il campo metas */
  clearDocumentMetas?: Maybe<Document>;
  /** rimpiazza l'intero campo metas */
  replaceDocumentMetas?: Maybe<Document>;
  runDocumentRule: Scalars['String'];
  downloadDocumentRule: Zipped;
  runTemporaryRule: Scalars['String'];
  createDocumentRule: DocumentRule;
  updateDocumentRule: DocumentRule;
  deleteDocumentRule?: Maybe<DocumentRule>;
  createDocumentType: DocumentType;
  updateDocumentType: DocumentType;
  deleteDocumentType?: Maybe<DocumentType>;
  createDossier: Dossier;
  updateDossier: Dossier;
  deleteDossier?: Maybe<Dossier>;
  /** appende metas a quelli gia' esistenti */
  addDossierMetas?: Maybe<Dossier>;
  /** svuota il campo metas */
  clearDossierMetas?: Maybe<Dossier>;
  /** rimpiazza l'intero campo metas */
  replaceDossierMetas?: Maybe<Dossier>;
  runDossierRule: Scalars['String'];
  downloadDossierRule: Zipped;
  runDossierRules?: Maybe<Array<Scalars['String']>>;
  runTemporaryDossierRule: Scalars['String'];
  runAgainDossierRulesThatHasErrors?: Maybe<Array<Scalars['String']>>;
  createDossierRule: DossierRule;
  updateDossierRule: DossierRule;
  deleteDossierRule?: Maybe<DossierRule>;
  updateMustContain?: Maybe<Array<Scalars['String']>>;
  updateNotContain?: Maybe<Array<Scalars['String']>>;
  importDossierRules?: Maybe<Array<Maybe<DossierRule>>>;
  createDossierType: DossierType;
  updateDossierType: DossierType;
  deleteDossierType: Scalars['Boolean'];
  updateHelperCode: Scalars['Boolean'];
  addDocumentLog?: Maybe<LogDocument>;
  addDossierLog?: Maybe<LogDossier>;
  insertLogValidazione?: Maybe<LogValidazione>;
  addNotificationReceiver: Array<NotificationReceiver>;
  deleteNotificationReceiver: Array<NotificationReceiver>;
  createRuleValidation?: Maybe<RuleValidation>;
  uploadRuleValidation?: Maybe<RuleValidation>;
  testRule?: Maybe<Scalars['Boolean']>;
  createUser: TokenDetails;
  deleteUser: Scalars['Boolean'];
  updateUser: User;
  updateAvatar: User;
  updateSelfPassword: User;
  updateUserPassword: User;
  recoverPassword: Scalars['Boolean'];
  resetPassword: TokenDetails;
  impersonates: TokenDetails;
  createRole?: Maybe<Role>;
};


export type MutationAddPolicyArgs = {
  subject: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
};


export type MutationAddGroupArgs = {
  subject: Scalars['String'];
  role: Scalars['String'];
};


export type MutationCreateDataTableArgs = {
  input: NewDataTable;
};


export type MutationUpdateDataTableArgs = {
  name: Scalars['String'];
  input: UpdateDataTable;
};


export type MutationDeleteDataTableArgs = {
  name: Scalars['String'];
};


export type MutationCreateDocValidationsArgs = {
  files: Array<DocValidationUpload>;
};


export type MutationDeleteDocValidationsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationCreateDocumentArgs = {
  input: NewDocument;
};


export type MutationUpdateDocumentArgs = {
  id: Scalars['ID'];
  input: UpdateDocument;
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFormDataArgs = {
  id: Scalars['ID'];
};


export type MutationAddDocumentMetasArgs = {
  id: Scalars['ID'];
  metas: Scalars['String'];
};


export type MutationClearDocumentMetasArgs = {
  id: Scalars['ID'];
};


export type MutationReplaceDocumentMetasArgs = {
  id: Scalars['ID'];
  metas: Scalars['String'];
};


export type MutationRunDocumentRuleArgs = {
  id: Scalars['ID'];
  ruleId: Scalars['ID'];
};


export type MutationDownloadDocumentRuleArgs = {
  id: Scalars['ID'];
  ruleId: Scalars['ID'];
};


export type MutationRunTemporaryRuleArgs = {
  id: Scalars['ID'];
  ruleID: Scalars['ID'];
  ruleName: Scalars['String'];
  code: Scalars['String'];
  packagecode: Scalars['String'];
  dataTables?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateDocumentRuleArgs = {
  input: NewDocumentRule;
};


export type MutationUpdateDocumentRuleArgs = {
  id: Scalars['String'];
  input: UpdateDocumentRule;
};


export type MutationDeleteDocumentRuleArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDocumentTypeArgs = {
  input: NewDocumentType;
};


export type MutationUpdateDocumentTypeArgs = {
  name: Scalars['String'];
  input: UpdateDocumentType;
};


export type MutationDeleteDocumentTypeArgs = {
  name: Scalars['String'];
};


export type MutationCreateDossierArgs = {
  input: DossierInput;
};


export type MutationUpdateDossierArgs = {
  id: Scalars['ID'];
  input: UpdateDossier;
};


export type MutationDeleteDossierArgs = {
  id: Scalars['ID'];
};


export type MutationAddDossierMetasArgs = {
  id: Scalars['ID'];
  metas: Scalars['String'];
};


export type MutationClearDossierMetasArgs = {
  id: Scalars['ID'];
};


export type MutationReplaceDossierMetasArgs = {
  id: Scalars['ID'];
  metas: Scalars['String'];
};


export type MutationRunDossierRuleArgs = {
  id: Scalars['ID'];
  ruleId: Scalars['ID'];
  groupId?: Maybe<Scalars['ID']>;
};


export type MutationDownloadDossierRuleArgs = {
  id: Scalars['ID'];
  ruleId: Scalars['ID'];
};


export type MutationRunDossierRulesArgs = {
  id: Scalars['ID'];
};


export type MutationRunTemporaryDossierRuleArgs = {
  id: Scalars['ID'];
  ruleID: Scalars['ID'];
  ruleName: Scalars['String'];
  code: Scalars['String'];
  packagecode: Scalars['String'];
};


export type MutationRunAgainDossierRulesThatHasErrorsArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDossierRuleArgs = {
  input: NewDossierRule;
};


export type MutationUpdateDossierRuleArgs = {
  id: Scalars['String'];
  input: UpdateDossierRule;
};


export type MutationDeleteDossierRuleArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMustContainArgs = {
  id: Scalars['String'];
  input?: Maybe<Array<Scalars['String']>>;
};


export type MutationUpdateNotContainArgs = {
  id: Scalars['String'];
  input?: Maybe<Array<Scalars['String']>>;
};


export type MutationImportDossierRulesArgs = {
  input: Scalars['String'];
};


export type MutationCreateDossierTypeArgs = {
  input: NewDossierType;
};


export type MutationUpdateDossierTypeArgs = {
  name: Scalars['String'];
  input: UpdateDossierType;
};


export type MutationDeleteDossierTypeArgs = {
  name: Scalars['String'];
};


export type MutationUpdateHelperCodeArgs = {
  id: Scalars['ID'];
  code: Scalars['String'];
};


export type MutationAddDocumentLogArgs = {
  event: Scalars['String'];
  document: Scalars['ID'];
};


export type MutationAddDossierLogArgs = {
  event: Scalars['String'];
  dossier: Scalars['ID'];
};


export type MutationInsertLogValidazioneArgs = {
  idValutazione: Scalars['String'];
  idRule: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};


export type MutationAddNotificationReceiverArgs = {
  receiver: Scalars['String'];
};


export type MutationDeleteNotificationReceiverArgs = {
  receiver: Scalars['String'];
};


export type MutationCreateRuleValidationArgs = {
  documentID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url: Scalars['String'];
  istitutoAppartenenza: Scalars['String'];
};


export type MutationUploadRuleValidationArgs = {
  fileName: Scalars['String'];
  file: Scalars['String'];
  istitutoAppartenenza: Scalars['String'];
};


export type MutationTestRuleArgs = {
  docValidationID: Scalars['String'];
  mustContain?: Maybe<Array<Maybe<Scalars['String']>>>;
  notContain?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateUserArgs = {
  user?: Maybe<UserInputWithPassword>;
};


export type MutationDeleteUserArgs = {
  email: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  email: Scalars['String'];
  user?: Maybe<UpdateUserInput>;
};


export type MutationUpdateAvatarArgs = {
  email: Scalars['String'];
  avatar: Scalars['String'];
};


export type MutationUpdateSelfPasswordArgs = {
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUpdateUserPasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationRecoverPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationImpersonatesArgs = {
  email: Scalars['String'];
};


export type MutationCreateRoleArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type NewDataTable = {
  name: Scalars['String'];
  ordinamento: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
};

export type NewDocument = {
  url?: Maybe<Scalars['String']>;
  metas?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  dossierId: Scalars['String'];
  documentTypeName: Scalars['String'];
};

export type NewDocumentRule = {
  documentType: Scalars['String'];
  description: Scalars['String'];
  code: Scalars['String'];
  packageJSON: Scalars['String'];
  dataTables?: Maybe<Array<Maybe<Scalars['String']>>>;
  nota?: Maybe<Scalars['String']>;
};

export type NewDocumentType = {
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
};

export type NewDossierRule = {
  istitutoAppartenenza: Scalars['String'];
  dossierType?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  code: Scalars['String'];
  packageJSON: Scalars['String'];
  dataTables?: Maybe<Array<Maybe<Scalars['String']>>>;
  mustContain?: Maybe<Array<Maybe<Scalars['String']>>>;
  notContain?: Maybe<Array<Maybe<Scalars['String']>>>;
  nota?: Maybe<Scalars['String']>;
  gruppo?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NewDossierType = {
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
};

export type NotificationReceiver = {
  __typename?: 'NotificationReceiver';
  id: Scalars['String'];
};

export type Policy = {
  __typename?: 'Policy';
  subject: Scalars['String'];
  domain: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  ping: Scalars['String'];
  getPolicies?: Maybe<Array<Maybe<Policy>>>;
  getGroups?: Maybe<Array<Maybe<Group>>>;
  dataTable: DataTable;
  dataTables: Array<DataTable>;
  docValidation?: Maybe<DocValidation>;
  docValidations?: Maybe<Array<Maybe<DocValidation>>>;
  downloadOCR?: Maybe<Scalars['String']>;
  downloadPDF?: Maybe<Scalars['String']>;
  downloadDiff: Scalars['String'];
  downloadAllValidationFiles: Zipped;
  document: Document;
  documents: Array<Document>;
  documentsInfo: Array<Document>;
  documentsNameForDossier: Array<DocumentName>;
  downloadDocument?: Maybe<DocumentDownload>;
  downloadDocuments?: Maybe<Array<Maybe<DocumentDownload>>>;
  documentsByDossier?: Maybe<Array<Maybe<Document>>>;
  runDocumentRules?: Maybe<Array<Scalars['String']>>;
  getDocumentCodeExecutions?: Maybe<Array<Maybe<DocumentCodeExecution>>>;
  getAllDocumentCodeExecutions?: Maybe<Array<Maybe<DocumentCodeExecution>>>;
  getPaginatedDocExecutions?: Maybe<DocumentCodeExecutionTable>;
  documentRule: DocumentRule;
  documentRules: Array<DocumentRule>;
  getRulesByDocument?: Maybe<Array<Maybe<DocumentRule>>>;
  getDocumentRuleExecutions?: Maybe<Array<Maybe<DocumentCodeExecution>>>;
  setDocumentRuleActive: DocumentRule;
  documentType: DocumentType;
  documentTypes: Array<DocumentType>;
  dossiers: Array<Dossier>;
  dossier: Dossier;
  downloadDossier?: Maybe<DossierDownload>;
  getDossierCodeExecutions?: Maybe<Array<Maybe<DossierCodeExecution>>>;
  getAllDossierCodeExecutions?: Maybe<Array<Maybe<DossierCodeExecution>>>;
  getPaginatedDossierCodeExecutions?: Maybe<DossierCodeExecutionTable>;
  getPaginatedDossier?: Maybe<DossierTable>;
  dossierCallbacks: Array<Callback>;
  metasCheck: MetasCheck;
  fixMetas: Scalars['Boolean'];
  dossierRule: DossierRule;
  dossierRules: Array<DossierRule>;
  notValidDossierRules?: Maybe<Array<Maybe<DossierRule>>>;
  getDossierRuleLogs?: Maybe<Array<Maybe<DossierCodeExecution>>>;
  setDossierRuleActive?: Maybe<DossierRule>;
  getAllDossierRuleExecutions?: Maybe<Array<Maybe<DossierRuleExecution>>>;
  getDossierRuleExecutions?: Maybe<Array<Maybe<DossierRuleExecution>>>;
  getDossierRuleExecutionsByDossier?: Maybe<Array<Maybe<DossierRuleExecution>>>;
  getDossierRuleExecutionsByRule?: Maybe<Array<Maybe<DossierRuleExecution>>>;
  getDossierRulesExecutionCount?: Maybe<CountReport>;
  getDossierResultAggregate: Scalars['String'];
  getFailedDossierRuleExecs: Scalars['Int'];
  getPaginatedDossierRuleExec: DossierRuleExecTable;
  dossierType: DossierType;
  dossierTypes: Array<DossierType>;
  getHelperCode?: Maybe<HelperCode>;
  runCode?: Maybe<CodeResponse>;
  getDocumentLogs?: Maybe<Array<Maybe<LogDocument>>>;
  getLogsByDocument?: Maybe<Array<Maybe<LogDocument>>>;
  getDossierLogs?: Maybe<Array<Maybe<LogDossier>>>;
  getLogsByDossier?: Maybe<Array<Maybe<LogDossier>>>;
  logValidazione?: Maybe<Array<Maybe<LogValidazione>>>;
  logValidazioni?: Maybe<Array<Maybe<LogValidazione>>>;
  notificationReceivers: Array<NotificationReceiver>;
  ruleValidations?: Maybe<Array<Maybe<RuleValidation>>>;
  ruleValidation?: Maybe<RuleValidation>;
  latestRuleValitation?: Maybe<RuleValidation>;
  runValidation?: Maybe<RuleValidation>;
  requestSignedURLForValidationDocument: SignedUrlResponse;
  users?: Maybe<Array<Maybe<User>>>;
  user: User;
  loggedUser: User;
  basicLogin: TokenDetails;
  getNewAccessToken: TokenDetails;
  roles?: Maybe<Array<Maybe<Role>>>;
};


export type QueryDataTableArgs = {
  name: Scalars['String'];
};


export type QueryDocValidationArgs = {
  id: Scalars['String'];
};


export type QueryDownloadOcrArgs = {
  id: Scalars['String'];
};


export type QueryDownloadPdfArgs = {
  id: Scalars['String'];
};


export type QueryDownloadDiffArgs = {
  id: Scalars['String'];
};


export type QueryDownloadAllValidationFilesArgs = {
  id: Scalars['String'];
};


export type QueryDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentsNameForDossierArgs = {
  id: Scalars['String'];
};


export type QueryDownloadDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryDownloadDocumentsArgs = {
  dossier: Scalars['ID'];
};


export type QueryDocumentsByDossierArgs = {
  dossier: Scalars['String'];
};


export type QueryRunDocumentRulesArgs = {
  id: Scalars['ID'];
};


export type QueryGetDocumentCodeExecutionsArgs = {
  document: Scalars['ID'];
};


export type QueryGetPaginatedDocExecutionsArgs = {
  input: TableInput;
};


export type QueryDocumentRuleArgs = {
  id: Scalars['ID'];
};


export type QueryGetRulesByDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryGetDocumentRuleExecutionsArgs = {
  id: Scalars['ID'];
};


export type QuerySetDocumentRuleActiveArgs = {
  id: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type QueryDocumentTypeArgs = {
  name: Scalars['String'];
};


export type QueryDossierArgs = {
  id: Scalars['ID'];
};


export type QueryDownloadDossierArgs = {
  id: Scalars['ID'];
};


export type QueryGetDossierCodeExecutionsArgs = {
  dossier: Scalars['ID'];
};


export type QueryGetPaginatedDossierCodeExecutionsArgs = {
  input: TableInput;
};


export type QueryGetPaginatedDossierArgs = {
  input: TableInput;
};


export type QueryDossierCallbacksArgs = {
  id: Scalars['ID'];
};


export type QueryMetasCheckArgs = {
  id: Scalars['ID'];
};


export type QueryFixMetasArgs = {
  id: Scalars['ID'];
  metas: Array<MetaValue>;
};


export type QueryDossierRuleArgs = {
  id: Scalars['ID'];
};


export type QueryDossierRulesArgs = {
  codeToSearch?: Maybe<Scalars['String']>;
};


export type QueryGetDossierRuleLogsArgs = {
  id: Scalars['ID'];
};


export type QuerySetDossierRuleActiveArgs = {
  id: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type QueryGetDossierRuleExecutionsArgs = {
  ruleID: Scalars['String'];
  dossierID: Scalars['String'];
};


export type QueryGetDossierRuleExecutionsByDossierArgs = {
  dossierID: Scalars['String'];
};


export type QueryGetDossierRuleExecutionsByRuleArgs = {
  ruleID: Scalars['String'];
};


export type QueryGetDossierRulesExecutionCountArgs = {
  dossierID: Scalars['String'];
};


export type QueryGetDossierResultAggregateArgs = {
  dossierID: Scalars['String'];
};


export type QueryGetFailedDossierRuleExecsArgs = {
  dossierID: Scalars['String'];
};


export type QueryGetPaginatedDossierRuleExecArgs = {
  input: TableInput;
};


export type QueryDossierTypeArgs = {
  name: Scalars['String'];
};


export type QueryGetHelperCodeArgs = {
  id: Scalars['ID'];
};


export type QueryRunCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetLogsByDocumentArgs = {
  document: Scalars['ID'];
};


export type QueryGetLogsByDossierArgs = {
  dossier: Scalars['ID'];
};


export type QueryLogValidazioneArgs = {
  idValidazione: Scalars['String'];
};


export type QueryRuleValidationArgs = {
  id: Scalars['String'];
};


export type QueryRunValidationArgs = {
  idDocValidation: Scalars['String'];
};


export type QueryRequestSignedUrlForValidationDocumentArgs = {
  filename: Scalars['String'];
};


export type QueryUserArgs = {
  email: Scalars['String'];
};


export type QueryBasicLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type QueryGetNewAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

export enum Resources {
  Users = 'USERS',
  Roles = 'ROLES',
  Groups = 'GROUPS',
  Policies = 'POLICIES'
}

export type ResponseDelete = {
  __typename?: 'ResponseDelete';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  rowAffected: Scalars['Int'];
};

export type Role = {
  __typename?: 'Role';
  name: Scalars['String'];
  description: Scalars['String'];
};

export type RuleValidation = {
  __typename?: 'RuleValidation';
  id: Scalars['String'];
  fileName: Scalars['String'];
  url: Scalars['String'];
  createdAt?: Maybe<Scalars['Int']>;
  urlOcr?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['Int']>;
  rulesInvalidated?: Maybe<Scalars['Int']>;
  idDocument: Scalars['String'];
  istituto: Scalars['String'];
};

export type SignedUrlResponse = {
  __typename?: 'SignedURLResponse';
  url: Scalars['String'];
  name: Scalars['String'];
};

export type TableInput = {
  records: Scalars['Int'];
  page: Scalars['Int'];
  orderBy: Scalars['String'];
  selectOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Array<Maybe<FilterInput>>>;
  sumColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  avgColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TableOperation = {
  __typename?: 'TableOperation';
  id: Scalars['String'];
  result: Scalars['String'];
  type: Scalars['String'];
};

export type TableSelectOptions = {
  __typename?: 'TableSelectOptions';
  column: Scalars['String'];
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TokenDetails = {
  __typename?: 'TokenDetails';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  accessUuid: Scalars['String'];
  refreshUuid: Scalars['String'];
  atExpires: Scalars['Int'];
  rtExpires: Scalars['Int'];
  user: User;
  permissions: Scalars['String'];
};


export type UpdateDataTable = {
  name?: Maybe<Scalars['String']>;
  ordinamento?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
};

export type UpdateDocument = {
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
};

export type UpdateDocumentRule = {
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  packageJSON?: Maybe<Scalars['String']>;
  dataTables?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentType?: Maybe<Scalars['String']>;
  nota?: Maybe<Scalars['String']>;
};

export type UpdateDocumentType = {
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
};

export type UpdateDossier = {
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  dossierTypeName?: Maybe<Scalars['String']>;
};

export type UpdateDossierRule = {
  istitutoAppartenenza?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  packageJSON?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  dataTables?: Maybe<Array<Maybe<Scalars['String']>>>;
  mustContain?: Maybe<Array<Maybe<Scalars['String']>>>;
  notContain?: Maybe<Array<Maybe<Scalars['String']>>>;
  nota?: Maybe<Scalars['String']>;
  gruppo?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateDossierType = {
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
};

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
};

export type UserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  salt: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
};

export type UserInputWithPassword = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  email: Scalars['String'];
};

export type Zipped = {
  __typename?: 'Zipped';
  name: Scalars['String'];
  bsae64Content: Scalars['String'];
};

export type AddGroupMutationVariables = Exact<{
  subject: Scalars['String'];
  role: Scalars['String'];
}>;


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { AddGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'subject' | 'role' | 'domain'>
  )> }
);

export type AddPolicyMutationVariables = Exact<{
  subject: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
}>;


export type AddPolicyMutation = (
  { __typename?: 'Mutation' }
  & { AddPolicy?: Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'subject' | 'domain' | 'object' | 'action'>
  )> }
);

export type AddDocumentLogMutationVariables = Exact<{
  event: Scalars['String'];
  document: Scalars['ID'];
}>;


export type AddDocumentLogMutation = (
  { __typename?: 'Mutation' }
  & { addDocumentLog?: Maybe<(
    { __typename?: 'LogDocument' }
    & Pick<LogDocument, 'id' | 'event' | 'timestamp'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
          & { dossierRules?: Maybe<Array<Maybe<(
            { __typename?: 'DossierRule' }
            & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
            & { dossierType?: Maybe<(
              { __typename?: 'DossierType' }
              & Pick<DossierType, 'name' | 'createdAt'>
            )> }
          )>>> }
        )>, document?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        )>>> }
      )> }
    )> }
  )> }
);

export type AddDocumentMetasMutationVariables = Exact<{
  id: Scalars['ID'];
  metas: Scalars['String'];
}>;


export type AddDocumentMetasMutation = (
  { __typename?: 'Mutation' }
  & { addDocumentMetas?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  )> }
);

export type AddDossierLogMutationVariables = Exact<{
  event: Scalars['String'];
  dossier: Scalars['ID'];
}>;


export type AddDossierLogMutation = (
  { __typename?: 'Mutation' }
  & { addDossierLog?: Maybe<(
    { __typename?: 'LogDossier' }
    & Pick<LogDossier, 'id' | 'event' | 'timestamp'>
    & { dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
          & { documentRules?: Maybe<Array<Maybe<(
            { __typename?: 'DocumentRule' }
            & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
            & { dataTables?: Maybe<Array<Maybe<(
              { __typename?: 'DataTable' }
              & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
            )>>>, documentType?: Maybe<(
              { __typename?: 'DocumentType' }
              & Pick<DocumentType, 'name' | 'createdAt'>
            )> }
          )>>> }
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  )> }
);

export type AddDossierMetasMutationVariables = Exact<{
  id: Scalars['ID'];
  metas: Scalars['String'];
}>;


export type AddDossierMetasMutation = (
  { __typename?: 'Mutation' }
  & { addDossierMetas?: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, document?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )>, document?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type AddNotificationReceiverMutationVariables = Exact<{
  receiver: Scalars['String'];
}>;


export type AddNotificationReceiverMutation = (
  { __typename?: 'Mutation' }
  & { addNotificationReceiver: Array<(
    { __typename?: 'NotificationReceiver' }
    & Pick<NotificationReceiver, 'id'>
  )> }
);

export type ClearDocumentMetasMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ClearDocumentMetasMutation = (
  { __typename?: 'Mutation' }
  & { clearDocumentMetas?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  )> }
);

export type ClearDossierMetasMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ClearDossierMetasMutation = (
  { __typename?: 'Mutation' }
  & { clearDossierMetas?: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, document?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )>, document?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type CreateDataTableMutationVariables = Exact<{
  input: NewDataTable;
}>;


export type CreateDataTableMutation = (
  { __typename?: 'Mutation' }
  & { createDataTable: (
    { __typename?: 'DataTable' }
    & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
  ) }
);

export type CreateDocValidationsMutationVariables = Exact<{
  files: Array<DocValidationUpload> | DocValidationUpload;
}>;


export type CreateDocValidationsMutation = (
  { __typename?: 'Mutation' }
  & { createDocValidations?: Maybe<Array<Maybe<(
    { __typename?: 'DocValidation' }
    & Pick<DocValidation, 'id' | 'description' | 'istitutoAppartenenza' | 'urlFile' | 'urlOCR' | 'createdAt'>
  )>>> }
);

export type CreateDocumentMutationVariables = Exact<{
  input: NewDocument;
}>;


export type CreateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createDocument: (
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  ) }
);

export type CreateDocumentRuleMutationVariables = Exact<{
  input: NewDocumentRule;
}>;


export type CreateDocumentRuleMutation = (
  { __typename?: 'Mutation' }
  & { createDocumentRule: (
    { __typename?: 'DocumentRule' }
    & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
    & { dataTables?: Maybe<Array<Maybe<(
      { __typename?: 'DataTable' }
      & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
    )>>>, documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  ) }
);

export type CreateDocumentTypeMutationVariables = Exact<{
  input: NewDocumentType;
}>;


export type CreateDocumentTypeMutation = (
  { __typename?: 'Mutation' }
  & { createDocumentType: (
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'name' | 'createdAt'>
    & { documentRules?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentRule' }
      & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
      & { dataTables?: Maybe<Array<Maybe<(
        { __typename?: 'DataTable' }
        & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
      )>>>, documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        )>>> }
      )> }
    )>>> }
  ) }
);

export type CreateDossierMutationVariables = Exact<{
  input: DossierInput;
}>;


export type CreateDossierMutation = (
  { __typename?: 'Mutation' }
  & { createDossier: (
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, document?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )>, document?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        )>>> }
      )> }
    )>>> }
  ) }
);

export type CreateDossierRuleMutationVariables = Exact<{
  input: NewDossierRule;
}>;


export type CreateDossierRuleMutation = (
  { __typename?: 'Mutation' }
  & { createDossierRule: (
    { __typename?: 'DossierRule' }
    & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  ) }
);

export type CreateDossierTypeMutationVariables = Exact<{
  input: NewDossierType;
}>;


export type CreateDossierTypeMutation = (
  { __typename?: 'Mutation' }
  & { createDossierType: (
    { __typename?: 'DossierType' }
    & Pick<DossierType, 'name' | 'createdAt'>
    & { dossierRules?: Maybe<Array<Maybe<(
      { __typename?: 'DossierRule' }
      & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        )>>> }
      )> }
    )>>> }
  ) }
);

export type CreateRoleMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'name' | 'description'>
  )> }
);

export type CreateRuleValidationMutationVariables = Exact<{
  documentID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url: Scalars['String'];
  istitutoAppartenenza: Scalars['String'];
}>;


export type CreateRuleValidationMutation = (
  { __typename?: 'Mutation' }
  & { createRuleValidation?: Maybe<(
    { __typename?: 'RuleValidation' }
    & Pick<RuleValidation, 'id' | 'fileName' | 'url' | 'createdAt' | 'urlOcr' | 'state' | 'finishedAt' | 'rulesInvalidated' | 'idDocument' | 'istituto'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  user?: Maybe<UserInputWithPassword>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type DeleteDataTableMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type DeleteDataTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDataTable'>
);

export type DeleteDocValidationsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteDocValidationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDocValidations'>
);

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteDocument?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  )> }
);

export type DeleteDocumentRuleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDocumentRuleMutation = (
  { __typename?: 'Mutation' }
  & { deleteDocumentRule?: Maybe<(
    { __typename?: 'DocumentRule' }
    & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
    & { dataTables?: Maybe<Array<Maybe<(
      { __typename?: 'DataTable' }
      & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
    )>>>, documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  )> }
);

export type DeleteDocumentTypeMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type DeleteDocumentTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteDocumentType?: Maybe<(
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'name' | 'createdAt'>
    & { documentRules?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentRule' }
      & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
      & { dataTables?: Maybe<Array<Maybe<(
        { __typename?: 'DataTable' }
        & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
      )>>>, documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type DeleteDossierMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDossierMutation = (
  { __typename?: 'Mutation' }
  & { deleteDossier?: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, document?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )>, document?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type DeleteDossierRuleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDossierRuleMutation = (
  { __typename?: 'Mutation' }
  & { deleteDossierRule?: Maybe<(
    { __typename?: 'DossierRule' }
    & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  )> }
);

export type DeleteDossierTypeMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type DeleteDossierTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDossierType'>
);

export type DeleteFormDataMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFormDataMutation = (
  { __typename?: 'Mutation' }
  & { deleteFormData: (
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  ) }
);

export type DeleteNotificationReceiverMutationVariables = Exact<{
  receiver: Scalars['String'];
}>;


export type DeleteNotificationReceiverMutation = (
  { __typename?: 'Mutation' }
  & { deleteNotificationReceiver: Array<(
    { __typename?: 'NotificationReceiver' }
    & Pick<NotificationReceiver, 'id'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type DownloadDocumentRuleMutationVariables = Exact<{
  id: Scalars['ID'];
  ruleId: Scalars['ID'];
}>;


export type DownloadDocumentRuleMutation = (
  { __typename?: 'Mutation' }
  & { downloadDocumentRule: (
    { __typename?: 'Zipped' }
    & Pick<Zipped, 'name' | 'bsae64Content'>
  ) }
);

export type DownloadDossierRuleMutationVariables = Exact<{
  id: Scalars['ID'];
  ruleId: Scalars['ID'];
}>;


export type DownloadDossierRuleMutation = (
  { __typename?: 'Mutation' }
  & { downloadDossierRule: (
    { __typename?: 'Zipped' }
    & Pick<Zipped, 'name' | 'bsae64Content'>
  ) }
);

export type ImpersonatesMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ImpersonatesMutation = (
  { __typename?: 'Mutation' }
  & { impersonates: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type ImportDossierRulesMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type ImportDossierRulesMutation = (
  { __typename?: 'Mutation' }
  & { importDossierRules?: Maybe<Array<Maybe<(
    { __typename?: 'DossierRule' }
    & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  )>>> }
);

export type InsertLogValidazioneMutationVariables = Exact<{
  idValutazione: Scalars['String'];
  idRule: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type InsertLogValidazioneMutation = (
  { __typename?: 'Mutation' }
  & { insertLogValidazione?: Maybe<(
    { __typename?: 'LogValidazione' }
    & Pick<LogValidazione, 'idValidazione' | 'idRule' | 'description'>
  )> }
);

export type PingMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type PingMutationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pingMutation'>
);

export type RecoverPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RecoverPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recoverPassword'>
);

export type ReplaceDocumentMetasMutationVariables = Exact<{
  id: Scalars['ID'];
  metas: Scalars['String'];
}>;


export type ReplaceDocumentMetasMutation = (
  { __typename?: 'Mutation' }
  & { replaceDocumentMetas?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  )> }
);

export type ReplaceDossierMetasMutationVariables = Exact<{
  id: Scalars['ID'];
  metas: Scalars['String'];
}>;


export type ReplaceDossierMetasMutation = (
  { __typename?: 'Mutation' }
  & { replaceDossierMetas?: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, document?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )>, document?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type RunAgainDossierRulesThatHasErrorsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RunAgainDossierRulesThatHasErrorsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runAgainDossierRulesThatHasErrors'>
);

export type RunDocumentRuleMutationVariables = Exact<{
  id: Scalars['ID'];
  ruleId: Scalars['ID'];
}>;


export type RunDocumentRuleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runDocumentRule'>
);

export type RunDossierRuleMutationVariables = Exact<{
  id: Scalars['ID'];
  ruleId: Scalars['ID'];
  groupId?: Maybe<Scalars['ID']>;
}>;


export type RunDossierRuleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runDossierRule'>
);

export type RunDossierRulesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RunDossierRulesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runDossierRules'>
);

export type RunTemporaryDossierRuleMutationVariables = Exact<{
  id: Scalars['ID'];
  ruleID: Scalars['ID'];
  ruleName: Scalars['String'];
  code: Scalars['String'];
  packagecode: Scalars['String'];
}>;


export type RunTemporaryDossierRuleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runTemporaryDossierRule'>
);

export type RunTemporaryRuleMutationVariables = Exact<{
  id: Scalars['ID'];
  ruleID: Scalars['ID'];
  ruleName: Scalars['String'];
  code: Scalars['String'];
  packagecode: Scalars['String'];
  dataTables?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type RunTemporaryRuleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runTemporaryRule'>
);

export type TestRuleMutationVariables = Exact<{
  docValidationID: Scalars['String'];
  mustContain?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  notContain?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type TestRuleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'testRule'>
);

export type UpdateAvatarMutationVariables = Exact<{
  email: Scalars['String'];
  avatar: Scalars['String'];
}>;


export type UpdateAvatarMutation = (
  { __typename?: 'Mutation' }
  & { updateAvatar: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UpdateDataTableMutationVariables = Exact<{
  name: Scalars['String'];
  input: UpdateDataTable;
}>;


export type UpdateDataTableMutation = (
  { __typename?: 'Mutation' }
  & { updateDataTable: (
    { __typename?: 'DataTable' }
    & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
  ) }
);

export type UpdateDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateDocument;
}>;


export type UpdateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateDocument: (
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  ) }
);

export type UpdateDocumentRuleMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateDocumentRule;
}>;


export type UpdateDocumentRuleMutation = (
  { __typename?: 'Mutation' }
  & { updateDocumentRule: (
    { __typename?: 'DocumentRule' }
    & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
    & { dataTables?: Maybe<Array<Maybe<(
      { __typename?: 'DataTable' }
      & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
    )>>>, documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  ) }
);

export type UpdateDocumentTypeMutationVariables = Exact<{
  name: Scalars['String'];
  input: UpdateDocumentType;
}>;


export type UpdateDocumentTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateDocumentType: (
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'name' | 'createdAt'>
    & { documentRules?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentRule' }
      & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
      & { dataTables?: Maybe<Array<Maybe<(
        { __typename?: 'DataTable' }
        & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
      )>>>, documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        )>>> }
      )> }
    )>>> }
  ) }
);

export type UpdateDossierMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateDossier;
}>;


export type UpdateDossierMutation = (
  { __typename?: 'Mutation' }
  & { updateDossier: (
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, document?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )>, document?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        )>>> }
      )> }
    )>>> }
  ) }
);

export type UpdateDossierRuleMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateDossierRule;
}>;


export type UpdateDossierRuleMutation = (
  { __typename?: 'Mutation' }
  & { updateDossierRule: (
    { __typename?: 'DossierRule' }
    & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  ) }
);

export type UpdateDossierTypeMutationVariables = Exact<{
  name: Scalars['String'];
  input: UpdateDossierType;
}>;


export type UpdateDossierTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateDossierType: (
    { __typename?: 'DossierType' }
    & Pick<DossierType, 'name' | 'createdAt'>
    & { dossierRules?: Maybe<Array<Maybe<(
      { __typename?: 'DossierRule' }
      & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        )>>> }
      )> }
    )>>> }
  ) }
);

export type UpdateHelperCodeMutationVariables = Exact<{
  id: Scalars['ID'];
  code: Scalars['String'];
}>;


export type UpdateHelperCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateHelperCode'>
);

export type UpdateMustContainMutationVariables = Exact<{
  id: Scalars['String'];
  input?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UpdateMustContainMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMustContain'>
);

export type UpdateNotContainMutationVariables = Exact<{
  id: Scalars['String'];
  input?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UpdateNotContainMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNotContain'>
);

export type UpdateSelfPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdateSelfPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateSelfPassword: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  email: Scalars['String'];
  user?: Maybe<UpdateUserInput>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UpdateUserPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdateUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateUserPassword: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UploadRuleValidationMutationVariables = Exact<{
  fileName: Scalars['String'];
  file: Scalars['String'];
  istitutoAppartenenza: Scalars['String'];
}>;


export type UploadRuleValidationMutation = (
  { __typename?: 'Mutation' }
  & { uploadRuleValidation?: Maybe<(
    { __typename?: 'RuleValidation' }
    & Pick<RuleValidation, 'id' | 'fileName' | 'url' | 'createdAt' | 'urlOcr' | 'state' | 'finishedAt' | 'rulesInvalidated' | 'idDocument' | 'istituto'>
  )> }
);

export type BasicLoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type BasicLoginQuery = (
  { __typename?: 'Query' }
  & { basicLogin: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type DataTableQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type DataTableQuery = (
  { __typename?: 'Query' }
  & { dataTable: (
    { __typename?: 'DataTable' }
    & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
  ) }
);

export type DataTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type DataTablesQuery = (
  { __typename?: 'Query' }
  & { dataTables: Array<(
    { __typename?: 'DataTable' }
    & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
  )> }
);

export type DocValidationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DocValidationQuery = (
  { __typename?: 'Query' }
  & { docValidation?: Maybe<(
    { __typename?: 'DocValidation' }
    & Pick<DocValidation, 'id' | 'description' | 'istitutoAppartenenza' | 'urlFile' | 'urlOCR' | 'createdAt'>
  )> }
);

export type DocValidationsQueryVariables = Exact<{ [key: string]: never; }>;


export type DocValidationsQuery = (
  { __typename?: 'Query' }
  & { docValidations?: Maybe<Array<Maybe<(
    { __typename?: 'DocValidation' }
    & Pick<DocValidation, 'id' | 'description' | 'istitutoAppartenenza' | 'urlFile' | 'urlOCR' | 'createdAt'>
  )>>> }
);

export type DocumentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DocumentQuery = (
  { __typename?: 'Query' }
  & { document: (
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      )>>> }
    )> }
  ) }
);

export type DocumentRuleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DocumentRuleQuery = (
  { __typename?: 'Query' }
  & { documentRule: (
    { __typename?: 'DocumentRule' }
    & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
    & { dataTables?: Maybe<Array<Maybe<(
      { __typename?: 'DataTable' }
      & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
    )>>>, documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  ) }
);

export type DocumentRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentRulesQuery = (
  { __typename?: 'Query' }
  & { documentRules: Array<(
    { __typename?: 'DocumentRule' }
    & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
    & { dataTables?: Maybe<Array<Maybe<(
      { __typename?: 'DataTable' }
      & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
    )>>>, documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
      )>>> }
    )> }
  )> }
);

export type DocumentTypeQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type DocumentTypeQuery = (
  { __typename?: 'Query' }
  & { documentType: (
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'name' | 'createdAt'>
    & { documentRules?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentRule' }
      & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
      & { dataTables?: Maybe<Array<Maybe<(
        { __typename?: 'DataTable' }
        & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
      )>>>, documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
      )> }
    )>>> }
  ) }
);

export type DocumentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentTypesQuery = (
  { __typename?: 'Query' }
  & { documentTypes: Array<(
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'name' | 'createdAt'>
    & { documentRules?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentRule' }
      & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
      & { dataTables?: Maybe<Array<Maybe<(
        { __typename?: 'DataTable' }
        & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
      )>>>, documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type DocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentsQuery = (
  { __typename?: 'Query' }
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  )> }
);

export type DocumentsByDossierQueryVariables = Exact<{
  dossier: Scalars['String'];
}>;


export type DocumentsByDossierQuery = (
  { __typename?: 'Query' }
  & { documentsByDossier?: Maybe<Array<Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  )>>> }
);

export type DocumentsInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentsInfoQuery = (
  { __typename?: 'Query' }
  & { documentsInfo: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
    & { documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  )> }
);

export type DocumentsNameForDossierQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DocumentsNameForDossierQuery = (
  { __typename?: 'Query' }
  & { documentsNameForDossier: Array<(
    { __typename?: 'DocumentName' }
    & Pick<DocumentName, 'id' | 'name'>
  )> }
);

export type DossierQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DossierQuery = (
  { __typename?: 'Query' }
  & { dossier: (
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, document?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      )> }
    )>>> }
  ) }
);

export type DossierCallbacksQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DossierCallbacksQuery = (
  { __typename?: 'Query' }
  & { dossierCallbacks: Array<(
    { __typename?: 'Callback' }
    & Pick<Callback, 'dossierId' | 'url' | 'success' | 'method' | 'requestBody' | 'responseCode' | 'responseBody' | 'timestamp' | 'errorDescription' | 'idValutazione'>
  )> }
);

export type DossierRuleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DossierRuleQuery = (
  { __typename?: 'Query' }
  & { dossierRule: (
    { __typename?: 'DossierRule' }
    & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  ) }
);

export type DossierRulesQueryVariables = Exact<{
  codeToSearch?: Maybe<Scalars['String']>;
}>;


export type DossierRulesQuery = (
  { __typename?: 'Query' }
  & { dossierRules: Array<(
    { __typename?: 'DossierRule' }
    & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
      )>>> }
    )> }
  )> }
);

export type DossierTypeQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type DossierTypeQuery = (
  { __typename?: 'Query' }
  & { dossierType: (
    { __typename?: 'DossierType' }
    & Pick<DossierType, 'name' | 'createdAt'>
    & { dossierRules?: Maybe<Array<Maybe<(
      { __typename?: 'DossierRule' }
      & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
      )> }
    )>>> }
  ) }
);

export type DossierTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type DossierTypesQuery = (
  { __typename?: 'Query' }
  & { dossierTypes: Array<(
    { __typename?: 'DossierType' }
    & Pick<DossierType, 'name' | 'createdAt'>
    & { dossierRules?: Maybe<Array<Maybe<(
      { __typename?: 'DossierRule' }
      & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type DossiersQueryVariables = Exact<{ [key: string]: never; }>;


export type DossiersQuery = (
  { __typename?: 'Query' }
  & { dossiers: Array<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )>, document?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )>, document?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type DownloadAllValidationFilesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DownloadAllValidationFilesQuery = (
  { __typename?: 'Query' }
  & { downloadAllValidationFiles: (
    { __typename?: 'Zipped' }
    & Pick<Zipped, 'name' | 'bsae64Content'>
  ) }
);

export type DownloadDiffQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DownloadDiffQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'downloadDiff'>
);

export type DownloadDocumentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DownloadDocumentQuery = (
  { __typename?: 'Query' }
  & { downloadDocument?: Maybe<(
    { __typename?: 'DocumentDownload' }
    & Pick<DocumentDownload, 'id' | 'name' | 'base64' | 'filename' | 'extension'>
  )> }
);

export type DownloadDocumentsQueryVariables = Exact<{
  dossier: Scalars['ID'];
}>;


export type DownloadDocumentsQuery = (
  { __typename?: 'Query' }
  & { downloadDocuments?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentDownload' }
    & Pick<DocumentDownload, 'id' | 'name' | 'base64' | 'filename' | 'extension'>
  )>>> }
);

export type DownloadDossierQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DownloadDossierQuery = (
  { __typename?: 'Query' }
  & { downloadDossier?: Maybe<(
    { __typename?: 'DossierDownload' }
    & Pick<DossierDownload, 'base64' | 'filename' | 'extension'>
  )> }
);

export type DownloadOcrQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DownloadOcrQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'downloadOCR'>
);

export type DownloadPdfQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DownloadPdfQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'downloadPDF'>
);

export type FixMetasQueryVariables = Exact<{
  id: Scalars['ID'];
  metas: Array<MetaValue> | MetaValue;
}>;


export type FixMetasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fixMetas'>
);

export type GetAllDocumentCodeExecutionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDocumentCodeExecutionsQuery = (
  { __typename?: 'Query' }
  & { getAllDocumentCodeExecutions?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentCodeExecution' }
    & Pick<DocumentCodeExecution, 'id' | 'documentId' | 'ruleId' | 'ruleName' | 'executedAt' | 'code' | 'rawText' | 'rawTextWithGeometry' | 'tables' | 'dataTables' | 'formData' | 'state' | 'result' | 'errorLog' | 'logs' | 'platform'>
  )>>> }
);

export type GetAllDossierCodeExecutionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDossierCodeExecutionsQuery = (
  { __typename?: 'Query' }
  & { getAllDossierCodeExecutions?: Maybe<Array<Maybe<(
    { __typename?: 'DossierCodeExecution' }
    & Pick<DossierCodeExecution, 'id' | 'dossierId' | 'ruleId' | 'ruleName' | 'executedAt' | 'metas' | 'code' | 'state' | 'result' | 'errors' | 'logs' | 'platform' | 'input'>
  )>>> }
);

export type GetAllDossierRuleExecutionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDossierRuleExecutionsQuery = (
  { __typename?: 'Query' }
  & { getAllDossierRuleExecutions?: Maybe<Array<Maybe<(
    { __typename?: 'DossierRuleExecution' }
    & Pick<DossierRuleExecution, 'executionID' | 'ruleID' | 'inputKey' | 'dossierID' | 'inputIndex' | 'input' | 'warning' | 'error' | 'state' | 'result' | 'createdAt' | 'finishedAt' | 'ruleName' | 'platform' | 'groupExecutionID'>
  )>>> }
);

export type GetDocumentCodeExecutionsQueryVariables = Exact<{
  document: Scalars['ID'];
}>;


export type GetDocumentCodeExecutionsQuery = (
  { __typename?: 'Query' }
  & { getDocumentCodeExecutions?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentCodeExecution' }
    & Pick<DocumentCodeExecution, 'id' | 'documentId' | 'ruleId' | 'ruleName' | 'executedAt' | 'code' | 'rawText' | 'rawTextWithGeometry' | 'tables' | 'dataTables' | 'formData' | 'state' | 'result' | 'errorLog' | 'logs' | 'platform'>
  )>>> }
);

export type GetDocumentLogsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDocumentLogsQuery = (
  { __typename?: 'Query' }
  & { getDocumentLogs?: Maybe<Array<Maybe<(
    { __typename?: 'LogDocument' }
    & Pick<LogDocument, 'id' | 'event' | 'timestamp'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
          & { dossierRules?: Maybe<Array<Maybe<(
            { __typename?: 'DossierRule' }
            & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
            & { dossierType?: Maybe<(
              { __typename?: 'DossierType' }
              & Pick<DossierType, 'name' | 'createdAt'>
            )> }
          )>>> }
        )>, document?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        )>>> }
      )> }
    )> }
  )>>> }
);

export type GetDocumentRuleExecutionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocumentRuleExecutionsQuery = (
  { __typename?: 'Query' }
  & { getDocumentRuleExecutions?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentCodeExecution' }
    & Pick<DocumentCodeExecution, 'id' | 'documentId' | 'ruleId' | 'ruleName' | 'executedAt' | 'code' | 'rawText' | 'rawTextWithGeometry' | 'tables' | 'dataTables' | 'formData' | 'state' | 'result' | 'errorLog' | 'logs' | 'platform'>
  )>>> }
);

export type GetDossierCodeExecutionsQueryVariables = Exact<{
  dossier: Scalars['ID'];
}>;


export type GetDossierCodeExecutionsQuery = (
  { __typename?: 'Query' }
  & { getDossierCodeExecutions?: Maybe<Array<Maybe<(
    { __typename?: 'DossierCodeExecution' }
    & Pick<DossierCodeExecution, 'id' | 'dossierId' | 'ruleId' | 'ruleName' | 'executedAt' | 'metas' | 'code' | 'state' | 'result' | 'errors' | 'logs' | 'platform' | 'input'>
  )>>> }
);

export type GetDossierLogsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDossierLogsQuery = (
  { __typename?: 'Query' }
  & { getDossierLogs?: Maybe<Array<Maybe<(
    { __typename?: 'LogDossier' }
    & Pick<LogDossier, 'id' | 'event' | 'timestamp'>
    & { dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
          & { documentRules?: Maybe<Array<Maybe<(
            { __typename?: 'DocumentRule' }
            & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
            & { dataTables?: Maybe<Array<Maybe<(
              { __typename?: 'DataTable' }
              & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
            )>>>, documentType?: Maybe<(
              { __typename?: 'DocumentType' }
              & Pick<DocumentType, 'name' | 'createdAt'>
            )> }
          )>>> }
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  )>>> }
);

export type GetDossierResultAggregateQueryVariables = Exact<{
  dossierID: Scalars['String'];
}>;


export type GetDossierResultAggregateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDossierResultAggregate'>
);

export type GetDossierRuleExecutionsQueryVariables = Exact<{
  ruleID: Scalars['String'];
  dossierID: Scalars['String'];
}>;


export type GetDossierRuleExecutionsQuery = (
  { __typename?: 'Query' }
  & { getDossierRuleExecutions?: Maybe<Array<Maybe<(
    { __typename?: 'DossierRuleExecution' }
    & Pick<DossierRuleExecution, 'executionID' | 'ruleID' | 'inputKey' | 'dossierID' | 'inputIndex' | 'input' | 'warning' | 'error' | 'state' | 'result' | 'createdAt' | 'finishedAt' | 'ruleName' | 'platform' | 'groupExecutionID'>
  )>>> }
);

export type GetDossierRuleExecutionsByDossierQueryVariables = Exact<{
  dossierID: Scalars['String'];
}>;


export type GetDossierRuleExecutionsByDossierQuery = (
  { __typename?: 'Query' }
  & { getDossierRuleExecutionsByDossier?: Maybe<Array<Maybe<(
    { __typename?: 'DossierRuleExecution' }
    & Pick<DossierRuleExecution, 'executionID' | 'ruleID' | 'inputKey' | 'dossierID' | 'inputIndex' | 'input' | 'warning' | 'error' | 'state' | 'result' | 'createdAt' | 'finishedAt' | 'ruleName' | 'platform' | 'groupExecutionID'>
  )>>> }
);

export type GetDossierRuleExecutionsByRuleQueryVariables = Exact<{
  ruleID: Scalars['String'];
}>;


export type GetDossierRuleExecutionsByRuleQuery = (
  { __typename?: 'Query' }
  & { getDossierRuleExecutionsByRule?: Maybe<Array<Maybe<(
    { __typename?: 'DossierRuleExecution' }
    & Pick<DossierRuleExecution, 'executionID' | 'ruleID' | 'inputKey' | 'dossierID' | 'inputIndex' | 'input' | 'warning' | 'error' | 'state' | 'result' | 'createdAt' | 'finishedAt' | 'ruleName' | 'platform' | 'groupExecutionID'>
  )>>> }
);

export type GetDossierRuleLogsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDossierRuleLogsQuery = (
  { __typename?: 'Query' }
  & { getDossierRuleLogs?: Maybe<Array<Maybe<(
    { __typename?: 'DossierCodeExecution' }
    & Pick<DossierCodeExecution, 'id' | 'dossierId' | 'ruleId' | 'ruleName' | 'executedAt' | 'metas' | 'code' | 'state' | 'result' | 'errors' | 'logs' | 'platform' | 'input'>
  )>>> }
);

export type GetDossierRulesExecutionCountQueryVariables = Exact<{
  dossierID: Scalars['String'];
}>;


export type GetDossierRulesExecutionCountQuery = (
  { __typename?: 'Query' }
  & { getDossierRulesExecutionCount?: Maybe<(
    { __typename?: 'CountReport' }
    & Pick<CountReport, 'processed' | 'total'>
  )> }
);

export type GetFailedDossierRuleExecsQueryVariables = Exact<{
  dossierID: Scalars['String'];
}>;


export type GetFailedDossierRuleExecsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFailedDossierRuleExecs'>
);

export type GetGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsQuery = (
  { __typename?: 'Query' }
  & { getGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'subject' | 'role' | 'domain'>
  )>>> }
);

export type GetHelperCodeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetHelperCodeQuery = (
  { __typename?: 'Query' }
  & { getHelperCode?: Maybe<(
    { __typename?: 'HelperCode' }
    & Pick<HelperCode, 'id' | 'description' | 'code'>
  )> }
);

export type GetLogsByDocumentQueryVariables = Exact<{
  document: Scalars['ID'];
}>;


export type GetLogsByDocumentQuery = (
  { __typename?: 'Query' }
  & { getLogsByDocument?: Maybe<Array<Maybe<(
    { __typename?: 'LogDocument' }
    & Pick<LogDocument, 'id' | 'event' | 'timestamp'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
      & { documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'createdAt'>
        & { documentRules?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentRule' }
          & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
          & { dataTables?: Maybe<Array<Maybe<(
            { __typename?: 'DataTable' }
            & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
          )>>>, documentType?: Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, dossier?: Maybe<(
        { __typename?: 'Dossier' }
        & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
          & { dossierRules?: Maybe<Array<Maybe<(
            { __typename?: 'DossierRule' }
            & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
            & { dossierType?: Maybe<(
              { __typename?: 'DossierType' }
              & Pick<DossierType, 'name' | 'createdAt'>
            )> }
          )>>> }
        )>, document?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        )>>> }
      )> }
    )> }
  )>>> }
);

export type GetLogsByDossierQueryVariables = Exact<{
  dossier: Scalars['ID'];
}>;


export type GetLogsByDossierQuery = (
  { __typename?: 'Query' }
  & { getLogsByDossier?: Maybe<Array<Maybe<(
    { __typename?: 'LogDossier' }
    & Pick<LogDossier, 'id' | 'event' | 'timestamp'>
    & { dossier?: Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
          & { documentRules?: Maybe<Array<Maybe<(
            { __typename?: 'DocumentRule' }
            & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
            & { dataTables?: Maybe<Array<Maybe<(
              { __typename?: 'DataTable' }
              & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
            )>>>, documentType?: Maybe<(
              { __typename?: 'DocumentType' }
              & Pick<DocumentType, 'name' | 'createdAt'>
            )> }
          )>>> }
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
        )> }
      )>>> }
    )> }
  )>>> }
);

export type GetNewAccessTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type GetNewAccessTokenQuery = (
  { __typename?: 'Query' }
  & { getNewAccessToken: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type GetPaginatedDocExecutionsQueryVariables = Exact<{
  input: TableInput;
}>;


export type GetPaginatedDocExecutionsQuery = (
  { __typename?: 'Query' }
  & { getPaginatedDocExecutions?: Maybe<(
    { __typename?: 'DocumentCodeExecutionTable' }
    & Pick<DocumentCodeExecutionTable, 'pages'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentCodeExecution' }
      & Pick<DocumentCodeExecution, 'id' | 'documentId' | 'ruleId' | 'ruleName' | 'executedAt' | 'code' | 'rawText' | 'rawTextWithGeometry' | 'tables' | 'dataTables' | 'formData' | 'state' | 'result' | 'errorLog' | 'logs' | 'platform'>
    )>>>, options?: Maybe<Array<Maybe<(
      { __typename?: 'TableSelectOptions' }
      & Pick<TableSelectOptions, 'column' | 'options'>
    )>>>, op?: Maybe<Array<Maybe<(
      { __typename?: 'TableOperation' }
      & Pick<TableOperation, 'id' | 'result' | 'type'>
    )>>> }
  )> }
);

export type GetPaginatedDossierQueryVariables = Exact<{
  input: TableInput;
}>;


export type GetPaginatedDossierQuery = (
  { __typename?: 'Query' }
  & { getPaginatedDossier?: Maybe<(
    { __typename?: 'DossierTable' }
    & Pick<DossierTable, 'pages'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
      & { dossierType?: Maybe<(
        { __typename?: 'DossierType' }
        & Pick<DossierType, 'name' | 'createdAt'>
        & { dossierRules?: Maybe<Array<Maybe<(
          { __typename?: 'DossierRule' }
          & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )> }
        )>>> }
      )>, document?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
        & { documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
          & { documentRules?: Maybe<Array<Maybe<(
            { __typename?: 'DocumentRule' }
            & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
            & { dataTables?: Maybe<Array<Maybe<(
              { __typename?: 'DataTable' }
              & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
            )>>>, documentType?: Maybe<(
              { __typename?: 'DocumentType' }
              & Pick<DocumentType, 'name' | 'createdAt'>
            )> }
          )>>> }
        )>, dossier?: Maybe<(
          { __typename?: 'Dossier' }
          & Pick<Dossier, 'id' | 'description' | 'hash' | 'createdAt' | 'metas' | 'state' | 'error' | 'tags' | 'fullDescription' | 'fileName' | 'fileTextContent' | 'input' | 'dossierTypeName'>
          & { dossierType?: Maybe<(
            { __typename?: 'DossierType' }
            & Pick<DossierType, 'name' | 'createdAt'>
          )>, document?: Maybe<Array<Maybe<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'url' | 'metas' | 'description' | 'state' | 'error' | 'formData' | 'tables' | 'rawTextWithGeometry' | 'rawText'>
          )>>> }
        )> }
      )>>> }
    )>>>, options?: Maybe<Array<Maybe<(
      { __typename?: 'TableSelectOptions' }
      & Pick<TableSelectOptions, 'column' | 'options'>
    )>>>, op?: Maybe<Array<Maybe<(
      { __typename?: 'TableOperation' }
      & Pick<TableOperation, 'id' | 'result' | 'type'>
    )>>> }
  )> }
);

export type GetPaginatedDossierCodeExecutionsQueryVariables = Exact<{
  input: TableInput;
}>;


export type GetPaginatedDossierCodeExecutionsQuery = (
  { __typename?: 'Query' }
  & { getPaginatedDossierCodeExecutions?: Maybe<(
    { __typename?: 'DossierCodeExecutionTable' }
    & Pick<DossierCodeExecutionTable, 'pages'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'DossierCodeExecuted' }
      & Pick<DossierCodeExecuted, 'id' | 'dossierId' | 'ruleName' | 'ruleId' | 'code' | 'state' | 'metas' | 'result' | 'executedAt' | 'logs' | 'errorLog' | 'platform'>
    )>>>, options?: Maybe<Array<Maybe<(
      { __typename?: 'TableSelectOptions' }
      & Pick<TableSelectOptions, 'column' | 'options'>
    )>>>, op?: Maybe<Array<Maybe<(
      { __typename?: 'TableOperation' }
      & Pick<TableOperation, 'id' | 'result' | 'type'>
    )>>> }
  )> }
);

export type GetPaginatedDossierRuleExecQueryVariables = Exact<{
  input: TableInput;
}>;


export type GetPaginatedDossierRuleExecQuery = (
  { __typename?: 'Query' }
  & { getPaginatedDossierRuleExec: (
    { __typename?: 'DossierRuleExecTable' }
    & Pick<DossierRuleExecTable, 'pages'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'DossierRuleExecution' }
      & Pick<DossierRuleExecution, 'executionID' | 'ruleID' | 'inputKey' | 'dossierID' | 'inputIndex' | 'input' | 'warning' | 'error' | 'state' | 'result' | 'createdAt' | 'finishedAt' | 'ruleName' | 'platform' | 'groupExecutionID'>
    )>>>, options?: Maybe<Array<Maybe<(
      { __typename?: 'TableSelectOptions' }
      & Pick<TableSelectOptions, 'column' | 'options'>
    )>>>, op?: Maybe<Array<Maybe<(
      { __typename?: 'TableOperation' }
      & Pick<TableOperation, 'id' | 'result' | 'type'>
    )>>> }
  ) }
);

export type GetPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPoliciesQuery = (
  { __typename?: 'Query' }
  & { getPolicies?: Maybe<Array<Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'subject' | 'domain' | 'object' | 'action'>
  )>>> }
);

export type GetRulesByDocumentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRulesByDocumentQuery = (
  { __typename?: 'Query' }
  & { getRulesByDocument?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentRule' }
    & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
    & { dataTables?: Maybe<Array<Maybe<(
      { __typename?: 'DataTable' }
      & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
    )>>>, documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  )>>> }
);

export type LatestRuleValitationQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestRuleValitationQuery = (
  { __typename?: 'Query' }
  & { latestRuleValitation?: Maybe<(
    { __typename?: 'RuleValidation' }
    & Pick<RuleValidation, 'id' | 'fileName' | 'url' | 'createdAt' | 'urlOcr' | 'state' | 'finishedAt' | 'rulesInvalidated' | 'idDocument' | 'istituto'>
  )> }
);

export type LogValidazioneQueryVariables = Exact<{
  idValidazione: Scalars['String'];
}>;


export type LogValidazioneQuery = (
  { __typename?: 'Query' }
  & { logValidazione?: Maybe<Array<Maybe<(
    { __typename?: 'LogValidazione' }
    & Pick<LogValidazione, 'idValidazione' | 'idRule' | 'description'>
  )>>> }
);

export type LogValidazioniQueryVariables = Exact<{ [key: string]: never; }>;


export type LogValidazioniQuery = (
  { __typename?: 'Query' }
  & { logValidazioni?: Maybe<Array<Maybe<(
    { __typename?: 'LogValidazione' }
    & Pick<LogValidazione, 'idValidazione' | 'idRule' | 'description'>
  )>>> }
);

export type LoggedUserQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedUserQuery = (
  { __typename?: 'Query' }
  & { loggedUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type MetasCheckQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MetasCheckQuery = (
  { __typename?: 'Query' }
  & { metasCheck: (
    { __typename?: 'MetasCheck' }
    & Pick<MetasCheck, 'valid' | 'missingRequired'>
    & { conflicts: Array<(
      { __typename?: 'MetaConflicts' }
      & Pick<MetaConflicts, 'name'>
      & { conflicts: Array<(
        { __typename?: 'MetaConflict' }
        & Pick<MetaConflict, 'value' | 'count'>
      )> }
    )> }
  ) }
);

export type NotValidDossierRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type NotValidDossierRulesQuery = (
  { __typename?: 'Query' }
  & { notValidDossierRules?: Maybe<Array<Maybe<(
    { __typename?: 'DossierRule' }
    & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  )>>> }
);

export type NotificationReceiversQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationReceiversQuery = (
  { __typename?: 'Query' }
  & { notificationReceivers: Array<(
    { __typename?: 'NotificationReceiver' }
    & Pick<NotificationReceiver, 'id'>
  )> }
);

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ping'>
);

export type RequestSignedUrlForValidationDocumentQueryVariables = Exact<{
  filename: Scalars['String'];
}>;


export type RequestSignedUrlForValidationDocumentQuery = (
  { __typename?: 'Query' }
  & { requestSignedURLForValidationDocument: (
    { __typename?: 'SignedURLResponse' }
    & Pick<SignedUrlResponse, 'url' | 'name'>
  ) }
);

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'name' | 'description'>
  )>>> }
);

export type RuleValidationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RuleValidationQuery = (
  { __typename?: 'Query' }
  & { ruleValidation?: Maybe<(
    { __typename?: 'RuleValidation' }
    & Pick<RuleValidation, 'id' | 'fileName' | 'url' | 'createdAt' | 'urlOcr' | 'state' | 'finishedAt' | 'rulesInvalidated' | 'idDocument' | 'istituto'>
  )> }
);

export type RuleValidationsQueryVariables = Exact<{ [key: string]: never; }>;


export type RuleValidationsQuery = (
  { __typename?: 'Query' }
  & { ruleValidations?: Maybe<Array<Maybe<(
    { __typename?: 'RuleValidation' }
    & Pick<RuleValidation, 'id' | 'fileName' | 'url' | 'createdAt' | 'urlOcr' | 'state' | 'finishedAt' | 'rulesInvalidated' | 'idDocument' | 'istituto'>
  )>>> }
);

export type RunCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type RunCodeQuery = (
  { __typename?: 'Query' }
  & { runCode?: Maybe<(
    { __typename?: 'CodeResponse' }
    & Pick<CodeResponse, 'result' | 'logs'>
  )> }
);

export type RunDocumentRulesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RunDocumentRulesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'runDocumentRules'>
);

export type RunValidationQueryVariables = Exact<{
  idDocValidation: Scalars['String'];
}>;


export type RunValidationQuery = (
  { __typename?: 'Query' }
  & { runValidation?: Maybe<(
    { __typename?: 'RuleValidation' }
    & Pick<RuleValidation, 'id' | 'fileName' | 'url' | 'createdAt' | 'urlOcr' | 'state' | 'finishedAt' | 'rulesInvalidated' | 'idDocument' | 'istituto'>
  )> }
);

export type SetDocumentRuleActiveQueryVariables = Exact<{
  id: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type SetDocumentRuleActiveQuery = (
  { __typename?: 'Query' }
  & { setDocumentRuleActive: (
    { __typename?: 'DocumentRule' }
    & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
    & { dataTables?: Maybe<Array<Maybe<(
      { __typename?: 'DataTable' }
      & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
    )>>>, documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'createdAt'>
      & { documentRules?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentRule' }
        & Pick<DocumentRule, 'id' | 'description' | 'code' | 'packageJSON' | 'state' | 'nota' | 'active'>
        & { dataTables?: Maybe<Array<Maybe<(
          { __typename?: 'DataTable' }
          & Pick<DataTable, 'name' | 'ordinamento' | 'content'>
        )>>>, documentType?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  ) }
);

export type SetDossierRuleActiveQueryVariables = Exact<{
  id: Scalars['ID'];
  active: Scalars['Boolean'];
}>;


export type SetDossierRuleActiveQuery = (
  { __typename?: 'Query' }
  & { setDossierRuleActive?: Maybe<(
    { __typename?: 'DossierRule' }
    & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
    & { dossierType?: Maybe<(
      { __typename?: 'DossierType' }
      & Pick<DossierType, 'name' | 'createdAt'>
      & { dossierRules?: Maybe<Array<Maybe<(
        { __typename?: 'DossierRule' }
        & Pick<DossierRule, 'id' | 'description' | 'istitutoAppartenenza' | 'code' | 'dataTables' | 'packageJSON' | 'valid' | 'mustContain' | 'notContain' | 'nota' | 'gruppo' | 'active'>
        & { dossierType?: Maybe<(
          { __typename?: 'DossierType' }
          & Pick<DossierType, 'name' | 'createdAt'>
        )> }
      )>>> }
    )> }
  )> }
);

export type UserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  )>>> }
);


export const AddGroupDocument = gql`
    mutation AddGroup($subject: String!, $role: String!) {
  AddGroup(subject: $subject, role: $role) {
    subject
    role
    domain
  }
}
    `;
export const AddPolicyDocument = gql`
    mutation AddPolicy($subject: String!, $object: String!, $action: String!) {
  AddPolicy(subject: $subject, object: $object, action: $action) {
    subject
    domain
    object
    action
  }
}
    `;
export const AddDocumentLogDocument = gql`
    mutation addDocumentLog($event: String!, $document: ID!) {
  addDocumentLog(event: $event, document: $document) {
    id
    event
    timestamp
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
        dossierType {
          name
          createdAt
          dossierRules {
            id
            description
            istitutoAppartenenza
            code
            dataTables
            dossierType {
              name
              createdAt
            }
            packageJSON
            valid
            mustContain
            notContain
            nota
            gruppo
            active
          }
        }
        document {
          id
          url
          metas
          description
          state
          error
          formData
          tables
          rawTextWithGeometry
          rawText
        }
      }
    }
  }
}
    `;
export const AddDocumentMetasDocument = gql`
    mutation addDocumentMetas($id: ID!, $metas: String!) {
  addDocumentMetas(id: $id, metas: $metas) {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const AddDossierLogDocument = gql`
    mutation addDossierLog($event: String!, $dossier: ID!) {
  addDossierLog(event: $event, dossier: $dossier) {
    id
    event
    timestamp
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
          documentRules {
            id
            description
            code
            dataTables {
              name
              ordinamento
              content
            }
            documentType {
              name
              createdAt
            }
            packageJSON
            state
            nota
            active
          }
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const AddDossierMetasDocument = gql`
    mutation addDossierMetas($id: ID!, $metas: String!) {
  addDossierMetas(id: $id, metas: $metas) {
    id
    description
    hash
    createdAt
    metas
    state
    error
    tags
    fullDescription
    fileName
    fileTextContent
    input
    dossierTypeName
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
        dossierType {
          name
          createdAt
        }
        document {
          id
          url
          metas
          description
          state
          error
          formData
          tables
          rawTextWithGeometry
          rawText
        }
      }
    }
  }
}
    `;
export const AddNotificationReceiverDocument = gql`
    mutation addNotificationReceiver($receiver: String!) {
  addNotificationReceiver(receiver: $receiver) {
    id
  }
}
    `;
export const ClearDocumentMetasDocument = gql`
    mutation clearDocumentMetas($id: ID!) {
  clearDocumentMetas(id: $id) {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const ClearDossierMetasDocument = gql`
    mutation clearDossierMetas($id: ID!) {
  clearDossierMetas(id: $id) {
    id
    description
    hash
    createdAt
    metas
    state
    error
    tags
    fullDescription
    fileName
    fileTextContent
    input
    dossierTypeName
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
        dossierType {
          name
          createdAt
        }
        document {
          id
          url
          metas
          description
          state
          error
          formData
          tables
          rawTextWithGeometry
          rawText
        }
      }
    }
  }
}
    `;
export const CreateDataTableDocument = gql`
    mutation createDataTable($input: NewDataTable!) {
  createDataTable(input: $input) {
    name
    ordinamento
    content
  }
}
    `;
export const CreateDocValidationsDocument = gql`
    mutation createDocValidations($files: [DocValidationUpload!]!) {
  createDocValidations(files: $files) {
    id
    description
    istitutoAppartenenza
    urlFile
    urlOCR
    createdAt
  }
}
    `;
export const CreateDocumentDocument = gql`
    mutation createDocument($input: NewDocument!) {
  createDocument(input: $input) {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const CreateDocumentRuleDocument = gql`
    mutation createDocumentRule($input: NewDocumentRule!) {
  createDocumentRule(input: $input) {
    id
    description
    code
    dataTables {
      name
      ordinamento
      content
    }
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    packageJSON
    state
    nota
    active
  }
}
    `;
export const CreateDocumentTypeDocument = gql`
    mutation createDocumentType($input: NewDocumentType!) {
  createDocumentType(input: $input) {
    name
    createdAt
    documentRules {
      id
      description
      code
      dataTables {
        name
        ordinamento
        content
      }
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          packageJSON
          state
          nota
          active
        }
      }
      packageJSON
      state
      nota
      active
    }
  }
}
    `;
export const CreateDossierDocument = gql`
    mutation createDossier($input: DossierInput!) {
  createDossier(input: $input) {
    id
    description
    hash
    createdAt
    metas
    state
    error
    tags
    fullDescription
    fileName
    fileTextContent
    input
    dossierTypeName
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
        dossierType {
          name
          createdAt
        }
        document {
          id
          url
          metas
          description
          state
          error
          formData
          tables
          rawTextWithGeometry
          rawText
        }
      }
    }
  }
}
    `;
export const CreateDossierRuleDocument = gql`
    mutation createDossierRule($input: NewDossierRule!) {
  createDossierRule(input: $input) {
    id
    description
    istitutoAppartenenza
    code
    dataTables
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    packageJSON
    valid
    mustContain
    notContain
    nota
    gruppo
    active
  }
}
    `;
export const CreateDossierTypeDocument = gql`
    mutation createDossierType($input: NewDossierType!) {
  createDossierType(input: $input) {
    name
    createdAt
    dossierRules {
      id
      description
      istitutoAppartenenza
      code
      dataTables
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      packageJSON
      valid
      mustContain
      notContain
      nota
      gruppo
      active
    }
  }
}
    `;
export const CreateRoleDocument = gql`
    mutation createRole($name: String!, $description: String!) {
  createRole(name: $name, description: $description) {
    name
    description
  }
}
    `;
export const CreateRuleValidationDocument = gql`
    mutation createRuleValidation($documentID: String, $name: String!, $url: String!, $istitutoAppartenenza: String!) {
  createRuleValidation(
    documentID: $documentID
    name: $name
    url: $url
    istitutoAppartenenza: $istitutoAppartenenza
  ) {
    id
    fileName
    url
    createdAt
    urlOcr
    state
    finishedAt
    rulesInvalidated
    idDocument
    istituto
  }
}
    `;
export const CreateUserDocument = gql`
    mutation createUser($user: UserInputWithPassword) {
  createUser(user: $user) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
  }
}
    `;
export const DeleteDataTableDocument = gql`
    mutation deleteDataTable($name: String!) {
  deleteDataTable(name: $name)
}
    `;
export const DeleteDocValidationsDocument = gql`
    mutation deleteDocValidations($ids: [String!]!) {
  deleteDocValidations(ids: $ids)
}
    `;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: ID!) {
  deleteDocument(id: $id) {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const DeleteDocumentRuleDocument = gql`
    mutation deleteDocumentRule($id: ID!) {
  deleteDocumentRule(id: $id) {
    id
    description
    code
    dataTables {
      name
      ordinamento
      content
    }
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    packageJSON
    state
    nota
    active
  }
}
    `;
export const DeleteDocumentTypeDocument = gql`
    mutation deleteDocumentType($name: String!) {
  deleteDocumentType(name: $name) {
    name
    createdAt
    documentRules {
      id
      description
      code
      dataTables {
        name
        ordinamento
        content
      }
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          packageJSON
          state
          nota
          active
        }
      }
      packageJSON
      state
      nota
      active
    }
  }
}
    `;
export const DeleteDossierDocument = gql`
    mutation deleteDossier($id: ID!) {
  deleteDossier(id: $id) {
    id
    description
    hash
    createdAt
    metas
    state
    error
    tags
    fullDescription
    fileName
    fileTextContent
    input
    dossierTypeName
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
        dossierType {
          name
          createdAt
        }
        document {
          id
          url
          metas
          description
          state
          error
          formData
          tables
          rawTextWithGeometry
          rawText
        }
      }
    }
  }
}
    `;
export const DeleteDossierRuleDocument = gql`
    mutation deleteDossierRule($id: ID!) {
  deleteDossierRule(id: $id) {
    id
    description
    istitutoAppartenenza
    code
    dataTables
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    packageJSON
    valid
    mustContain
    notContain
    nota
    gruppo
    active
  }
}
    `;
export const DeleteDossierTypeDocument = gql`
    mutation deleteDossierType($name: String!) {
  deleteDossierType(name: $name)
}
    `;
export const DeleteFormDataDocument = gql`
    mutation deleteFormData($id: ID!) {
  deleteFormData(id: $id) {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const DeleteNotificationReceiverDocument = gql`
    mutation deleteNotificationReceiver($receiver: String!) {
  deleteNotificationReceiver(receiver: $receiver) {
    id
  }
}
    `;
export const DeleteUserDocument = gql`
    mutation deleteUser($email: String!) {
  deleteUser(email: $email)
}
    `;
export const DownloadDocumentRuleDocument = gql`
    mutation downloadDocumentRule($id: ID!, $ruleId: ID!) {
  downloadDocumentRule(id: $id, ruleId: $ruleId) {
    name
    bsae64Content
  }
}
    `;
export const DownloadDossierRuleDocument = gql`
    mutation downloadDossierRule($id: ID!, $ruleId: ID!) {
  downloadDossierRule(id: $id, ruleId: $ruleId) {
    name
    bsae64Content
  }
}
    `;
export const ImpersonatesDocument = gql`
    mutation impersonates($email: String!) {
  impersonates(email: $email) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
  }
}
    `;
export const ImportDossierRulesDocument = gql`
    mutation importDossierRules($input: String!) {
  importDossierRules(input: $input) {
    id
    description
    istitutoAppartenenza
    code
    dataTables
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    packageJSON
    valid
    mustContain
    notContain
    nota
    gruppo
    active
  }
}
    `;
export const InsertLogValidazioneDocument = gql`
    mutation insertLogValidazione($idValutazione: String!, $idRule: String!, $description: String) {
  insertLogValidazione(
    idValutazione: $idValutazione
    idRule: $idRule
    description: $description
  ) {
    idValidazione
    idRule
    description
  }
}
    `;
export const PingMutationDocument = gql`
    mutation pingMutation {
  pingMutation
}
    `;
export const RecoverPasswordDocument = gql`
    mutation recoverPassword($email: String!) {
  recoverPassword(email: $email)
}
    `;
export const ReplaceDocumentMetasDocument = gql`
    mutation replaceDocumentMetas($id: ID!, $metas: String!) {
  replaceDocumentMetas(id: $id, metas: $metas) {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const ReplaceDossierMetasDocument = gql`
    mutation replaceDossierMetas($id: ID!, $metas: String!) {
  replaceDossierMetas(id: $id, metas: $metas) {
    id
    description
    hash
    createdAt
    metas
    state
    error
    tags
    fullDescription
    fileName
    fileTextContent
    input
    dossierTypeName
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
        dossierType {
          name
          createdAt
        }
        document {
          id
          url
          metas
          description
          state
          error
          formData
          tables
          rawTextWithGeometry
          rawText
        }
      }
    }
  }
}
    `;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $code: String!, $newPassword: String!) {
  resetPassword(email: $email, code: $code, newPassword: $newPassword) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
  }
}
    `;
export const RunAgainDossierRulesThatHasErrorsDocument = gql`
    mutation runAgainDossierRulesThatHasErrors($id: ID!) {
  runAgainDossierRulesThatHasErrors(id: $id)
}
    `;
export const RunDocumentRuleDocument = gql`
    mutation runDocumentRule($id: ID!, $ruleId: ID!) {
  runDocumentRule(id: $id, ruleId: $ruleId)
}
    `;
export const RunDossierRuleDocument = gql`
    mutation runDossierRule($id: ID!, $ruleId: ID!, $groupId: ID) {
  runDossierRule(id: $id, ruleId: $ruleId, groupId: $groupId)
}
    `;
export const RunDossierRulesDocument = gql`
    mutation runDossierRules($id: ID!) {
  runDossierRules(id: $id)
}
    `;
export const RunTemporaryDossierRuleDocument = gql`
    mutation runTemporaryDossierRule($id: ID!, $ruleID: ID!, $ruleName: String!, $code: String!, $packagecode: String!) {
  runTemporaryDossierRule(
    id: $id
    ruleID: $ruleID
    ruleName: $ruleName
    code: $code
    packagecode: $packagecode
  )
}
    `;
export const RunTemporaryRuleDocument = gql`
    mutation runTemporaryRule($id: ID!, $ruleID: ID!, $ruleName: String!, $code: String!, $packagecode: String!, $dataTables: [String]) {
  runTemporaryRule(
    id: $id
    ruleID: $ruleID
    ruleName: $ruleName
    code: $code
    packagecode: $packagecode
    dataTables: $dataTables
  )
}
    `;
export const TestRuleDocument = gql`
    mutation testRule($docValidationID: String!, $mustContain: [String], $notContain: [String]) {
  testRule(
    docValidationID: $docValidationID
    mustContain: $mustContain
    notContain: $notContain
  )
}
    `;
export const UpdateAvatarDocument = gql`
    mutation updateAvatar($email: String!, $avatar: String!) {
  updateAvatar(email: $email, avatar: $avatar) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UpdateDataTableDocument = gql`
    mutation updateDataTable($name: String!, $input: UpdateDataTable!) {
  updateDataTable(name: $name, input: $input) {
    name
    ordinamento
    content
  }
}
    `;
export const UpdateDocumentDocument = gql`
    mutation updateDocument($id: ID!, $input: UpdateDocument!) {
  updateDocument(id: $id, input: $input) {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const UpdateDocumentRuleDocument = gql`
    mutation updateDocumentRule($id: String!, $input: UpdateDocumentRule!) {
  updateDocumentRule(id: $id, input: $input) {
    id
    description
    code
    dataTables {
      name
      ordinamento
      content
    }
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    packageJSON
    state
    nota
    active
  }
}
    `;
export const UpdateDocumentTypeDocument = gql`
    mutation updateDocumentType($name: String!, $input: UpdateDocumentType!) {
  updateDocumentType(name: $name, input: $input) {
    name
    createdAt
    documentRules {
      id
      description
      code
      dataTables {
        name
        ordinamento
        content
      }
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          packageJSON
          state
          nota
          active
        }
      }
      packageJSON
      state
      nota
      active
    }
  }
}
    `;
export const UpdateDossierDocument = gql`
    mutation updateDossier($id: ID!, $input: UpdateDossier!) {
  updateDossier(id: $id, input: $input) {
    id
    description
    hash
    createdAt
    metas
    state
    error
    tags
    fullDescription
    fileName
    fileTextContent
    input
    dossierTypeName
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
        dossierType {
          name
          createdAt
        }
        document {
          id
          url
          metas
          description
          state
          error
          formData
          tables
          rawTextWithGeometry
          rawText
        }
      }
    }
  }
}
    `;
export const UpdateDossierRuleDocument = gql`
    mutation updateDossierRule($id: String!, $input: UpdateDossierRule!) {
  updateDossierRule(id: $id, input: $input) {
    id
    description
    istitutoAppartenenza
    code
    dataTables
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    packageJSON
    valid
    mustContain
    notContain
    nota
    gruppo
    active
  }
}
    `;
export const UpdateDossierTypeDocument = gql`
    mutation updateDossierType($name: String!, $input: UpdateDossierType!) {
  updateDossierType(name: $name, input: $input) {
    name
    createdAt
    dossierRules {
      id
      description
      istitutoAppartenenza
      code
      dataTables
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      packageJSON
      valid
      mustContain
      notContain
      nota
      gruppo
      active
    }
  }
}
    `;
export const UpdateHelperCodeDocument = gql`
    mutation updateHelperCode($id: ID!, $code: String!) {
  updateHelperCode(id: $id, code: $code)
}
    `;
export const UpdateMustContainDocument = gql`
    mutation updateMustContain($id: String!, $input: [String!]) {
  updateMustContain(id: $id, input: $input)
}
    `;
export const UpdateNotContainDocument = gql`
    mutation updateNotContain($id: String!, $input: [String!]) {
  updateNotContain(id: $id, input: $input)
}
    `;
export const UpdateSelfPasswordDocument = gql`
    mutation updateSelfPassword($email: String!, $oldPassword: String!, $newPassword: String!) {
  updateSelfPassword(
    email: $email
    oldPassword: $oldPassword
    newPassword: $newPassword
  ) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UpdateUserDocument = gql`
    mutation updateUser($email: String!, $user: UpdateUserInput) {
  updateUser(email: $email, user: $user) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UpdateUserPasswordDocument = gql`
    mutation updateUserPassword($email: String!, $newPassword: String!) {
  updateUserPassword(email: $email, newPassword: $newPassword) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UploadRuleValidationDocument = gql`
    mutation uploadRuleValidation($fileName: String!, $file: String!, $istitutoAppartenenza: String!) {
  uploadRuleValidation(
    fileName: $fileName
    file: $file
    istitutoAppartenenza: $istitutoAppartenenza
  ) {
    id
    fileName
    url
    createdAt
    urlOcr
    state
    finishedAt
    rulesInvalidated
    idDocument
    istituto
  }
}
    `;
export const BasicLoginDocument = gql`
    query basicLogin($username: String!, $password: String!) {
  basicLogin(username: $username, password: $password) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
  }
}
    `;
export const DataTableDocument = gql`
    query dataTable($name: String!) {
  dataTable(name: $name) {
    name
    ordinamento
    content
  }
}
    `;
export const DataTablesDocument = gql`
    query dataTables {
  dataTables {
    name
    ordinamento
    content
  }
}
    `;
export const DocValidationDocument = gql`
    query docValidation($id: String!) {
  docValidation(id: $id) {
    id
    description
    istitutoAppartenenza
    urlFile
    urlOCR
    createdAt
  }
}
    `;
export const DocValidationsDocument = gql`
    query docValidations {
  docValidations {
    id
    description
    istitutoAppartenenza
    urlFile
    urlOCR
    createdAt
  }
}
    `;
export const DocumentDocument = gql`
    query document($id: ID!) {
  document(id: $id) {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
      }
    }
  }
}
    `;
export const DocumentRuleDocument = gql`
    query documentRule($id: ID!) {
  documentRule(id: $id) {
    id
    description
    code
    dataTables {
      name
      ordinamento
      content
    }
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    packageJSON
    state
    nota
    active
  }
}
    `;
export const DocumentRulesDocument = gql`
    query documentRules {
  documentRules {
    id
    description
    code
    dataTables {
      name
      ordinamento
      content
    }
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        packageJSON
        state
        nota
        active
      }
    }
    packageJSON
    state
    nota
    active
  }
}
    `;
export const DocumentTypeDocument = gql`
    query documentType($name: String!) {
  documentType(name: $name) {
    name
    createdAt
    documentRules {
      id
      description
      code
      dataTables {
        name
        ordinamento
        content
      }
      documentType {
        name
        createdAt
      }
      packageJSON
      state
      nota
      active
    }
  }
}
    `;
export const DocumentTypesDocument = gql`
    query documentTypes {
  documentTypes {
    name
    createdAt
    documentRules {
      id
      description
      code
      dataTables {
        name
        ordinamento
        content
      }
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          packageJSON
          state
          nota
          active
        }
      }
      packageJSON
      state
      nota
      active
    }
  }
}
    `;
export const DocumentsDocument = gql`
    query documents {
  documents {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const DocumentsByDossierDocument = gql`
    query documentsByDossier($dossier: String!) {
  documentsByDossier(dossier: $dossier) {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const DocumentsInfoDocument = gql`
    query documentsInfo {
  documentsInfo {
    id
    url
    metas
    description
    state
    error
    formData
    tables
    rawTextWithGeometry
    rawText
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const DocumentsNameForDossierDocument = gql`
    query documentsNameForDossier($id: String!) {
  documentsNameForDossier(id: $id) {
    id
    name
  }
}
    `;
export const DossierDocument = gql`
    query dossier($id: ID!) {
  dossier(id: $id) {
    id
    description
    hash
    createdAt
    metas
    state
    error
    tags
    fullDescription
    fileName
    fileTextContent
    input
    dossierTypeName
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
      }
    }
  }
}
    `;
export const DossierCallbacksDocument = gql`
    query dossierCallbacks($id: ID!) {
  dossierCallbacks(id: $id) {
    dossierId
    url
    success
    method
    requestBody
    responseCode
    responseBody
    timestamp
    errorDescription
    idValutazione
  }
}
    `;
export const DossierRuleDocument = gql`
    query dossierRule($id: ID!) {
  dossierRule(id: $id) {
    id
    description
    istitutoAppartenenza
    code
    dataTables
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    packageJSON
    valid
    mustContain
    notContain
    nota
    gruppo
    active
  }
}
    `;
export const DossierRulesDocument = gql`
    query dossierRules($codeToSearch: String) {
  dossierRules(codeToSearch: $codeToSearch) {
    id
    description
    istitutoAppartenenza
    code
    dataTables
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    packageJSON
    valid
    mustContain
    notContain
    nota
    gruppo
    active
  }
}
    `;
export const DossierTypeDocument = gql`
    query dossierType($name: String!) {
  dossierType(name: $name) {
    name
    createdAt
    dossierRules {
      id
      description
      istitutoAppartenenza
      code
      dataTables
      dossierType {
        name
        createdAt
      }
      packageJSON
      valid
      mustContain
      notContain
      nota
      gruppo
      active
    }
  }
}
    `;
export const DossierTypesDocument = gql`
    query dossierTypes {
  dossierTypes {
    name
    createdAt
    dossierRules {
      id
      description
      istitutoAppartenenza
      code
      dataTables
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      packageJSON
      valid
      mustContain
      notContain
      nota
      gruppo
      active
    }
  }
}
    `;
export const DossiersDocument = gql`
    query dossiers {
  dossiers {
    id
    description
    hash
    createdAt
    metas
    state
    error
    tags
    fullDescription
    fileName
    fileTextContent
    input
    dossierTypeName
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
        dossierType {
          name
          createdAt
        }
        document {
          id
          url
          metas
          description
          state
          error
          formData
          tables
          rawTextWithGeometry
          rawText
        }
      }
    }
  }
}
    `;
export const DownloadAllValidationFilesDocument = gql`
    query downloadAllValidationFiles($id: String!) {
  downloadAllValidationFiles(id: $id) {
    name
    bsae64Content
  }
}
    `;
export const DownloadDiffDocument = gql`
    query downloadDiff($id: String!) {
  downloadDiff(id: $id)
}
    `;
export const DownloadDocumentDocument = gql`
    query downloadDocument($id: ID!) {
  downloadDocument(id: $id) {
    id
    name
    base64
    filename
    extension
  }
}
    `;
export const DownloadDocumentsDocument = gql`
    query downloadDocuments($dossier: ID!) {
  downloadDocuments(dossier: $dossier) {
    id
    name
    base64
    filename
    extension
  }
}
    `;
export const DownloadDossierDocument = gql`
    query downloadDossier($id: ID!) {
  downloadDossier(id: $id) {
    base64
    filename
    extension
  }
}
    `;
export const DownloadOcrDocument = gql`
    query downloadOCR($id: String!) {
  downloadOCR(id: $id)
}
    `;
export const DownloadPdfDocument = gql`
    query downloadPDF($id: String!) {
  downloadPDF(id: $id)
}
    `;
export const FixMetasDocument = gql`
    query fixMetas($id: ID!, $metas: [MetaValue!]!) {
  fixMetas(id: $id, metas: $metas)
}
    `;
export const GetAllDocumentCodeExecutionsDocument = gql`
    query getAllDocumentCodeExecutions {
  getAllDocumentCodeExecutions {
    id
    documentId
    ruleId
    ruleName
    executedAt
    code
    rawText
    rawTextWithGeometry
    tables
    dataTables
    formData
    state
    result
    errorLog
    logs
    platform
  }
}
    `;
export const GetAllDossierCodeExecutionsDocument = gql`
    query getAllDossierCodeExecutions {
  getAllDossierCodeExecutions {
    id
    dossierId
    ruleId
    ruleName
    executedAt
    metas
    code
    state
    result
    errors
    logs
    platform
    input
  }
}
    `;
export const GetAllDossierRuleExecutionsDocument = gql`
    query getAllDossierRuleExecutions {
  getAllDossierRuleExecutions {
    executionID
    ruleID
    inputKey
    dossierID
    inputIndex
    input
    warning
    error
    state
    result
    createdAt
    finishedAt
    ruleName
    platform
    groupExecutionID
  }
}
    `;
export const GetDocumentCodeExecutionsDocument = gql`
    query getDocumentCodeExecutions($document: ID!) {
  getDocumentCodeExecutions(document: $document) {
    id
    documentId
    ruleId
    ruleName
    executedAt
    code
    rawText
    rawTextWithGeometry
    tables
    dataTables
    formData
    state
    result
    errorLog
    logs
    platform
  }
}
    `;
export const GetDocumentLogsDocument = gql`
    query getDocumentLogs {
  getDocumentLogs {
    id
    event
    timestamp
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
        dossierType {
          name
          createdAt
          dossierRules {
            id
            description
            istitutoAppartenenza
            code
            dataTables
            dossierType {
              name
              createdAt
            }
            packageJSON
            valid
            mustContain
            notContain
            nota
            gruppo
            active
          }
        }
        document {
          id
          url
          metas
          description
          state
          error
          formData
          tables
          rawTextWithGeometry
          rawText
        }
      }
    }
  }
}
    `;
export const GetDocumentRuleExecutionsDocument = gql`
    query getDocumentRuleExecutions($id: ID!) {
  getDocumentRuleExecutions(id: $id) {
    id
    documentId
    ruleId
    ruleName
    executedAt
    code
    rawText
    rawTextWithGeometry
    tables
    dataTables
    formData
    state
    result
    errorLog
    logs
    platform
  }
}
    `;
export const GetDossierCodeExecutionsDocument = gql`
    query getDossierCodeExecutions($dossier: ID!) {
  getDossierCodeExecutions(dossier: $dossier) {
    id
    dossierId
    ruleId
    ruleName
    executedAt
    metas
    code
    state
    result
    errors
    logs
    platform
    input
  }
}
    `;
export const GetDossierLogsDocument = gql`
    query getDossierLogs {
  getDossierLogs {
    id
    event
    timestamp
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
          documentRules {
            id
            description
            code
            dataTables {
              name
              ordinamento
              content
            }
            documentType {
              name
              createdAt
            }
            packageJSON
            state
            nota
            active
          }
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const GetDossierResultAggregateDocument = gql`
    query getDossierResultAggregate($dossierID: String!) {
  getDossierResultAggregate(dossierID: $dossierID)
}
    `;
export const GetDossierRuleExecutionsDocument = gql`
    query getDossierRuleExecutions($ruleID: String!, $dossierID: String!) {
  getDossierRuleExecutions(ruleID: $ruleID, dossierID: $dossierID) {
    executionID
    ruleID
    inputKey
    dossierID
    inputIndex
    input
    warning
    error
    state
    result
    createdAt
    finishedAt
    ruleName
    platform
    groupExecutionID
  }
}
    `;
export const GetDossierRuleExecutionsByDossierDocument = gql`
    query getDossierRuleExecutionsByDossier($dossierID: String!) {
  getDossierRuleExecutionsByDossier(dossierID: $dossierID) {
    executionID
    ruleID
    inputKey
    dossierID
    inputIndex
    input
    warning
    error
    state
    result
    createdAt
    finishedAt
    ruleName
    platform
    groupExecutionID
  }
}
    `;
export const GetDossierRuleExecutionsByRuleDocument = gql`
    query getDossierRuleExecutionsByRule($ruleID: String!) {
  getDossierRuleExecutionsByRule(ruleID: $ruleID) {
    executionID
    ruleID
    inputKey
    dossierID
    inputIndex
    input
    warning
    error
    state
    result
    createdAt
    finishedAt
    ruleName
    platform
    groupExecutionID
  }
}
    `;
export const GetDossierRuleLogsDocument = gql`
    query getDossierRuleLogs($id: ID!) {
  getDossierRuleLogs(id: $id) {
    id
    dossierId
    ruleId
    ruleName
    executedAt
    metas
    code
    state
    result
    errors
    logs
    platform
    input
  }
}
    `;
export const GetDossierRulesExecutionCountDocument = gql`
    query getDossierRulesExecutionCount($dossierID: String!) {
  getDossierRulesExecutionCount(dossierID: $dossierID) {
    processed
    total
  }
}
    `;
export const GetFailedDossierRuleExecsDocument = gql`
    query getFailedDossierRuleExecs($dossierID: String!) {
  getFailedDossierRuleExecs(dossierID: $dossierID)
}
    `;
export const GetGroupsDocument = gql`
    query getGroups {
  getGroups {
    subject
    role
    domain
  }
}
    `;
export const GetHelperCodeDocument = gql`
    query getHelperCode($id: ID!) {
  getHelperCode(id: $id) {
    id
    description
    code
  }
}
    `;
export const GetLogsByDocumentDocument = gql`
    query getLogsByDocument($document: ID!) {
  getLogsByDocument(document: $document) {
    id
    event
    timestamp
    document {
      id
      url
      metas
      description
      state
      error
      formData
      tables
      rawTextWithGeometry
      rawText
      documentType {
        name
        createdAt
        documentRules {
          id
          description
          code
          dataTables {
            name
            ordinamento
            content
          }
          documentType {
            name
            createdAt
          }
          packageJSON
          state
          nota
          active
        }
      }
      dossier {
        id
        description
        hash
        createdAt
        metas
        state
        error
        tags
        fullDescription
        fileName
        fileTextContent
        input
        dossierTypeName
        dossierType {
          name
          createdAt
          dossierRules {
            id
            description
            istitutoAppartenenza
            code
            dataTables
            dossierType {
              name
              createdAt
            }
            packageJSON
            valid
            mustContain
            notContain
            nota
            gruppo
            active
          }
        }
        document {
          id
          url
          metas
          description
          state
          error
          formData
          tables
          rawTextWithGeometry
          rawText
        }
      }
    }
  }
}
    `;
export const GetLogsByDossierDocument = gql`
    query getLogsByDossier($dossier: ID!) {
  getLogsByDossier(dossier: $dossier) {
    id
    event
    timestamp
    dossier {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
          documentRules {
            id
            description
            code
            dataTables {
              name
              ordinamento
              content
            }
            documentType {
              name
              createdAt
            }
            packageJSON
            state
            nota
            active
          }
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
        }
      }
    }
  }
}
    `;
export const GetNewAccessTokenDocument = gql`
    query getNewAccessToken($refreshToken: String!) {
  getNewAccessToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
  }
}
    `;
export const GetPaginatedDocExecutionsDocument = gql`
    query getPaginatedDocExecutions($input: TableInput!) {
  getPaginatedDocExecutions(input: $input) {
    pages
    items {
      id
      documentId
      ruleId
      ruleName
      executedAt
      code
      rawText
      rawTextWithGeometry
      tables
      dataTables
      formData
      state
      result
      errorLog
      logs
      platform
    }
    options {
      column
      options
    }
    op {
      id
      result
      type
    }
  }
}
    `;
export const GetPaginatedDossierDocument = gql`
    query getPaginatedDossier($input: TableInput!) {
  getPaginatedDossier(input: $input) {
    pages
    items {
      id
      description
      hash
      createdAt
      metas
      state
      error
      tags
      fullDescription
      fileName
      fileTextContent
      input
      dossierTypeName
      dossierType {
        name
        createdAt
        dossierRules {
          id
          description
          istitutoAppartenenza
          code
          dataTables
          dossierType {
            name
            createdAt
          }
          packageJSON
          valid
          mustContain
          notContain
          nota
          gruppo
          active
        }
      }
      document {
        id
        url
        metas
        description
        state
        error
        formData
        tables
        rawTextWithGeometry
        rawText
        documentType {
          name
          createdAt
          documentRules {
            id
            description
            code
            dataTables {
              name
              ordinamento
              content
            }
            documentType {
              name
              createdAt
            }
            packageJSON
            state
            nota
            active
          }
        }
        dossier {
          id
          description
          hash
          createdAt
          metas
          state
          error
          tags
          fullDescription
          fileName
          fileTextContent
          input
          dossierTypeName
          dossierType {
            name
            createdAt
          }
          document {
            id
            url
            metas
            description
            state
            error
            formData
            tables
            rawTextWithGeometry
            rawText
          }
        }
      }
    }
    options {
      column
      options
    }
    op {
      id
      result
      type
    }
  }
}
    `;
export const GetPaginatedDossierCodeExecutionsDocument = gql`
    query getPaginatedDossierCodeExecutions($input: TableInput!) {
  getPaginatedDossierCodeExecutions(input: $input) {
    pages
    items {
      id
      dossierId
      ruleName
      ruleId
      code
      state
      metas
      result
      executedAt
      logs
      errorLog
      platform
    }
    options {
      column
      options
    }
    op {
      id
      result
      type
    }
  }
}
    `;
export const GetPaginatedDossierRuleExecDocument = gql`
    query getPaginatedDossierRuleExec($input: TableInput!) {
  getPaginatedDossierRuleExec(input: $input) {
    pages
    items {
      executionID
      ruleID
      inputKey
      dossierID
      inputIndex
      input
      warning
      error
      state
      result
      createdAt
      finishedAt
      ruleName
      platform
      groupExecutionID
    }
    options {
      column
      options
    }
    op {
      id
      result
      type
    }
  }
}
    `;
export const GetPoliciesDocument = gql`
    query getPolicies {
  getPolicies {
    subject
    domain
    object
    action
  }
}
    `;
export const GetRulesByDocumentDocument = gql`
    query getRulesByDocument($id: ID!) {
  getRulesByDocument(id: $id) {
    id
    description
    code
    dataTables {
      name
      ordinamento
      content
    }
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    packageJSON
    state
    nota
    active
  }
}
    `;
export const LatestRuleValitationDocument = gql`
    query latestRuleValitation {
  latestRuleValitation {
    id
    fileName
    url
    createdAt
    urlOcr
    state
    finishedAt
    rulesInvalidated
    idDocument
    istituto
  }
}
    `;
export const LogValidazioneDocument = gql`
    query logValidazione($idValidazione: String!) {
  logValidazione(idValidazione: $idValidazione) {
    idValidazione
    idRule
    description
  }
}
    `;
export const LogValidazioniDocument = gql`
    query logValidazioni {
  logValidazioni {
    idValidazione
    idRule
    description
  }
}
    `;
export const LoggedUserDocument = gql`
    query loggedUser {
  loggedUser {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const MetasCheckDocument = gql`
    query metasCheck($id: ID!) {
  metasCheck(id: $id) {
    valid
    missingRequired
    conflicts {
      name
      conflicts {
        value
        count
      }
    }
  }
}
    `;
export const NotValidDossierRulesDocument = gql`
    query notValidDossierRules {
  notValidDossierRules {
    id
    description
    istitutoAppartenenza
    code
    dataTables
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    packageJSON
    valid
    mustContain
    notContain
    nota
    gruppo
    active
  }
}
    `;
export const NotificationReceiversDocument = gql`
    query notificationReceivers {
  notificationReceivers {
    id
  }
}
    `;
export const PingDocument = gql`
    query ping {
  ping
}
    `;
export const RequestSignedUrlForValidationDocumentDocument = gql`
    query requestSignedURLForValidationDocument($filename: String!) {
  requestSignedURLForValidationDocument(filename: $filename) {
    url
    name
  }
}
    `;
export const RolesDocument = gql`
    query roles {
  roles {
    name
    description
  }
}
    `;
export const RuleValidationDocument = gql`
    query ruleValidation($id: String!) {
  ruleValidation(id: $id) {
    id
    fileName
    url
    createdAt
    urlOcr
    state
    finishedAt
    rulesInvalidated
    idDocument
    istituto
  }
}
    `;
export const RuleValidationsDocument = gql`
    query ruleValidations {
  ruleValidations {
    id
    fileName
    url
    createdAt
    urlOcr
    state
    finishedAt
    rulesInvalidated
    idDocument
    istituto
  }
}
    `;
export const RunCodeDocument = gql`
    query runCode($code: String!) {
  runCode(code: $code) {
    result
    logs
  }
}
    `;
export const RunDocumentRulesDocument = gql`
    query runDocumentRules($id: ID!) {
  runDocumentRules(id: $id)
}
    `;
export const RunValidationDocument = gql`
    query runValidation($idDocValidation: String!) {
  runValidation(idDocValidation: $idDocValidation) {
    id
    fileName
    url
    createdAt
    urlOcr
    state
    finishedAt
    rulesInvalidated
    idDocument
    istituto
  }
}
    `;
export const SetDocumentRuleActiveDocument = gql`
    query setDocumentRuleActive($id: ID!, $active: Boolean!) {
  setDocumentRuleActive(id: $id, active: $active) {
    id
    description
    code
    dataTables {
      name
      ordinamento
      content
    }
    documentType {
      name
      createdAt
      documentRules {
        id
        description
        code
        dataTables {
          name
          ordinamento
          content
        }
        documentType {
          name
          createdAt
        }
        packageJSON
        state
        nota
        active
      }
    }
    packageJSON
    state
    nota
    active
  }
}
    `;
export const SetDossierRuleActiveDocument = gql`
    query setDossierRuleActive($id: ID!, $active: Boolean!) {
  setDossierRuleActive(id: $id, active: $active) {
    id
    description
    istitutoAppartenenza
    code
    dataTables
    dossierType {
      name
      createdAt
      dossierRules {
        id
        description
        istitutoAppartenenza
        code
        dataTables
        dossierType {
          name
          createdAt
        }
        packageJSON
        valid
        mustContain
        notContain
        nota
        gruppo
        active
      }
    }
    packageJSON
    valid
    mustContain
    notContain
    nota
    gruppo
    active
  }
}
    `;
export const UserDocument = gql`
    query user($email: String!) {
  user(email: $email) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UsersDocument = gql`
    query users {
  users {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AddGroup(variables: AddGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddGroupMutation> {
      return withWrapper(() => client.request<AddGroupMutation>(AddGroupDocument, variables, requestHeaders));
    },
    AddPolicy(variables: AddPolicyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddPolicyMutation> {
      return withWrapper(() => client.request<AddPolicyMutation>(AddPolicyDocument, variables, requestHeaders));
    },
    addDocumentLog(variables: AddDocumentLogMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddDocumentLogMutation> {
      return withWrapper(() => client.request<AddDocumentLogMutation>(AddDocumentLogDocument, variables, requestHeaders));
    },
    addDocumentMetas(variables: AddDocumentMetasMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddDocumentMetasMutation> {
      return withWrapper(() => client.request<AddDocumentMetasMutation>(AddDocumentMetasDocument, variables, requestHeaders));
    },
    addDossierLog(variables: AddDossierLogMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddDossierLogMutation> {
      return withWrapper(() => client.request<AddDossierLogMutation>(AddDossierLogDocument, variables, requestHeaders));
    },
    addDossierMetas(variables: AddDossierMetasMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddDossierMetasMutation> {
      return withWrapper(() => client.request<AddDossierMetasMutation>(AddDossierMetasDocument, variables, requestHeaders));
    },
    addNotificationReceiver(variables: AddNotificationReceiverMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddNotificationReceiverMutation> {
      return withWrapper(() => client.request<AddNotificationReceiverMutation>(AddNotificationReceiverDocument, variables, requestHeaders));
    },
    clearDocumentMetas(variables: ClearDocumentMetasMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ClearDocumentMetasMutation> {
      return withWrapper(() => client.request<ClearDocumentMetasMutation>(ClearDocumentMetasDocument, variables, requestHeaders));
    },
    clearDossierMetas(variables: ClearDossierMetasMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ClearDossierMetasMutation> {
      return withWrapper(() => client.request<ClearDossierMetasMutation>(ClearDossierMetasDocument, variables, requestHeaders));
    },
    createDataTable(variables: CreateDataTableMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDataTableMutation> {
      return withWrapper(() => client.request<CreateDataTableMutation>(CreateDataTableDocument, variables, requestHeaders));
    },
    createDocValidations(variables: CreateDocValidationsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDocValidationsMutation> {
      return withWrapper(() => client.request<CreateDocValidationsMutation>(CreateDocValidationsDocument, variables, requestHeaders));
    },
    createDocument(variables: CreateDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDocumentMutation> {
      return withWrapper(() => client.request<CreateDocumentMutation>(CreateDocumentDocument, variables, requestHeaders));
    },
    createDocumentRule(variables: CreateDocumentRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDocumentRuleMutation> {
      return withWrapper(() => client.request<CreateDocumentRuleMutation>(CreateDocumentRuleDocument, variables, requestHeaders));
    },
    createDocumentType(variables: CreateDocumentTypeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDocumentTypeMutation> {
      return withWrapper(() => client.request<CreateDocumentTypeMutation>(CreateDocumentTypeDocument, variables, requestHeaders));
    },
    createDossier(variables: CreateDossierMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDossierMutation> {
      return withWrapper(() => client.request<CreateDossierMutation>(CreateDossierDocument, variables, requestHeaders));
    },
    createDossierRule(variables: CreateDossierRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDossierRuleMutation> {
      return withWrapper(() => client.request<CreateDossierRuleMutation>(CreateDossierRuleDocument, variables, requestHeaders));
    },
    createDossierType(variables: CreateDossierTypeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDossierTypeMutation> {
      return withWrapper(() => client.request<CreateDossierTypeMutation>(CreateDossierTypeDocument, variables, requestHeaders));
    },
    createRole(variables: CreateRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateRoleMutation> {
      return withWrapper(() => client.request<CreateRoleMutation>(CreateRoleDocument, variables, requestHeaders));
    },
    createRuleValidation(variables: CreateRuleValidationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateRuleValidationMutation> {
      return withWrapper(() => client.request<CreateRuleValidationMutation>(CreateRuleValidationDocument, variables, requestHeaders));
    },
    createUser(variables?: CreateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateUserMutation> {
      return withWrapper(() => client.request<CreateUserMutation>(CreateUserDocument, variables, requestHeaders));
    },
    deleteDataTable(variables: DeleteDataTableMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDataTableMutation> {
      return withWrapper(() => client.request<DeleteDataTableMutation>(DeleteDataTableDocument, variables, requestHeaders));
    },
    deleteDocValidations(variables: DeleteDocValidationsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDocValidationsMutation> {
      return withWrapper(() => client.request<DeleteDocValidationsMutation>(DeleteDocValidationsDocument, variables, requestHeaders));
    },
    deleteDocument(variables: DeleteDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDocumentMutation> {
      return withWrapper(() => client.request<DeleteDocumentMutation>(DeleteDocumentDocument, variables, requestHeaders));
    },
    deleteDocumentRule(variables: DeleteDocumentRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDocumentRuleMutation> {
      return withWrapper(() => client.request<DeleteDocumentRuleMutation>(DeleteDocumentRuleDocument, variables, requestHeaders));
    },
    deleteDocumentType(variables: DeleteDocumentTypeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDocumentTypeMutation> {
      return withWrapper(() => client.request<DeleteDocumentTypeMutation>(DeleteDocumentTypeDocument, variables, requestHeaders));
    },
    deleteDossier(variables: DeleteDossierMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDossierMutation> {
      return withWrapper(() => client.request<DeleteDossierMutation>(DeleteDossierDocument, variables, requestHeaders));
    },
    deleteDossierRule(variables: DeleteDossierRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDossierRuleMutation> {
      return withWrapper(() => client.request<DeleteDossierRuleMutation>(DeleteDossierRuleDocument, variables, requestHeaders));
    },
    deleteDossierType(variables: DeleteDossierTypeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDossierTypeMutation> {
      return withWrapper(() => client.request<DeleteDossierTypeMutation>(DeleteDossierTypeDocument, variables, requestHeaders));
    },
    deleteFormData(variables: DeleteFormDataMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteFormDataMutation> {
      return withWrapper(() => client.request<DeleteFormDataMutation>(DeleteFormDataDocument, variables, requestHeaders));
    },
    deleteNotificationReceiver(variables: DeleteNotificationReceiverMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteNotificationReceiverMutation> {
      return withWrapper(() => client.request<DeleteNotificationReceiverMutation>(DeleteNotificationReceiverDocument, variables, requestHeaders));
    },
    deleteUser(variables: DeleteUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteUserMutation> {
      return withWrapper(() => client.request<DeleteUserMutation>(DeleteUserDocument, variables, requestHeaders));
    },
    downloadDocumentRule(variables: DownloadDocumentRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadDocumentRuleMutation> {
      return withWrapper(() => client.request<DownloadDocumentRuleMutation>(DownloadDocumentRuleDocument, variables, requestHeaders));
    },
    downloadDossierRule(variables: DownloadDossierRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadDossierRuleMutation> {
      return withWrapper(() => client.request<DownloadDossierRuleMutation>(DownloadDossierRuleDocument, variables, requestHeaders));
    },
    impersonates(variables: ImpersonatesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImpersonatesMutation> {
      return withWrapper(() => client.request<ImpersonatesMutation>(ImpersonatesDocument, variables, requestHeaders));
    },
    importDossierRules(variables: ImportDossierRulesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImportDossierRulesMutation> {
      return withWrapper(() => client.request<ImportDossierRulesMutation>(ImportDossierRulesDocument, variables, requestHeaders));
    },
    insertLogValidazione(variables: InsertLogValidazioneMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InsertLogValidazioneMutation> {
      return withWrapper(() => client.request<InsertLogValidazioneMutation>(InsertLogValidazioneDocument, variables, requestHeaders));
    },
    pingMutation(variables?: PingMutationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingMutationMutation> {
      return withWrapper(() => client.request<PingMutationMutation>(PingMutationDocument, variables, requestHeaders));
    },
    recoverPassword(variables: RecoverPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RecoverPasswordMutation> {
      return withWrapper(() => client.request<RecoverPasswordMutation>(RecoverPasswordDocument, variables, requestHeaders));
    },
    replaceDocumentMetas(variables: ReplaceDocumentMetasMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReplaceDocumentMetasMutation> {
      return withWrapper(() => client.request<ReplaceDocumentMetasMutation>(ReplaceDocumentMetasDocument, variables, requestHeaders));
    },
    replaceDossierMetas(variables: ReplaceDossierMetasMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReplaceDossierMetasMutation> {
      return withWrapper(() => client.request<ReplaceDossierMetasMutation>(ReplaceDossierMetasDocument, variables, requestHeaders));
    },
    resetPassword(variables: ResetPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetPasswordMutation> {
      return withWrapper(() => client.request<ResetPasswordMutation>(ResetPasswordDocument, variables, requestHeaders));
    },
    runAgainDossierRulesThatHasErrors(variables: RunAgainDossierRulesThatHasErrorsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunAgainDossierRulesThatHasErrorsMutation> {
      return withWrapper(() => client.request<RunAgainDossierRulesThatHasErrorsMutation>(RunAgainDossierRulesThatHasErrorsDocument, variables, requestHeaders));
    },
    runDocumentRule(variables: RunDocumentRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunDocumentRuleMutation> {
      return withWrapper(() => client.request<RunDocumentRuleMutation>(RunDocumentRuleDocument, variables, requestHeaders));
    },
    runDossierRule(variables: RunDossierRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunDossierRuleMutation> {
      return withWrapper(() => client.request<RunDossierRuleMutation>(RunDossierRuleDocument, variables, requestHeaders));
    },
    runDossierRules(variables: RunDossierRulesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunDossierRulesMutation> {
      return withWrapper(() => client.request<RunDossierRulesMutation>(RunDossierRulesDocument, variables, requestHeaders));
    },
    runTemporaryDossierRule(variables: RunTemporaryDossierRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunTemporaryDossierRuleMutation> {
      return withWrapper(() => client.request<RunTemporaryDossierRuleMutation>(RunTemporaryDossierRuleDocument, variables, requestHeaders));
    },
    runTemporaryRule(variables: RunTemporaryRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunTemporaryRuleMutation> {
      return withWrapper(() => client.request<RunTemporaryRuleMutation>(RunTemporaryRuleDocument, variables, requestHeaders));
    },
    testRule(variables: TestRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TestRuleMutation> {
      return withWrapper(() => client.request<TestRuleMutation>(TestRuleDocument, variables, requestHeaders));
    },
    updateAvatar(variables: UpdateAvatarMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAvatarMutation> {
      return withWrapper(() => client.request<UpdateAvatarMutation>(UpdateAvatarDocument, variables, requestHeaders));
    },
    updateDataTable(variables: UpdateDataTableMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDataTableMutation> {
      return withWrapper(() => client.request<UpdateDataTableMutation>(UpdateDataTableDocument, variables, requestHeaders));
    },
    updateDocument(variables: UpdateDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDocumentMutation> {
      return withWrapper(() => client.request<UpdateDocumentMutation>(UpdateDocumentDocument, variables, requestHeaders));
    },
    updateDocumentRule(variables: UpdateDocumentRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDocumentRuleMutation> {
      return withWrapper(() => client.request<UpdateDocumentRuleMutation>(UpdateDocumentRuleDocument, variables, requestHeaders));
    },
    updateDocumentType(variables: UpdateDocumentTypeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDocumentTypeMutation> {
      return withWrapper(() => client.request<UpdateDocumentTypeMutation>(UpdateDocumentTypeDocument, variables, requestHeaders));
    },
    updateDossier(variables: UpdateDossierMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDossierMutation> {
      return withWrapper(() => client.request<UpdateDossierMutation>(UpdateDossierDocument, variables, requestHeaders));
    },
    updateDossierRule(variables: UpdateDossierRuleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDossierRuleMutation> {
      return withWrapper(() => client.request<UpdateDossierRuleMutation>(UpdateDossierRuleDocument, variables, requestHeaders));
    },
    updateDossierType(variables: UpdateDossierTypeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateDossierTypeMutation> {
      return withWrapper(() => client.request<UpdateDossierTypeMutation>(UpdateDossierTypeDocument, variables, requestHeaders));
    },
    updateHelperCode(variables: UpdateHelperCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateHelperCodeMutation> {
      return withWrapper(() => client.request<UpdateHelperCodeMutation>(UpdateHelperCodeDocument, variables, requestHeaders));
    },
    updateMustContain(variables: UpdateMustContainMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateMustContainMutation> {
      return withWrapper(() => client.request<UpdateMustContainMutation>(UpdateMustContainDocument, variables, requestHeaders));
    },
    updateNotContain(variables: UpdateNotContainMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateNotContainMutation> {
      return withWrapper(() => client.request<UpdateNotContainMutation>(UpdateNotContainDocument, variables, requestHeaders));
    },
    updateSelfPassword(variables: UpdateSelfPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateSelfPasswordMutation> {
      return withWrapper(() => client.request<UpdateSelfPasswordMutation>(UpdateSelfPasswordDocument, variables, requestHeaders));
    },
    updateUser(variables: UpdateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserMutation> {
      return withWrapper(() => client.request<UpdateUserMutation>(UpdateUserDocument, variables, requestHeaders));
    },
    updateUserPassword(variables: UpdateUserPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserPasswordMutation> {
      return withWrapper(() => client.request<UpdateUserPasswordMutation>(UpdateUserPasswordDocument, variables, requestHeaders));
    },
    uploadRuleValidation(variables: UploadRuleValidationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UploadRuleValidationMutation> {
      return withWrapper(() => client.request<UploadRuleValidationMutation>(UploadRuleValidationDocument, variables, requestHeaders));
    },
    basicLogin(variables: BasicLoginQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BasicLoginQuery> {
      return withWrapper(() => client.request<BasicLoginQuery>(BasicLoginDocument, variables, requestHeaders));
    },
    dataTable(variables: DataTableQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DataTableQuery> {
      return withWrapper(() => client.request<DataTableQuery>(DataTableDocument, variables, requestHeaders));
    },
    dataTables(variables?: DataTablesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DataTablesQuery> {
      return withWrapper(() => client.request<DataTablesQuery>(DataTablesDocument, variables, requestHeaders));
    },
    docValidation(variables: DocValidationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocValidationQuery> {
      return withWrapper(() => client.request<DocValidationQuery>(DocValidationDocument, variables, requestHeaders));
    },
    docValidations(variables?: DocValidationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocValidationsQuery> {
      return withWrapper(() => client.request<DocValidationsQuery>(DocValidationsDocument, variables, requestHeaders));
    },
    document(variables: DocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocumentQuery> {
      return withWrapper(() => client.request<DocumentQuery>(DocumentDocument, variables, requestHeaders));
    },
    documentRule(variables: DocumentRuleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocumentRuleQuery> {
      return withWrapper(() => client.request<DocumentRuleQuery>(DocumentRuleDocument, variables, requestHeaders));
    },
    documentRules(variables?: DocumentRulesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocumentRulesQuery> {
      return withWrapper(() => client.request<DocumentRulesQuery>(DocumentRulesDocument, variables, requestHeaders));
    },
    documentType(variables: DocumentTypeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocumentTypeQuery> {
      return withWrapper(() => client.request<DocumentTypeQuery>(DocumentTypeDocument, variables, requestHeaders));
    },
    documentTypes(variables?: DocumentTypesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocumentTypesQuery> {
      return withWrapper(() => client.request<DocumentTypesQuery>(DocumentTypesDocument, variables, requestHeaders));
    },
    documents(variables?: DocumentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocumentsQuery> {
      return withWrapper(() => client.request<DocumentsQuery>(DocumentsDocument, variables, requestHeaders));
    },
    documentsByDossier(variables: DocumentsByDossierQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocumentsByDossierQuery> {
      return withWrapper(() => client.request<DocumentsByDossierQuery>(DocumentsByDossierDocument, variables, requestHeaders));
    },
    documentsInfo(variables?: DocumentsInfoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocumentsInfoQuery> {
      return withWrapper(() => client.request<DocumentsInfoQuery>(DocumentsInfoDocument, variables, requestHeaders));
    },
    documentsNameForDossier(variables: DocumentsNameForDossierQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocumentsNameForDossierQuery> {
      return withWrapper(() => client.request<DocumentsNameForDossierQuery>(DocumentsNameForDossierDocument, variables, requestHeaders));
    },
    dossier(variables: DossierQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DossierQuery> {
      return withWrapper(() => client.request<DossierQuery>(DossierDocument, variables, requestHeaders));
    },
    dossierCallbacks(variables: DossierCallbacksQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DossierCallbacksQuery> {
      return withWrapper(() => client.request<DossierCallbacksQuery>(DossierCallbacksDocument, variables, requestHeaders));
    },
    dossierRule(variables: DossierRuleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DossierRuleQuery> {
      return withWrapper(() => client.request<DossierRuleQuery>(DossierRuleDocument, variables, requestHeaders));
    },
    dossierRules(variables?: DossierRulesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DossierRulesQuery> {
      return withWrapper(() => client.request<DossierRulesQuery>(DossierRulesDocument, variables, requestHeaders));
    },
    dossierType(variables: DossierTypeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DossierTypeQuery> {
      return withWrapper(() => client.request<DossierTypeQuery>(DossierTypeDocument, variables, requestHeaders));
    },
    dossierTypes(variables?: DossierTypesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DossierTypesQuery> {
      return withWrapper(() => client.request<DossierTypesQuery>(DossierTypesDocument, variables, requestHeaders));
    },
    dossiers(variables?: DossiersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DossiersQuery> {
      return withWrapper(() => client.request<DossiersQuery>(DossiersDocument, variables, requestHeaders));
    },
    downloadAllValidationFiles(variables: DownloadAllValidationFilesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadAllValidationFilesQuery> {
      return withWrapper(() => client.request<DownloadAllValidationFilesQuery>(DownloadAllValidationFilesDocument, variables, requestHeaders));
    },
    downloadDiff(variables: DownloadDiffQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadDiffQuery> {
      return withWrapper(() => client.request<DownloadDiffQuery>(DownloadDiffDocument, variables, requestHeaders));
    },
    downloadDocument(variables: DownloadDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadDocumentQuery> {
      return withWrapper(() => client.request<DownloadDocumentQuery>(DownloadDocumentDocument, variables, requestHeaders));
    },
    downloadDocuments(variables: DownloadDocumentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadDocumentsQuery> {
      return withWrapper(() => client.request<DownloadDocumentsQuery>(DownloadDocumentsDocument, variables, requestHeaders));
    },
    downloadDossier(variables: DownloadDossierQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadDossierQuery> {
      return withWrapper(() => client.request<DownloadDossierQuery>(DownloadDossierDocument, variables, requestHeaders));
    },
    downloadOCR(variables: DownloadOcrQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadOcrQuery> {
      return withWrapper(() => client.request<DownloadOcrQuery>(DownloadOcrDocument, variables, requestHeaders));
    },
    downloadPDF(variables: DownloadPdfQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadPdfQuery> {
      return withWrapper(() => client.request<DownloadPdfQuery>(DownloadPdfDocument, variables, requestHeaders));
    },
    fixMetas(variables: FixMetasQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FixMetasQuery> {
      return withWrapper(() => client.request<FixMetasQuery>(FixMetasDocument, variables, requestHeaders));
    },
    getAllDocumentCodeExecutions(variables?: GetAllDocumentCodeExecutionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAllDocumentCodeExecutionsQuery> {
      return withWrapper(() => client.request<GetAllDocumentCodeExecutionsQuery>(GetAllDocumentCodeExecutionsDocument, variables, requestHeaders));
    },
    getAllDossierCodeExecutions(variables?: GetAllDossierCodeExecutionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAllDossierCodeExecutionsQuery> {
      return withWrapper(() => client.request<GetAllDossierCodeExecutionsQuery>(GetAllDossierCodeExecutionsDocument, variables, requestHeaders));
    },
    getAllDossierRuleExecutions(variables?: GetAllDossierRuleExecutionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAllDossierRuleExecutionsQuery> {
      return withWrapper(() => client.request<GetAllDossierRuleExecutionsQuery>(GetAllDossierRuleExecutionsDocument, variables, requestHeaders));
    },
    getDocumentCodeExecutions(variables: GetDocumentCodeExecutionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDocumentCodeExecutionsQuery> {
      return withWrapper(() => client.request<GetDocumentCodeExecutionsQuery>(GetDocumentCodeExecutionsDocument, variables, requestHeaders));
    },
    getDocumentLogs(variables?: GetDocumentLogsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDocumentLogsQuery> {
      return withWrapper(() => client.request<GetDocumentLogsQuery>(GetDocumentLogsDocument, variables, requestHeaders));
    },
    getDocumentRuleExecutions(variables: GetDocumentRuleExecutionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDocumentRuleExecutionsQuery> {
      return withWrapper(() => client.request<GetDocumentRuleExecutionsQuery>(GetDocumentRuleExecutionsDocument, variables, requestHeaders));
    },
    getDossierCodeExecutions(variables: GetDossierCodeExecutionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDossierCodeExecutionsQuery> {
      return withWrapper(() => client.request<GetDossierCodeExecutionsQuery>(GetDossierCodeExecutionsDocument, variables, requestHeaders));
    },
    getDossierLogs(variables?: GetDossierLogsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDossierLogsQuery> {
      return withWrapper(() => client.request<GetDossierLogsQuery>(GetDossierLogsDocument, variables, requestHeaders));
    },
    getDossierResultAggregate(variables: GetDossierResultAggregateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDossierResultAggregateQuery> {
      return withWrapper(() => client.request<GetDossierResultAggregateQuery>(GetDossierResultAggregateDocument, variables, requestHeaders));
    },
    getDossierRuleExecutions(variables: GetDossierRuleExecutionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDossierRuleExecutionsQuery> {
      return withWrapper(() => client.request<GetDossierRuleExecutionsQuery>(GetDossierRuleExecutionsDocument, variables, requestHeaders));
    },
    getDossierRuleExecutionsByDossier(variables: GetDossierRuleExecutionsByDossierQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDossierRuleExecutionsByDossierQuery> {
      return withWrapper(() => client.request<GetDossierRuleExecutionsByDossierQuery>(GetDossierRuleExecutionsByDossierDocument, variables, requestHeaders));
    },
    getDossierRuleExecutionsByRule(variables: GetDossierRuleExecutionsByRuleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDossierRuleExecutionsByRuleQuery> {
      return withWrapper(() => client.request<GetDossierRuleExecutionsByRuleQuery>(GetDossierRuleExecutionsByRuleDocument, variables, requestHeaders));
    },
    getDossierRuleLogs(variables: GetDossierRuleLogsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDossierRuleLogsQuery> {
      return withWrapper(() => client.request<GetDossierRuleLogsQuery>(GetDossierRuleLogsDocument, variables, requestHeaders));
    },
    getDossierRulesExecutionCount(variables: GetDossierRulesExecutionCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDossierRulesExecutionCountQuery> {
      return withWrapper(() => client.request<GetDossierRulesExecutionCountQuery>(GetDossierRulesExecutionCountDocument, variables, requestHeaders));
    },
    getFailedDossierRuleExecs(variables: GetFailedDossierRuleExecsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetFailedDossierRuleExecsQuery> {
      return withWrapper(() => client.request<GetFailedDossierRuleExecsQuery>(GetFailedDossierRuleExecsDocument, variables, requestHeaders));
    },
    getGroups(variables?: GetGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetGroupsQuery> {
      return withWrapper(() => client.request<GetGroupsQuery>(GetGroupsDocument, variables, requestHeaders));
    },
    getHelperCode(variables: GetHelperCodeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetHelperCodeQuery> {
      return withWrapper(() => client.request<GetHelperCodeQuery>(GetHelperCodeDocument, variables, requestHeaders));
    },
    getLogsByDocument(variables: GetLogsByDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLogsByDocumentQuery> {
      return withWrapper(() => client.request<GetLogsByDocumentQuery>(GetLogsByDocumentDocument, variables, requestHeaders));
    },
    getLogsByDossier(variables: GetLogsByDossierQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetLogsByDossierQuery> {
      return withWrapper(() => client.request<GetLogsByDossierQuery>(GetLogsByDossierDocument, variables, requestHeaders));
    },
    getNewAccessToken(variables: GetNewAccessTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetNewAccessTokenQuery> {
      return withWrapper(() => client.request<GetNewAccessTokenQuery>(GetNewAccessTokenDocument, variables, requestHeaders));
    },
    getPaginatedDocExecutions(variables: GetPaginatedDocExecutionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPaginatedDocExecutionsQuery> {
      return withWrapper(() => client.request<GetPaginatedDocExecutionsQuery>(GetPaginatedDocExecutionsDocument, variables, requestHeaders));
    },
    getPaginatedDossier(variables: GetPaginatedDossierQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPaginatedDossierQuery> {
      return withWrapper(() => client.request<GetPaginatedDossierQuery>(GetPaginatedDossierDocument, variables, requestHeaders));
    },
    getPaginatedDossierCodeExecutions(variables: GetPaginatedDossierCodeExecutionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPaginatedDossierCodeExecutionsQuery> {
      return withWrapper(() => client.request<GetPaginatedDossierCodeExecutionsQuery>(GetPaginatedDossierCodeExecutionsDocument, variables, requestHeaders));
    },
    getPaginatedDossierRuleExec(variables: GetPaginatedDossierRuleExecQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPaginatedDossierRuleExecQuery> {
      return withWrapper(() => client.request<GetPaginatedDossierRuleExecQuery>(GetPaginatedDossierRuleExecDocument, variables, requestHeaders));
    },
    getPolicies(variables?: GetPoliciesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPoliciesQuery> {
      return withWrapper(() => client.request<GetPoliciesQuery>(GetPoliciesDocument, variables, requestHeaders));
    },
    getRulesByDocument(variables: GetRulesByDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetRulesByDocumentQuery> {
      return withWrapper(() => client.request<GetRulesByDocumentQuery>(GetRulesByDocumentDocument, variables, requestHeaders));
    },
    latestRuleValitation(variables?: LatestRuleValitationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LatestRuleValitationQuery> {
      return withWrapper(() => client.request<LatestRuleValitationQuery>(LatestRuleValitationDocument, variables, requestHeaders));
    },
    logValidazione(variables: LogValidazioneQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LogValidazioneQuery> {
      return withWrapper(() => client.request<LogValidazioneQuery>(LogValidazioneDocument, variables, requestHeaders));
    },
    logValidazioni(variables?: LogValidazioniQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LogValidazioniQuery> {
      return withWrapper(() => client.request<LogValidazioniQuery>(LogValidazioniDocument, variables, requestHeaders));
    },
    loggedUser(variables?: LoggedUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoggedUserQuery> {
      return withWrapper(() => client.request<LoggedUserQuery>(LoggedUserDocument, variables, requestHeaders));
    },
    metasCheck(variables: MetasCheckQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MetasCheckQuery> {
      return withWrapper(() => client.request<MetasCheckQuery>(MetasCheckDocument, variables, requestHeaders));
    },
    notValidDossierRules(variables?: NotValidDossierRulesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NotValidDossierRulesQuery> {
      return withWrapper(() => client.request<NotValidDossierRulesQuery>(NotValidDossierRulesDocument, variables, requestHeaders));
    },
    notificationReceivers(variables?: NotificationReceiversQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NotificationReceiversQuery> {
      return withWrapper(() => client.request<NotificationReceiversQuery>(NotificationReceiversDocument, variables, requestHeaders));
    },
    ping(variables?: PingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingQuery> {
      return withWrapper(() => client.request<PingQuery>(PingDocument, variables, requestHeaders));
    },
    requestSignedURLForValidationDocument(variables: RequestSignedUrlForValidationDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RequestSignedUrlForValidationDocumentQuery> {
      return withWrapper(() => client.request<RequestSignedUrlForValidationDocumentQuery>(RequestSignedUrlForValidationDocumentDocument, variables, requestHeaders));
    },
    roles(variables?: RolesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RolesQuery> {
      return withWrapper(() => client.request<RolesQuery>(RolesDocument, variables, requestHeaders));
    },
    ruleValidation(variables: RuleValidationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RuleValidationQuery> {
      return withWrapper(() => client.request<RuleValidationQuery>(RuleValidationDocument, variables, requestHeaders));
    },
    ruleValidations(variables?: RuleValidationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RuleValidationsQuery> {
      return withWrapper(() => client.request<RuleValidationsQuery>(RuleValidationsDocument, variables, requestHeaders));
    },
    runCode(variables: RunCodeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunCodeQuery> {
      return withWrapper(() => client.request<RunCodeQuery>(RunCodeDocument, variables, requestHeaders));
    },
    runDocumentRules(variables: RunDocumentRulesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunDocumentRulesQuery> {
      return withWrapper(() => client.request<RunDocumentRulesQuery>(RunDocumentRulesDocument, variables, requestHeaders));
    },
    runValidation(variables: RunValidationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunValidationQuery> {
      return withWrapper(() => client.request<RunValidationQuery>(RunValidationDocument, variables, requestHeaders));
    },
    setDocumentRuleActive(variables: SetDocumentRuleActiveQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetDocumentRuleActiveQuery> {
      return withWrapper(() => client.request<SetDocumentRuleActiveQuery>(SetDocumentRuleActiveDocument, variables, requestHeaders));
    },
    setDossierRuleActive(variables: SetDossierRuleActiveQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetDossierRuleActiveQuery> {
      return withWrapper(() => client.request<SetDossierRuleActiveQuery>(SetDossierRuleActiveDocument, variables, requestHeaders));
    },
    user(variables: UserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserQuery> {
      return withWrapper(() => client.request<UserQuery>(UserDocument, variables, requestHeaders));
    },
    users(variables?: UsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UsersQuery> {
      return withWrapper(() => client.request<UsersQuery>(UsersDocument, variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;