import type {FC} from "react";
import React, {useEffect, useState, ChangeEvent} from "react";
import useSettings from '../../hooks/useSettings';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {
    Box, Breadcrumbs,
    Container,
    Divider,
    Grid,
    Link,
    Card,
    CardContent,
    Typography,
    Tabs, Tab, Button, IconButton,
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ChevronRightIcon from "../../icons/ChevronRight";
import {Document, DocumentRule} from "src/types/generated";
import GraphqlClient from "../../client/graphql.client";
import {useSnackbar} from "notistack";
import DettagliGenerale from "./components/DettagliGenerale"
import DettagliFormData from "./components/DettagliFormData"
import DettagliRawText from "./components/DettagliRawText"
import {HandleDownloadDocument} from "./pagina.documenti"
import SyntaxHighlighter from "react-syntax-highlighter";
import {atomOneDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import DettaglioCodice from "./components/DettagliCodice";
import DettaglioTableData from "./components/DettagliTableData";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";


function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

const PaginaRegoleDocumenti: FC<{ id?: string }> = (props: any) => {
    const classes = useStyles();

    const {settings} = useSettings();
    const [currentTab, setCurrentTab] = useState<string>();
    const [currentSubTab, setCurrentSubTab] = useState<string>("codice");

    const [rules, setRules] = useState([] as DocumentRule[]);
    const [rule, setRule] = useState({} as DocumentRule);
    const [documento, setDocumento] = useState({} as Document);
    const [loadingRunRule, setLoadingRunRule] = useState(false);

    const {...other} = props;
    const params = useParams();
    const {enqueueSnackbar} = useSnackbar();

    const [loadingData, setLoadingData] = useState(false);
    const [loadingRules, setLoadingRules] = useState(false);

    const id = props.id;

    const handleTabsChange = (event, newValue): void => {
        setCurrentTab(newValue);
        setRule(rules.filter(rule => rule.description === newValue)[0]);
    };

    const handleSubTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentSubTab(value);
    };

    const eseguiRegola = async (documentID, ruleID) => {
        try {
            setLoadingRunRule(true);
            enqueueSnackbar("Regola avviata", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'info'
            });
            const res = await GraphqlClient.runDocumentRule({id: documentID, ruleId: ruleID})
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Impossibile eseguire la regola", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            // Notification
        } finally {
            setLoadingRunRule(false);
            enqueueSnackbar("Regola eseguita", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
        }

    }

    useEffect(() => {
        const caricaDocumento = async () => {

            try {
                setLoadingData(true)
                const res = await GraphqlClient.getRulesByDocument({id: id});
                setRules(res.getRulesByDocument);
                if (res.getRulesByDocument?.length > 0) {
                    setRule(res.getRulesByDocument[0]);
                    setCurrentTab(res.getRulesByDocument[0].description);
                }
            } catch (e) {
                console.error('Impossibile ottenere regole: ', e);
                enqueueSnackbar("Impossibile ottenere le regole per il documento", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            } finally {
                setLoadingData(false);
            }

            try {
                setLoadingRules(true)
                const res = await GraphqlClient.document({id: id});
                setDocumento(res.document);
            } catch (e) {
                console.error('Impossibile ottenere documento: ', e);
                enqueueSnackbar("Impossibile ottenere il documento", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            } finally {
                setLoadingRules(false);
            }
        }

        caricaDocumento();
    }, [])

    console.log('RULEEEEEEEE', rule);

    return (

        <Container maxWidth={settings.compact ? 'xl' : false}>

            {(!rules || rules.length === 0) && <Box m={2}> <Typography

                color="textSecondary"
                variant="h5"
            >
                Non ci sono regole associate al documento
            </Typography></Box>}

            {rules?.length > 0 &&
            <div className={classes.root} style={{borderRadius: 6}}>
                <Box sx={{mt: 3}}>
                    <Tabs
                        indicatorColor="primary"
                        orientation="vertical"
                        className={classes.tabs}

                        onChange={handleTabsChange}
                        textColor="primary"
                        value={currentTab}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {rules?.map((rule, index) => (
                            <Tab
                                key={rule.description}
                                label={rule.description}
                                value={rule.description}
                                tabIndex={index}
                                {...a11yProps(index)}
                            />
                        ))}
                    </Tabs>


                </Box>

                <Box p={3} style={{display: 'flex', flexDirection: 'column', 'width': '100%'}}>
                    <Box m={2}>
                        <Button
                            variant="outlined"
                            startIcon={<PlayCircleOutlineIcon/>}
                            onClick={() => eseguiRegola(documento.id, rule.id)}
                        >
                            Esegui regola
                        </Button>

                    </Box>
                    <Divider/>

                    <Tabs
                        indicatorColor="secondary"
                        onChange={handleSubTabsChange}
                        scrollButtons="auto"
                        textColor="secondary"
                        value={currentSubTab}
                        variant="scrollable"
                    >
                        {["codice", "form data", "raw text", "data tables"].map((title) => (
                            <Tab
                                key={title}
                                label={title}
                                value={title}
                            />
                        ))}
                    </Tabs>


                    <div
                        role="tabpanel"
                    >
                        <Box p={3}>
                            {currentSubTab === "codice" && <DettaglioCodice code={rule.code || ""}/>}
                            {currentSubTab === 'form data' &&
                            <DettagliFormData {...documento} {...props} />}
                            {currentSubTab === 'raw text' && <DettagliRawText {...documento} {...props} />}
                            {currentSubTab === 'data tables' &&
                            <DettaglioTableData
                                tables={rule.dataTables || []} {...props} />}                                    </Box>
                    </div>


                </Box></div>}
        </Container>
    )
}

export default PaginaRegoleDocumenti;
