import { useEffect, useState } from 'react'
import type { FC } from "react";
import { Box, Card, CardContent } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/light';
import {atomOneDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';

const VariabiliInput: FC<{input: string}> = (input) => {
  const [parsedInput, setParsedInput] = useState("")
  useEffect(() => {
    const temp = JSON.parse(input.input)
    setParsedInput(JSON.stringify(temp, null, 2))
  }, [input])
  return (
    <Box m={1}>
      <Card>
        <CardContent>
          <SyntaxHighlighter 
            customStyle={{backgroundColor: 'transparent'}} 
            wrapLongLines={true}
            wrapLines={true} 
            language="json" 
            style={atomOneDark}
          >
            {parsedInput}
          </SyntaxHighlighter>
        </CardContent>
      </Card>
    </Box>
  )
}

export default VariabiliInput
