import { FC } from 'react'
import { Document } from 'src/types/generated'
import {
    Box,
    Grid,
    Card,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Divider,
    CardContent,
    TableBody,
    TableFooter
} from '@material-ui/core'
import Scrollbar from 'src/material/Scrollbar'
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const DettagliFormData: FC<Document> = (documento: Document, props) => {
    const data = documento.formData

    if (!data) return null;

    let code = JSON.stringify(data, null, 2)
    return (
        <Grid
            container
            spacing={3}
            {...props}
        >
            <Grid item style={{ flex: '1' }}>
                <Box p={2}>
                    <SyntaxHighlighter
                        language="javascript"
                        style={atomOneDark}
                    >
                        {code}
                    </SyntaxHighlighter>
                </Box>
            </Grid>
        </Grid>
    )
}

export default DettagliFormData
