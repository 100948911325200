import store from "../../store";

import {actions} from './redux.login';
import GraphqlClient, {SetClientJwt} from "../../client/graphql.client";
import {SetPermissions} from "./authorizer";

export const refreshToken = async (accessToken) => {
    store.dispatch(actions.refreshingToken());
    try {
        const res = await GraphqlClient.getNewAccessToken({refreshToken: accessToken})
        await SetPermissions(res.getNewAccessToken.permissions);
        SetClientJwt(res.getNewAccessToken.accessToken)

        store.dispatch(actions.refreshedToken(res.getNewAccessToken));
    } catch (e) {
        console.error(e);
        store.dispatch(actions.cannotRefreshToken());
    }
}

export const resetPassword = async (response: any) => {
    await SetPermissions(response.permissions);
    SetClientJwt(response.accessToken)

    store.dispatch(actions.refreshedToken(response));
}

export const login = async (username, password) => {
    try {
        const res = await GraphqlClient.basicLogin({username, password});
        await SetPermissions(res.basicLogin.permissions);
        SetClientJwt(res.basicLogin.accessToken)

        store.dispatch(actions.login(res.basicLogin));
        return {logged: true}
    } catch (error) {
        return {logged: false, message: JSON.parse(JSON.stringify(error)).response.error}
    }
};

export const impersonificate = async (userId: string) => {
    try {
        const res = await GraphqlClient.impersonates({email: userId});
        await SetPermissions(res.impersonates.permissions);
        SetClientJwt(res.impersonates.accessToken)

        store.dispatch(actions.impersonates(res.impersonates));
        return {data: res.impersonates}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
}

export const removeImpersonation = async () => {
    await SetPermissions(store.getState().auth.userPreImpersonation.permissions);
    SetClientJwt(store.getState().auth.userPreImpersonation.accessToken)

    store.dispatch(actions.removeImpersonation());
}


export const logout = () => {
    SetClientJwt("");
    store.dispatch(actions.logout())
}

export const setAvatar = (avatar) => {
    store.dispatch(actions.setAvatar(avatar))
}
