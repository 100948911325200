import React, {useEffect} from 'react';
import type {FC} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {
    Box,
    Card,
    CardContent,
    Container,
    Divider,
    Link,
    Typography
} from '@material-ui/core';
import AuthBanner from '../../components/authentication/AuthBanner';
import
    LoginPage from '../login/login.page';
import Logo from '../../material/Logo';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import LanguagePopover from "../../material/dashboard/LanguagePopover";
import {useTranslation} from "react-i18next";

const platformIcons = {
    Amplify: '/static/icons/amplify.svg',
    Auth0: '/static/icons/auth0.svg',
    Firebase: '/static/icons/firebase.svg',
    JWT: '/static/icons/jwt.svg'
};

const Login: FC = () => {
    const {platform} = useAuth() as any;
    const {t} = useTranslation();

    useEffect(() => {
        gtm.push({event: 'page_view'});
    }, []);

    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{py: '80px'}}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 8
                        }}
                    >
                        <RouterLink to="/">
                            <Logo
                                sx={{
                                    height: 40,
                                    width: 40
                                }}
                            />
                        </RouterLink>
                    </Box>
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 4
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 3
                                }}
                            >
                                <div>
                                    <Typography
                                        color="textPrimary"
                                        gutterBottom
                                        variant="h4"
                                    >
                                        {t("loginHeader")}
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {t("loginDescription")}
                                    </Typography>
                                </div>
                                <Box
                                    sx={{
                                        height: 32,
                                        '& > img': {
                                            maxHeight: '100%',
                                            width: 'auto'
                                        }
                                    }}
                                >

                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3
                                }}
                            >
                                <LoginPage/>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Login;
