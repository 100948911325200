import React from 'react'
import { FC, useEffect, useState } from 'react';
import Button_MUI from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import IconButton_MUI from '@material-ui/core/IconButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonGroup_MUI from '@material-ui/core/ButtonGroup';


export interface ButtonProps{
  label?:string;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary'|'secondary';
  variant?: "text" | "outlined" | "contained";
  onClick?: () => void;
  component?: any
  type?: string;
  disabled?: boolean;
  to?: any;
  startIcon?: any;
  sx?: any;
  className?: any;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  color,
  size,
  variant,
  onClick,
  component,
  to,
  ...props
}) => {
  return (
    <Button_MUI variant={variant} color={color} size={size} onClick={onClick} component={component} to={to} {...props}>
      {label}
    </Button_MUI>

  );
};

export interface TextButtonProps{
  label?:string;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary'|'secondary';
  variant?: "text" | "outlined" | "contained";
  onClick?: () => void;
  component?: any
  type?: string;
  disabled?: boolean;
  to?: any;
  startIcon?: any;
  sx?: any;
  href?: any;
  className?: any;
}

export const TextButton: FC<TextButtonProps> = ({
  color,
  label,
  disabled,
  href,
  size,
  ...props
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();

  if (disabled) {
    return (
      <div className={classes.root}>
        <Button_MUI disabled href={href} color={color} size={size} {...props}>{label}</Button_MUI>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Button_MUI href={href} color={color} size={size} {...props}>{label}</Button_MUI>
      </div>
    );
  }
};

