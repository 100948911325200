import {
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector
} from 'react-redux';
import type {TypedUseSelectorHook} from 'react-redux';
import type {ThunkAction} from 'redux-thunk';
import {configureStore} from '@reduxjs/toolkit';
import type {Action} from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import persistStore from "redux-persist/es/persistStore";
import {rootEpic} from "./epics";
import {createEpicMiddleware} from "redux-observable";
import persistedReducer from "./rootReducer";


const epicMiddleware = createEpicMiddleware();

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
    devTools: true
});

epicMiddleware.run(rootEpic);
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
