import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box, Breadcrumbs,
  /* Button, */
  Card,
  CardContent,
  CardHeader,
  Checkbox, Container,
  FormControlLabel,
  FormHelperText,
  Grid, Link,
  /* TextField, */
  Typography
} from '@material-ui/core';
import useSettings from "../../hooks/useSettings";
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import { newType } from "./commands.tipodoc";
import { DocumentType } from "../../types/generated";


/*****************************************/
import Button_MUI from '@material-ui/core/Button';
import { Button } from '../../components/Button/Button'
import { TextField } from '../../components/TextField/TextField'
/*****************************************/

const NuovoDocType: FC = (props) => {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Helmet>
        <title>Documenti: Nuovo tipo </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Nuovo tipo
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/tipologie/documenti"
                  variant="subtitle2"
                >
                  Tipologie Documenti
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Nuovo tipo
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<ArrowLeftIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                  to="/tipologie/documenti"
                  variant="outlined"
                  label='Indietro'
                />
                  {/* Indietro
                </Button_MUI> */}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Formik
              initialValues={{
                nome: '',
              }}
              validationSchema={
                Yup
                  .object()
                  .shape({
                    nome: Yup.string().max(30).required('Campo obbligatorio'),
                  })
              }
              onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
              }): Promise<void> => {
                setSubmitting(true);
                let { nome } = values;
                const res = await newType({ 'name': nome });
                if (res.error && res.error == true) {
                  console.error(res.message);
                  setStatus({ success: false });
                  setSubmitting(false);
                  enqueueSnackbar(res.message, {
                    anchorOrigin: {
                      horizontal: 'right',
                      vertical: 'top'
                    },
                    variant: 'error'
                  });
                  return;
                }

                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar('Tipologia creata', {
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                  },
                  variant: 'success'
                });
                navigate('/tipologie/documenti');
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values
              }): JSX.Element => (
                <form
                  onSubmit={handleSubmit}
                  {...props}
                >
                  <Box sx={{ p: 3 }}>

                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <Card>
                          <CardContent>
                            <CardHeader title="Inserisci il nome" />
                            <Box sx={{ mt: 2 }}>
                              <TextField
                                required
                                error={Boolean(touched.nome && errors.nome)}
                                fullWidth
                                helperText={touched.nome && errors.nome}
                                label="Nome"
                                input_id="nome"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.nome}
                                variant="outlined"
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        mt: 3
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        label='Crea tipologia'
                      />
                        {/* Crea tipologia
                      </Button> */}
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NuovoDocType;
