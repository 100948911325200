import {FC, useState} from 'react'
import PropTypes from 'prop-types';

import {DataTable, Document} from 'src/types/generated'
import {
    Box,
    Grid,
    Card,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell, Tab,
    Tabs,
    Divider,
    CardContent,
    TableBody,
    TableFooter,
} from '@material-ui/core'
import Scrollbar from 'src/material/Scrollbar'
import SyntaxHighlighter from "react-syntax-highlighter";
import {atomOneDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {makeStyles} from '@material-ui/core/styles';

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

const DettaglioTableData: FC<{ tables?: DataTable[] }> = ({tables}) => {

    // console.log('TABLES', tables);
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid item style={{flex: '1'}}>
                <Card>
                    <CardContent className={classes.root}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className={classes.tabs}
                        >
                            {tables?.map((table, index) => (
                                <Tab label={table.name} {...a11yProps(index)} />

                            ))}
                        </Tabs>

                        {tables?.map((table, index) => (
                            <TabPanel value={value} index={index}>
                                <SyntaxHighlighter language="javascript" style={atomOneDark}>
                                    {JSON.stringify(JSON.parse(table.content),null, 4)}
                                </SyntaxHighlighter>
                            </TabPanel>

                        ))}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default DettaglioTableData;
