import type { FC } from "react";
import React, { ChangeEvent, useEffect, useState } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box, Breadcrumbs, Button,
    Container, Card,
    Grid,
    Link,
    IconButton,
    Typography,
    Tooltip,
    CardContent, Chip,
    CardHeader,
    Divider,
    CircularProgress, Tabs, Tab,
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Scrollbar from '../../material/Scrollbar';
import ChevronRightIcon from "../../icons/ChevronRight";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightIcon from '../../icons/ArrowRight';

import { FilePond, registerPlugin } from 'react-filepond'
import "filepond/dist/filepond.min.css";
import { diff, uploadValidationFile } from "./commands.validazione";
import { useSnackbar } from "notistack";
import {
    Table,
    SelectColumnFilter,
    SelectColumnFilterMethod,
    DatePickerRangeFilter,
    DatePickerRangeFilterMethod
} from "src/components/Table/Table";
import AccountGeneralSettings from "../account/AccountGeneralSettings";
import AccountSecuritySettings from "../account/AccountSecuritySettings";
import PlusIcon from "../../icons/Plus";
import { RuleValidation } from "../../types/generated";
import GraphqlClient from "../../client/graphql.client";
import moment from "moment/moment";
import PageDocValidation from "./documenti.validazione";
import Label from "src/material/Label";

// filepond plugin
/*
registerPlugin(FilePondPluginFileValidateType);
*/

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const PageRuleValidation: FC = (props) => {
    const { settings } = useSettings();
    const [currentTab, setCurrentTab] = useState<string>('validazioni');
    const [validations, setValidations] = useState([] as any);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    const tabs = [
        { label: "Validazioni", value: 'validazioni' },
        { label: "Documenti validazione", value: 'documentiValidazione' }
    ];

    const readAll = async (interval) => {
        if (!interval) {
            setLoading(true);

        }
        try {
            const res = await GraphqlClient.ruleValidations({});
            setValidations(res.ruleValidations);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Impossibile caricare le validazioni", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            })
        } finally {
            if (!interval) {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            readAll(true);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        readAll(false);
    }, [])

    return (
        <>
            <Helmet>
                <title>Validazione Regole</title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Validazione Regole
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Validazione Regole
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>

                                <IconButton
                                    color="primary"
                                    onClick={() => readAll(false)}
                                >
                                    {loading ?
                                        <CircularProgress color={"primary"} size={24} /> :
                                        <CachedIcon fontSize="small" />}
                                </IconButton>

                                <Button
                                    color="primary"
                                    startIcon={<PlusIcon fontSize="small" />}
                                    sx={{ m: 1 }}
                                    variant="contained"
                                    component={RouterLink}
                                    to="/validazione/nuova"
                                >
                                    Nuova Validazione
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Grid container
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Tabs
                                indicatorColor="primary"
                                onChange={handleTabsChange}
                                scrollButtons="auto"
                                textColor="primary"
                                value={currentTab}
                                variant="scrollable"
                            >
                                {tabs.map((tab) => (
                                    <Tab
                                        key={tab.value}
                                        label={tab.label}
                                        value={tab.value}
                                    />
                                ))}
                            </Tabs>
                            <Divider />

                            {currentTab === "validazioni" &&

                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Table
                                                idDefaultColumnSort="finishedAt"
                                                Data={validations || []}
                                                Columns={[
                                                    {
                                                        Header: 'Stato',
                                                        accessor: (row) => {
                                                            switch (row.state) {
                                                                case "finished":
                                                                    return "terminata"
                                                                case "created":
                                                                    return "in corso"
                                                                default:
                                                                    return "-"
                                                            }
                                                        },
                                                        Cell: (row) => {
                                                            switch (row.value) {
                                                                case "in corso":
                                                                    return (
                                                                        <Label color="warning">
                                                                            <CircularProgress
                                                                                classes={{ colorPrimary: "white" }}
                                                                                size={13} />
                                                                            <div style={{ marginLeft: "10px" }}>IN CORSO</div>
                                                                        </Label>
                                                                    )
                                                                case "terminata":
                                                                    return <Label color="success">TERMINATA</Label>
                                                            }
                                                            return row.value;
                                                        },
                                                        Filter: SelectColumnFilter,
                                                        filter: SelectColumnFilterMethod,
                                                    },
                                                    {
                                                        Header: 'Istituto',
                                                        accessor: 'istituto',
                                                    },
                                                    {
                                                        Header: 'Documento',
                                                        accessor: 'fileName',
                                                    },
                                                    {
                                                        Header: "Iniziata alle",
                                                        accessor: 'createdAt',
                                                        Cell: (row) => {
                                                            return moment.unix(row.value).format('DD/MM/YYYY HH:mm:ss')
                                                        },
                                                        Filter: DatePickerRangeFilter,
                                                        filter: DatePickerRangeFilterMethod
                                                    },
                                                    {
                                                        Header: "Terminata alle",
                                                        accessor: 'finishedAt',
                                                        Cell: (row) => {
                                                            if (!row.value || row.value === 0) return "-";
                                                            return moment.unix(row.value).format('DD/MM/YYYY HH:mm:ss')
                                                        },
                                                        Filter: DatePickerRangeFilter,
                                                        filter: DatePickerRangeFilterMethod
                                                    },
                                                    {
                                                        Header: "Regole invalidate",
                                                        accessor: 'rulesInvalidated',
                                                    },
                                                    {
                                                        Header: 'Azioni',
                                                        Cell: (rows) => {
                                                            return (
                                                                <>
                                                                    <Tooltip title="Dettagli">
                                                                        <IconButton
                                                                            aria-label='Dettagli'
                                                                            component={RouterLink}
                                                                            to={`/validazione/${rows.row.original.id}`}
                                                                        >
                                                                            <ArrowRightIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            )
                                                        }
                                                    },
                                                ]}
                                            >
                                            </Table>
                                        </CardContent>
                                    </Card>
                                </Grid>}
                            {currentTab === 'documentiValidazione' && <PageDocValidation />}
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
}

export default PageRuleValidation;
