import React, { FC, useEffect, useState } from "react";
import { Dossier, Callback } from "src/types/generated";
import {
  Box,
  Grid,
  Card,
  Typography,
  /* Table_MUI */
  TableHead,
  TableRow,
  TableCell,
  Divider,
  CardContent,
  TableBody,
  Container,
  Paper,
  Tooltip,
  Button,
  IconButton,
  TableFooter,
  Skeleton,
  CardHeader,
  Modal,
  LinearProgress,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import Table_MUI from "@material-ui/core/Table";
import Scrollbar from "src/material/Scrollbar";
import GraphqlClient from "src/client/graphql.client";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import LoadingScreen from "src/material/LoadingScreen";
import XIcon from "../../../icons/X";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/light";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
  Table,
  SelectColumnFilter,
  DatePickerRangeFilter,
  DatePickerRangeFilterMethod,
} from "../../../components/Table/Table";
import PageviewIcon from "@material-ui/icons/Pageview";
interface DetailsProps {
  dossier: Dossier;
  callbacks: Callback[];
  loading: Boolean;
}

const DettagliGenerale: FC<DetailsProps> = ({
  dossier,
  callbacks,
  loading,
}: DetailsProps) => {
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState("");



  const HandleDownloadDocument = async (code) => {
    const bytes = code;
    let length = bytes.length;

    let out = new Uint8Array(length);
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    let myBlob = new Blob([out], { type: "application/octet-stream" });


    const URLblobObj = window.URL.createObjectURL(myBlob);

    let a = document.createElement("a");
    a.style.display = "none";
    a.href = URLblobObj;
    a.download = "codice.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(URLblobObj);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={3}>
      <ModalCodice open={open} code={code} handleClose={handleClose} />
      <Grid xs={12} item>
        <Card>
          <CardHeader title="Dettagli" />
          <Divider />
          <CardContent>
            <Table_MUI>
              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="h6">
                    Identificativo
                  </Typography>
                </TableCell>
                <TableCell>
                  {loading ? (
                    <Skeleton width="361px" height="31px" />
                  ) : (
                    <Typography color="textPrimary" variant="h6">
                      {dossier.description ?? dossier.id}
                    </Typography>
                  )}
                </TableCell>

                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="h6">
                    Tipologia
                  </Typography>
                </TableCell>
                <TableCell>
                  {loading ? (
                    <Skeleton width="361px" height="31px" />
                  ) : (
                    <Typography color="textPrimary" variant="h6">
                      {dossier.dossierType?.name ?? "---"}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="h6">
                    Stato processazione
                  </Typography>
                </TableCell>
                <TableCell>
                  {loading ? (
                    <Skeleton height="31px" />
                  ) : (
                    <Typography color="textPrimary" variant="h6">
                      {dossier.state}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left">
                  <Typography color="textSecondary" variant="h6">
                    Errore
                  </Typography>
                </TableCell>
                <TableCell>
                  {loading ? (
                    <Skeleton height="31px" />
                  ) : (
                    <Typography color="textPrimary" variant="h6">
                      {dossier.error || "Nessuno"}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </Table_MUI>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} item>
        <Card>
          <CardHeader title="Callbacks" />
          <Divider />
          <CardContent>
            <Table_MUI>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      Stato
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      Orario
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      Url
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      Tipo
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      Payload
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      Codice risposta
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      Corpo risposta
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      Note
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {callbacks?.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {loading ? (
                        <Skeleton width="228px" height="20px" />
                      ) : (
                        <Chip
                          label={entry.success ? "RIUSCITA" : "FALLITA"}
                          style={{
                            backgroundColor: entry.success
                              ? "#4caf50"
                              : "#f44336",
                            fontWeight: "bold",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {loading ? (
                        <Skeleton width="228px" height="20px" />
                      ) : (
                        dayjs.unix(entry.timestamp).format("DD/MM/YYYY HH:mm:ss")
                      )}
                    </TableCell>
                    <TableCell>
                      {loading ? (
                        <Skeleton width="228px" height="20px" />
                      ) : (
                        entry.url
                      )}
                    </TableCell>
                    <TableCell>
                      {loading ? (
                        <Skeleton width="228px" height="20px" />
                      ) : (
                        entry.method
                      )}
                    </TableCell>

                    <TableCell>
                      {loading ? (
                        <Skeleton width="228px" height="20px" />
                      ) : (
                        <Tooltip title={"Visualizza payload richiesta"}>
                          <IconButton
                            aria-label="Download"
                            onClick={() => {
                              HandleDownloadDocument(entry.requestBody);
                            }}
                          >
                            <PageviewIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      {loading ? (
                        <Skeleton width="228px" height="20px" />
                      ) : (
                        <Chip
                          label={entry.responseCode}
                          style={{
                            backgroundColor: entry.success
                              ? "#4caf50"
                              : "#f44336",
                            fontWeight: "bold",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {loading ? (
                        <Skeleton width="228px" height="20px" />
                      ) : (
                        <Tooltip title={"Visualizza payload risposta"}>
                          <IconButton
                            aria-label="Download"
                            onClick={() => {
                              HandleDownloadDocument(entry.responseBody);
                            }}
                          >
                            <PageviewIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>

                    <TableCell>
                      {loading ? (
                        <Skeleton width="228px" height="20px" />
                      ) : (
                        entry.errorDescription
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table_MUI>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DettagliGenerale;

const ModalCodice: FC<{
  open: boolean;
  handleClose: () => void;
  code: string;
}> = ({ open, handleClose, code }) => {
  // @ts-ignore
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <Box
          sx={{
            minHeight: "100%",
            p: 3,
          }}
        >
          <Container>
            <Paper elevation={12} sx={{ position: "relative" }}>
              <Box
                sx={{
                  display: "flex",
                  pb: 2,
                  pt: 3,
                  px: 3,
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "baseline",
                      marginBottom: 1,
                      width: "100%",
                    }}
                  >
                    <Typography color="textPrimary" variant="h5">
                      Codice
                    </Typography>

                    <IconButton>
                      <XIcon onClick={handleClose} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box p={3} sx={{ overflowY: 'scroll', height: 400 }}>
                <SyntaxHighlighter
                  customStyle={{ backgroundColor: "transparent" }}
                  wrapLongLines={true}
                  wrapLines={true}
                  language="json"
                  style={atomOneDark}
                >
                  {code}
                </SyntaxHighlighter>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  px: 3,
                  py: 1.5,
                }}
              >
                <Button
                  color="primary"
                  sx={{ mr: 2 }}
                  onClick={handleClose}
                  variant="outlined"
                >
                  Ok
                </Button>
              </Box>
            </Paper>
          </Container>
        </Box>
      </div>
    </Modal>
  );
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: "100%",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
    },
  })
);
