import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react'
import { DataTable, Document, DocumentCodeExecution, DossierCodeExecution } from 'src/types/generated'
import {
  Box,
  Grid,
  Card,
  Typography,
  TableRow,
  TableCell,
  Divider,
  CardContent, IconButton, Collapse, Tabs, Tab, LinearProgress
} from '@material-ui/core'
import Scrollbar from 'src/material/Scrollbar'
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import GraphqlClient from "../../../client/graphql.client";
import { useSnackbar } from "notistack";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DettaglioCodice from "./DettagliCodice";
import dayjs from "dayjs";
import { DatePickerRangeFilter, DatePickerRangeFilterMethod, SelectColumnFilter, SelectColumnFilterMethod, Table } from 'src/components/Table/Table';
import store, { useSelector } from 'src/store';
import { fetchAsyncData } from './redux/logs.redux';
import Label from 'src/material/Label';

const LogRegole: FC<{ id?: string, ruleID?: string }> = ({ id, ruleID }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([] as DossierCodeExecution[]);
  const { enqueueSnackbar } = useSnackbar();
  const paginationLogs = useSelector(state => state.dossierLogs)

  const [open, setOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>();
  const [currentSubTab, setCurrentSubTab] = useState<string>("codice");

  const handleSubTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentSubTab(value);
  };

  const collapseRow = useCallback(
    ({ row }) => (
      <>
        <Box margin={1}>
          <Tabs
            indicatorColor="primary"
            onChange={handleSubTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentSubTab}
            variant="scrollable"
          >
            {["codice", "metas", "input", "console", "errori",].map((title) => (
              <Tab
                key={title}
                label={title}
                value={title}
              />
            ))}
          </Tabs>
          {currentSubTab === "codice" && <DettaglioCodice code={row.original.code} />}
          {currentSubTab === "metas" && <DettaglioCodice json={true} code={row.original.metas} />}
          {currentSubTab === "input" && <DettaglioCodice json={true} code={row.original.input} />}
          {currentSubTab === "console" && <DettaglioCodice code={row.original.logs?.join("\n")} />}
          {currentSubTab === "errori" && <DettaglioCodice code={row.original.errors?.join("\n")} />}
        </Box>
      </>
    ), [currentSubTab])

  const fetchIdRef = useRef(0)
  const fetchData = useCallback((records, page, orderBy, filters, selectOptions) => {
    const fetchId = ++fetchIdRef.current
    const sumColumns = []
    const avgColumns = []

    if (ruleID) {
      filters.push({ column: 'rule_id', value: [ruleID], type: 'text' })
    }

    if (id) {
      filters.push({ column: 'dossier_id', value: [id], type: 'text' })
    }

    if (fetchId === fetchIdRef.current) {
      store.dispatch(fetchAsyncData({ records, page, orderBy, filters, selectOptions, sumColumns, avgColumns }))
    }
  }, [])

  useEffect(() => {
    if (paginationLogs.error != null) {
      console.log(paginationLogs.error)
      enqueueSnackbar('Errore', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  }, [paginationLogs.error])

  return (
    <Box m={1}>
      <Grid
        container
        spacing={3}
      >
        <Grid item style={{ flex: '1' }}>
          <Card>
            <CardContent>
              {/* {loadingData && <LinearProgress />} */}
              <Table
                fetchData={fetchData}
                pageCount={paginationLogs.pages}
                loading={paginationLogs.loading}
                selectFilterColumns={['platform']} // nome della colonna su Postgres su cui applicare il filtro select
                selectFilterOptions={[
                  {
                    column: 'platform',
                    options: ['backend', 'frontend']
                  }
                ]}
                idDefaultColumnSort="executedAt"
                Data={paginationLogs.data ?? []}
                RenderRowSubComponent={collapseRow}
                Columns={[
                  {
                    Header: () => null,
                    id: 'expander',
                    width: 50,
                    Cell: ({ row }) => (
                      <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? <IconButton><KeyboardArrowDownIcon fontSize="small" /></IconButton> : <IconButton><KeyboardArrowUpIcon fontSize="small" /></IconButton>}
                      </span>
                    ),
                  },
                  {
                    Header: 'Nome',
                    accessor: 'ruleName',
                    filter: 'fuzzyText',
                  },
                  {
                    Header: 'Dossier',
                    accessor: 'dossierId',
                    filter: 'fuzzyText',
                  },
                  {
                    Header: 'Eseguita il',
                    accessor: 'executedAt',
                    Cell: (row) => {
                      return dayjs.unix(row.row.original.executedAt).format('DD/MM/YYYY HH:mm:ss')
                    },
                    Filter: DatePickerRangeFilter,
                  },
                  {
                    Header: 'Piattaforma',
                    accessor: 'platform',
                    id: 'platform',
                    Filter: SelectColumnFilter,
                    Cell: cell => <Label>{cell.value}</Label>
                  },
                  {
                    Header: 'Risultato',
                    accessor: 'result',
                    Cell: (row) => {
                      return (

                        
                          <SyntaxHighlighter
                            customStyle={{ backgroundColor: 'transparent', overflowX: 'hidden'}}
                            language="javascript"
                            style={atomOneDark}
                            PreTag={Scrollbar}
                          >
                            {row.row.original.result}
                          </SyntaxHighlighter>

                      )
                    }
                  },
                ]}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LogRegole;
