import PaginaRegole from "./pages/Regole/pagina.regole"
import PaginaDettagliRegole from "./pages/Regole/dettagli.regole"
import PaginaDatatables from "./pages/Datatables/pagina.datatables"
import NuovaDataTable from "./pages/Datatables/nuova.datatables"
import PaginaDossier from "./pages/dossier/pagina.dossier";
import PaginaDocumenti from "./pages/documenti/pagina.documenti";
import PaginaDocTypes from "./pages/tipoDocumenti/pagina.tipodoc";
import NuovoDocType from './pages/tipoDocumenti/nuovo.tipodoc';
import PaginaDettagliDocType from './pages/tipoDocumenti/dettagli.tipodoc';
import PaginaDossierTypes from "./pages/tipoDossier/pagina.tipodossier";
import NuovoDossierType from './pages/tipoDossier/nuovo.tipodossier';
import PaginaDettagliDossierType from './pages/tipoDossier/dettagli.tipodossier';
import PaginaDettagliDocs from './pages/documenti/dettagli.documenti';
import PaginaDettagliDossier from './pages/dossier/dettagli.dossier';
import PaginaRegoleDocumenti from "./pages/documenti/regole.documenti";
import ImportDossier from "./pages/dossier/import.dossier";
import DettagliDatatables from "./pages/Datatables/dettagli.datatables"
import PlaygroundNuovaDocRule from "./pages/playground/documenti/nuova.play"
import PlaygroundDocumenti from "./pages/playground/documenti/documenti.play"
import PlaygroundAggiornaDocRule from "./pages/playground/documenti/aggiorna.play"
import PlaygroundAggiornaDossierRule from "./pages/playground/dossier/aggiorna.play"
import PlaygroundDossier from "./pages/playground/dossier/dossier.play"
import PlaygroundNuovaDossierRule from "./pages/playground/dossier/nuova.play"

import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './material/AuthGuard';
import DashboardLayout from './material/dashboard/DashboardLayout';
import GuestGuard from './material/GuestGuard';
import LoadingScreen from './material/LoadingScreen';
import PageUsers from "./pages/users/page.user";
import NewUser from "./pages/users/new.user";
import UpdateUser from "./pages/users/update.user";
import PageRole from "./pages/roles/page.role";
import NewRole from "./pages/roles/new.role";
import NewPolicy from "./pages/roles/new.policy";
import PermissionGuard from "./components/PermissionGuard/PermissionGuard";
import NewGroup from "./pages/roles/new.group";
import PasswordReset from "./pages/authentication/password-reset/PasswordReset";
import PasswordRecovery from "./pages/authentication/password-recovery/PasswordRecovery";
// YEOMAN IMPORT ANCHOR */

import PageRuleValidation from "./pages/validazione/pagina.validazione";
import NuovaValidazione from "./pages/validazione/nuova.validazione";
import DettagliValidazione from "./pages/validazione/dettagli.validazione";
import PaginaNotifiche from "./pages/Notifications/pagina.notifiche";

const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/account/pagina.account')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/pagina.dashboard')));

const routes: PartialRouteObject[] = [
    {
        path: 'authentication',
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                )
            },
            {
                path: 'register',
                element: (
                    <GuestGuard>
                        <Register />
                    </GuestGuard>
                )
            },
            {
                path: 'register-unguarded',
                element: <Register />
            },
            {
                path: 'password-recovery',
                element: <PasswordRecovery />
            },
            {
                path: 'password-reset',
                element: <PasswordReset />
            },
        ]
    },
    {
        path: '*',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '/',
                element: <Finance />
            },
            {
                path: 'account',
                element: <Account />
            },
            {
                path: 'playground',
                children: [
                    {
                        path: '/',
                        element: <PlaygroundDocumenti />,
                    },
                    {
                        path: '/documenti',
                        element: <PlaygroundDocumenti />
                    },
                    {
                        path: '/documenti/nuova',
                        element: <PlaygroundNuovaDocRule />
                    },
                    {
                        path: '/documenti/:id/aggiorna',
                        element: <PlaygroundAggiornaDocRule />
                    },
                    {
                        path: '/dossier',
                        element: <PlaygroundDossier />
                    },
                    {
                        path: '/dossier/nuova',
                        element: <PlaygroundNuovaDossierRule />
                    },
                    {
                        path: '/dossier/:id/aggiorna',
                        element: <PlaygroundAggiornaDossierRule />
                    },
                ]
            },
            {
                path: 'dossier',
                children: [
                    {
                        path: '/',
                        element: <PaginaDossier />
                    },
                    {
                        path: '/dettagli/:id',
                        element: <PaginaDettagliDossier />
                    },
                    {
                        path: '/dettagli/:id/documenti',
                        element: <PaginaDocumenti />
                    },
                    {
                        path: '/dettagli/:id/documenti/:docid',
                        element: <PaginaDettagliDocs />
                    },
                    {
                        path: '/dettagli/:id/documenti/:docid/regole',
                        element: <PaginaRegoleDocumenti />
                    },
                    {
                        path: '/import',
                        element: <ImportDossier />
                    },

                ]
            },
            {
                path: 'tipologie',
                children: [

                    {
                        path: '/dossier',
                        element: <PaginaDossierTypes />,
                    },
                    {
                        path: '/dossier/nuova',
                        element: <NuovoDossierType />
                    },
                    {
                        path: '/dossier/dettagli/:id',
                        element: <PaginaDettagliDossierType />,
                    },
                    {
                        path: '/documenti',
                        element: <PaginaDocTypes />,
                    },
                    {
                        path: '/documenti/nuova',
                        element: <NuovoDocType />
                    },
                    {
                        path: '/documenti/dettagli/:id',
                        element: <PaginaDettagliDocType />,
                    },
                ]
            },
            {
                path: 'regole',
                children: [
                    {
                        path: '/',
                        element: <PaginaRegole />
                    },
                    {
                        path: '/dettagli/:id',
                        element: <PaginaDettagliRegole />
                    },
                ]
            },
            {
                path: 'datatables',
                children: [
                    {
                        path: '/',
                        element: <PaginaDatatables />
                    },
                    {
                        path: 'nuova',
                        element: <NuovaDataTable />
                    },
                    {
                        path: '/dettagli/:name',
                        element: <DettagliDatatables />
                    },

                ]
            },
            {
                path: 'users',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"users"}>
                                <PageUsers />
                            </PermissionGuard>
                    },
                    {
                        path: 'new',
                        element: <PermissionGuard action={"allow"} resource={"createUser"}>
                            <NewUser />
                        </PermissionGuard>
                    },
                    {
                        path: 'modifica/:id',
                        element: <PermissionGuard action={"allow"} resource={"updateUser"}>
                            <UpdateUser />
                        </PermissionGuard>
                    }
                ]
            },
            {
                path: 'roles',
                children: [
                    {
                        path: '/',
                        element: <PageRole />
                    },
                    {
                        path: 'new/ruolo',
                        element: <NewRole />
                    },
                    {
                        path: 'new/gruppo',
                        element: <NewGroup />
                    },
                    {
                        path: 'nuova/policy',
                        element: <NewPolicy />
                    },
                ]
            },
            {
                path: 'notifications',
                children: [
                    {
                        path: '/',
                        element: <PaginaNotifiche />
                    },
                ]
            },
            // YEOMAN ANCHOR */
            {
                path: 'validazione',
                children: [
                    {
                        path: '/',
                        element: <PageRuleValidation />
                    },
                    {
                        path: '/nuova',
                        element: <NuovaValidazione />
                    },
                    {
                        path: '/:id',
                        element: <DettagliValidazione />
                    },
                ]
            },
        ]
    },
];

export default routes;
