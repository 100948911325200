import { useEffect, useState, ChangeEvent } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useParams, Navigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box, Breadcrumbs,
  Button,
  Card, CardContent,
  Container,
  Grid, Link,
  IconButton,
  Typography,
  Tabs, Tab,
  Table, TableHead, TableCell, TableRow, TableBody, Divider,
} from '@material-ui/core';
import Scrollbar from '../../material/Scrollbar';
import useSettings from "../../hooks/useSettings";
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import DeleteIcon from '@material-ui/icons/Delete';
import { DocumentRule } from 'src/types/generated';
import { loadRegola, deleteDocumentRule } from './commands.regole'
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import DettaglioTableData from '../documenti/components/DettagliTableData'

const tabs = [
  { label: 'Codice', value: 'codice' },
  { label: 'Package.json', value: 'packagejson' },
  { label: 'Datatables', value: 'datatables' },
];

const PaginaDettagliRegole: FC = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [rule, setRule] = useState({} as DocumentRule);
  const [loadingRule, setLoadingRule] = useState(false)

  const { ...other } = props;

  const id = params.id;

  const [currentTab, setCurrentTab] = useState<string>('');
  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const deleteRegola = async (id: string) => {
    try {
      const res = await deleteDocumentRule(id)
      enqueueSnackbar("Regola eliminata", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      }) 
    } catch (e) {
      console.error('Impossibile ottenere regola: ', e);
      enqueueSnackbar("Impossibile cancellare la regola", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    } finally {
      navigate('/regole', { replace: true } )
    }
  }

  useEffect(() => {
    const caricaRegola = async (id: string) => {
      try {
        setLoadingRule(true)
        const res = await loadRegola(id)
        setRule(res.data)
      } catch (e) {
        console.error('Impossibile ottenere regola: ', e);
        enqueueSnackbar("Impossibile ottenere la regola", {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      } finally {
        setLoadingRule(false)
        setCurrentTab('codice')
      }
    }

    caricaRegola(id)
  }, [])

  return (
    <>
      <Helmet>
        <title>Dettagli: {id} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {id}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/regole"
                  variant="subtitle2"
                >
                  Regole
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {id}
                </Typography>
              </Breadcrumbs>

            </Grid>
            <Grid item>
              <Button
                color="secondary"
                startIcon={<DeleteIcon fontSize="small" />}
                sx={{ mt: 1 }}
                variant="outlined"
                onClick={() => {deleteRegola(id)} }
              >
                Elimina
              </Button>
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<ArrowLeftIcon fontSize="small" />}
                sx={{ mt: 1, ml: 2 }}
                to="/regole"
                variant="outlined"
              >
                Indietro
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Box sx={{ mt: 3 }}>
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Tabs>
            </Box>
            <Divider />
            <Box sx={{ mt: 3 }}>
              <Card {...other}>

                {currentTab === 'codice' &&
                  <CardContent>
                    <SyntaxHighlighter language="javascript" style={atomOneDark}>
                      {loadingRule ? "Loading..." : rule?.code}
                    </SyntaxHighlighter>
                  </CardContent>
                }
                {currentTab === 'packagejson' &&
                  <CardContent>
                    <SyntaxHighlighter language="json" style={atomOneDark}>
                      {loadingRule ? "Loading..." : rule?.packageJSON}
                    </SyntaxHighlighter>
                  </CardContent>
                }

              </Card>
              {currentTab === 'datatables' && <DettaglioTableData tables={rule.dataTables} />}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default PaginaDettagliRegole
