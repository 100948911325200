import regole from '../pages/Regole/redux.regole'
import regoleDossier from '../pages/Regole/redux.regoleDossier'
import datatables from '../pages/Datatables/redux.datatables'
import { combineReducers } from '@reduxjs/toolkit';
import authentication from '../pages/login/redux.login';
import storage from 'redux-persist/lib/storage'
import persistReducer from "redux-persist/es/persistReducer";
import dossier from '../pages/dossier/redux.dossier';
import documenti from '../pages/documenti/redux.documenti';
import doctypes from '../pages/tipoDocumenti/redux.tipodoc';
import dossiertypes from '../pages/tipoDossier/redux.tipodossier';
import users from '../pages/users/redux.user'
import roles from '../pages/roles/redux.role'
// YEOMAN ANCHOR IMPORT */
import docValidation from '../pages/validazione/redux.docvalidazione'
import docExecLogs from '../pages/playground/redux.logDocs'
import dossierExecLogs from '../pages/playground/redux.logDossier'
import tablePref from '../components/Table/redux.table'
import dossierLogs from '../pages/dossier/components/redux/logs.redux'
import docLogs from '../pages/documenti/components/redux/logs.redux'
import dossierRuleExecs from '../pages/dossier/components/redux/execs.redux'

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    blacklist: ['tokenRefreshed', 'refreshingToken']
}

// Aggiungere reducers qui
const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authentication),
    tablePref,
    users,
    roles,
    dossier: dossier,
    documenti: documenti,
    doctypes: doctypes,
    dossiertypes: dossiertypes,
    regole: regole,
    datatables: datatables,
    regoleDossier: regoleDossier,
    // YEOMAN ANCHOR REDUCER */
    docValidation,
    docExecLogs,
    dossierExecLogs,
    dossierLogs,
    docLogs,
    dossierRuleExecs
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth'],
    debug: true
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;
