import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react'
import { DataTable, Document, DocumentCodeExecution } from 'src/types/generated'
import {
    Box,
    Grid,
    Card,
    CardContent,
    IconButton, Tabs, Tab, LinearProgress
} from '@material-ui/core'
import Scrollbar from 'src/material/Scrollbar'
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import GraphqlClient from "../../../client/graphql.client";
import { useSnackbar } from "notistack";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DettaglioCodice from "./DettagliCodice";
import { DatePickerRangeFilter, DatePickerRangeFilterMethod, SelectColumnFilter, SelectColumnFilterMethod, Table } from "../../../components/Table/Table"
import dayjs from "dayjs";
import store, { useSelector } from 'src/store';
import { fetchAsyncData } from './redux/logs.redux';

const RegoleEseguite: FC<{ id?: string, ruleID?: string }> = ({ id, ruleID }) => {
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState([] as DocumentCodeExecution[]);
    const [currentSubTab, setCurrentSubTab] = useState<string>("codice");
    const { enqueueSnackbar } = useSnackbar();

    const handleSubTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentSubTab(value);
    };

    const collapseRow = useCallback(
        ({ row }) => (
            <>
                <Box margin={1}>
                    <Tabs
                        indicatorColor="primary"
                        onChange={handleSubTabsChange}
                        scrollButtons="auto"
                        textColor="primary"
                        value={currentSubTab}
                        variant="scrollable"
                    >
                        {["codice", "console", "errori", "form data", "raw text", "tabelle", "testo con posizioni", "data tables",].map((title) => (
                            <Tab
                                key={title}
                                label={title}
                                value={title}
                            />
                        ))}
                    </Tabs>
                    {currentSubTab === "codice" && <DettaglioCodice code={row.original.code} />}
                    {currentSubTab === "raw text" && <DettaglioCodice code={row.original.rawText} />}
                    {currentSubTab === "form data" && <DettaglioCodice json={true} code={row.original.formData} />}
                    {currentSubTab === "data tables" && <DettaglioCodice json={true} code={row.original.dataTables} />}
                    {currentSubTab === "tabelle" && <DettaglioCodice json={true} code={row.original.tables} />}
                    {currentSubTab === "testo con posizione" && <DettaglioCodice json={true} code={row.original.rawTextWithGeometry} />}
                    {currentSubTab === "console" && <DettaglioCodice code={row.original.logs?.join("\n")} />}
                    {currentSubTab === "errori" && <DettaglioCodice code={row.original.errors?.join("\n")} />}
                </Box>
            </>
        ), [currentSubTab])

    const paginationLogs = useSelector(state => state.docLogs)
    const fetchIdRef = useRef(0)
    const fetchData = useCallback((records, page, orderBy, filters, selectOptions) => {
        const fetchId = ++fetchIdRef.current
        const sumColumns = []
        const avgColumns = []

        if (ruleID) {
            filters.push({ column: 'rule_id', value: [ruleID], type: 'text' })
        }

        if (id) {
            filters.push({ column: 'document_id', value: [id], type: 'text' })
        }

        if (fetchId === fetchIdRef.current) {
            store.dispatch(fetchAsyncData({ records, page, orderBy, filters, selectOptions, sumColumns, avgColumns }))
        }
    }, [])

    useEffect(() => {
        if (paginationLogs.error != null) {
            console.log(paginationLogs.error)
            enqueueSnackbar('Errore', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    }, [paginationLogs.error])

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        {loadingData && <LinearProgress />}
                        <Table
                            fetchData={fetchData}
                            pageCount={paginationLogs.pages}
                            loading={paginationLogs.loading}
                            selectFilterColumns={['platform']} // nome della colonna su Postgres su cui applicare il filtro select
                            selectFilterOptions={paginationLogs.options}
                            idDefaultColumnSort="executedAt"
                            Data={paginationLogs.data ?? []}
                            RenderRowSubComponent={collapseRow}
                            Columns={[
                                {
                                    Header: () => null,
                                    id: 'expander',
                                    width: 50,
                                    Cell: ({ row }) => (
                                        <span {...row.getToggleRowExpandedProps()}>
                                            {row.isExpanded ? <IconButton size='small'><KeyboardArrowDownIcon fontSize="small" /></IconButton> : <IconButton size='small'><KeyboardArrowUpIcon fontSize="small" /></IconButton>}
                                        </span>
                                    ),
                                },
                                {
                                    Header: 'Nome',
                                    accessor: 'ruleName',
                                    filter: 'fuzzyText',
                                },
                                {
                                    Header: 'Eseguita il',
                                    accessor: 'executedAt',
                                    Cell: (row) => {
                                        return dayjs.unix(row.row.original.executedAt).format('DD/MM/YYYY HH:mm:ss')
                                    },
                                    Filter: DatePickerRangeFilter,
                                    filter: DatePickerRangeFilterMethod,
                                },
                                {
                                    Header: 'Piattafroma',
                                    accessor: 'platform',
                                    Filter: SelectColumnFilter,
                                    filter: SelectColumnFilterMethod,
                                },
                                {
                                    Header: 'Risultato',
                                    accessor: 'result',
                                    Cell: (row) => {
                                        return (

                                            <SyntaxHighlighter
                                                customStyle={{ backgroundColor: 'transparent' }}
                                                language="javascript"
                                                style={atomOneDark}
                                                PreTag={Scrollbar}
                                            >
                                                {row.row.original.result}
                                            </SyntaxHighlighter>

                                        )
                                    }
                                },
                            ]}
                        />

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default RegoleEseguite;
