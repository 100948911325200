import {
  Button,
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Trash from 'src/icons/Trash';
import { deleteDocumentRule, loadRegole } from 'src/pages/Regole/commands.regole';

interface Props {
  open: boolean
  handleClose: any
  id: string
}

const DeleteDocRuleDialog = (props: Props) => {
  const [id, setID] = useState("")
  const { enqueueSnackbar } = useSnackbar()
  const handleDelete = async () => {
    let res = await deleteDocumentRule(id)
    if (res.error) {
      console.error('Impossibile eliminare la regola: ', res.message);
      enqueueSnackbar("Impossibile eliminare la regola", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
      props.handleClose()
      return
    }

    enqueueSnackbar("Regola eliminata", {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      variant: 'success'
    });
    loadRegole()
    props.handleClose()
  }

  useEffect(() => {
    setID(props.id)
  }, [props.id])
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>
        <WarningIcon />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Azione irreversibile, vuoi continuare?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={props.handleClose}
        >
          Annulla
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Trash />}
          onClick={handleDelete}
        >
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDocRuleDialog
