import { FC, useCallback } from "react";
import { useEffect, useState } from "react";
import useSettings from "../../hooks/useSettings";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Container,
  Card,
  Grid,
  Link,
  IconButton,
  Typography,
  Tooltip,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import Scrollbar from "../../material/Scrollbar";
import ChevronRightIcon from "../../icons/ChevronRight";

import { useSelector as useReduxSelector } from "../../store";
import dayjs from "dayjs";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import {
  fetchValidationDocs,
  downloadText,
  downloadSource,
  deleteDocValidation,
  diff,
} from "./commands.validazione";
import { useSnackbar } from "notistack";
import {
  Table,
  SelectColumnFilter,
  SelectColumnFilterMethod,
  DatePickerRangeFilter,
  DatePickerRangeFilterMethod,
} from "src/components/Table/Table";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Trash from "src/icons/Trash";
import { Button } from "../../components/Button/Button";
import CompareIcon from "@material-ui/icons/Compare";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import GraphqlClient from "src/client/graphql.client";
import DownloadB64 from "src/utils/downloadB64";
// filepond plugin
/*
registerPlugin(FilePondPluginFileValidateType);
*/

const HandleDownloadFile = async (
  isPDF: boolean,
  id: string,
  description: string,
  setDownloadingState,
  enqueueSnackbar
) => {
  let file;
  setDownloadingState(true);
  if (isPDF) {
    file = await downloadSource(id);
  } else {
    file = await downloadText(id);
  }
  if (file.error) {
    enqueueSnackbar("Impossibile scaricare il file", {
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      variant: "error",
    });
    console.error(file.message);
    return;
  }
  const bytes = atob(file.data);

  let length = bytes.length;

  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  let myBlob = new Blob([out], { type: "application/octet-stream" });

  const URLblobObj = window.URL.createObjectURL(myBlob);

  let a = document.createElement("a");
  a.style.display = "none";
  a.href = URLblobObj;
  if (isPDF) {
    a.download = description + ".pdf";
  } else {
    a.download = description + ".txt";
  }
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(URLblobObj);
  setDownloadingState(false);
};

const DownloadPDFButton: FC<{ rows: any }> = (props) => {
  const rows = props.rows;
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Tooltip title="Download PDF">
        <IconButton
          disabled={isDownloading}
          aria-label="Download PDF"
          onClick={() =>
            HandleDownloadFile(
              true,
              rows.row.original.id,
              rows.row.original.description,
              setIsDownloading,
              enqueueSnackbar
            )
          }
        >
          {isDownloading ? (
            <CircularProgress size={18} />
          ) : (
            <PictureAsPdfIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
};

const DownloadOCRButton: FC<{ rows: any }> = (props) => {
  const rows = props.rows;
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Tooltip title="Download OCR">
        <IconButton
          disabled={rows.row.original.urlOCR ? false : true}
          aria-label="Download OCR"
          onClick={() =>
            HandleDownloadFile(
              false,
              rows.row.original.id,
              rows.row.original.description,
              setIsDownloading,
              enqueueSnackbar
            )
          }
        >
          {isDownloading ? (
            <CircularProgress size={18} />
          ) : (
            <TextFieldsIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
};

const PageDocValidation: FC = (props) => {
  const state = useReduxSelector((state) => state.docValidation);
  const { settings } = useSettings();

  const { ...other } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [docToDelete, setDocToDelete] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);

  const handleOpenDeleteDialog = (id: string) => {
    setOpenDeleteDialog(true);
    setDocToDelete(id);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDocToDelete("");
  };

  const deleteDocVal = async (id: string) => {
    const res = await deleteDocValidation(id);
    if (res.error) {
      handleCloseDeleteDialog();
      enqueueSnackbar("Impossibile eliminare il documento", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
      console.error(res.message);
      return;
    }

    handleCloseDeleteDialog();
    fetchValidationDocs();
    enqueueSnackbar("Documento eliminato", {
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      variant: "success",
    });
  };

  const handleDownloadDiff = async (id: string) => {
    console.log(id);
    setIsDownloading(true);
    const res = await diff(id);
    if (res.error) {
      console.error(res.message);
      enqueueSnackbar("Impossibile scaricare le differenze", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
      setIsDownloading(false);
      return;
    }

    let myBlob = new Blob([res.data], { type: "text/plain" });

    const URLblobObj = window.URL.createObjectURL(myBlob);

    let a = document.createElement("a");
    a.style.display = "none";
    a.href = URLblobObj;
    a.download = `diff.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(URLblobObj);
    setIsDownloading(false);
  };

  const handleDownloadZip = async (id: string) => {
    console.log(id);
    setIsDownloading(true);
    try {
      const res = await GraphqlClient.downloadAllValidationFiles({ id });
      DownloadB64(
        res.downloadAllValidationFiles.name,
        res.downloadAllValidationFiles.bsae64Content
      );
    } catch (e) {
      enqueueSnackbar("Impossibile scaricare i file", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    fetchValidationDocs();
  }, []);

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Scrollbar>
            <Table
              Data={state.data || []}
              Columns={[
                {
                  Header: "Descrizione",
                  accessor: "description",
                },
                {
                  Header: "Caricato il",
                  accessor: "createdAt",
                  Cell: (rows) => {
                    return dayjs
                      .unix(rows.row.values.createdAt)
                      .format("DD/MM/YYYY");
                  },
                  Filter: DatePickerRangeFilter,
                  filter: DatePickerRangeFilterMethod,
                },

                {
                  Header: "Azioni",
                  Cell: (rows) => {
                    return (
                      <div>
                        <DownloadOCRButton rows={rows} />
                        <DownloadPDFButton rows={rows} />
                        <Tooltip title="Download differenza">
                          <IconButton
                            disabled={isDownloading}
                            aria-label="differenza"
                            onClick={() =>
                              handleDownloadDiff(rows.row.original.id)
                            }
                          >
                            {isDownloading ? (
                              <CircularProgress size="18" />
                            ) : (
                              <CompareIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Download file validazione">
                          <IconButton
                            disabled={isDownloading}
                            onClick={() =>
                              handleDownloadZip(rows.row.original.id)
                            }
                          >
                            {isDownloading ? (
                              <CircularProgress size="18" />
                            ) : (
                              <CloudDownloadIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Elimina Documento">
                          <IconButton
                            aria-label="Elimina Documento"
                            onClick={() =>
                              handleOpenDeleteDialog(rows.row.original.id)
                            }
                          >
                            <Trash fontSize="small" color="secondary" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },
              ]}
            ></Table>
          </Scrollbar>
          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="delete-dialog"
          >
            <DialogContent>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography color="textPrimary" variant="body1">
                  Azione irreversibile, continuare?
                </Typography>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseDeleteDialog}
                color="primary"
                variant="contained"
                label="Annulla"
              />
              <Button
                onClick={() => deleteDocVal(docToDelete)}
                color="secondary"
                variant="contained"
                label="Elimina definitivamente"
              />
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PageDocValidation;
