import { createSlice } from '@reduxjs/toolkit'

const regoleSlice = createSlice({
  name: 'regole',
  initialState: {
    loaded: false,
    data: [],
  },
  reducers: {
    load: (state, action) => {
      state.loaded = true
      state.data = action.payload
    }
  }
})

export const actions = regoleSlice.actions;
export default regoleSlice.reducer;