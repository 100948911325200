import type { FC } from "react";
import { useEffect, useState, ChangeEvent } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box, Breadcrumbs,
    Container,
    Divider,
    Grid,
    Link,
    Typography,
    Tabs, Tab, Button, IconButton, CircularProgress,
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ChevronRightIcon from "../../icons/ChevronRight";
import { Document, LogDocument } from "src/types/generated";
import GraphqlClient from "../../client/graphql.client";
import { useSnackbar } from "notistack";
import DettagliGenerale from "./components/DettagliGenerale"
import DettagliFormData from "./components/DettagliFormData"
import DettagliRawText from "./components/DettagliRawText"
import { HandleDownloadDocument } from "./pagina.documenti"
import RegoleEseguite from "./components/RegoleEsegute";
import PaginaRegoleDocumenti from "./regole.documenti";
import DettaglioCodice from "./components/DettagliCodice";
import DettagliModifica from "./components/DettagliModifica";

const tabs = [
    { label: 'Generale', value: 'generale' },
    { label: 'Modifica Tag', value: 'modifica' },
    { label: 'Regole', value: 'regole' },
    { label: 'Log regole', value: 'regoleeseguite' },
    { label: 'Form Data', value: 'formdata' },
    { label: 'Tabelle', value: 'tables' },
    { label: 'Testo con posizione', value: 'textwithgeometry' },
    { label: 'Testo Estratto', value: 'rawtext' }
];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function insertUrlParam(key, value) {
    if (window.history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    }
}

const PaginaDettagliDocs: FC = (props) => {
    const { settings } = useSettings();
    const navigate = useNavigate();
    const query = useQuery();
    const [isDownloading, setIsDownloading] = useState(false)
    let tab = query.get('tab');

    const [currentTab, setCurrentTab] = useState(tab ? tab : 'generale');

    const [documento, setDocumento] = useState({} as Document)
    const [logs, setLogs] = useState([] as LogDocument[])

    const { ...other } = props;
    const params = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const [loadingData, setLoadingData] = useState(false);

    const id = params.docid
    const dossierid = params.id

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
        insertUrlParam('tab', value);
    };

    useEffect(() => {
        const caricaDocumento = async () => {
            try {
                setLoadingData(true)
                const res = await GraphqlClient.document({ id: id });
                setDocumento(res.document);
            } catch (e) {
                console.error('Impossibile ottenere documento: ', e);
                enqueueSnackbar("Impossibile ottenere il documento", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            } finally {
                setLoadingData(false);
            }
        }

        const caricaLogs = async () => {
            try {
                setLoadingData(true);
                const res = await GraphqlClient.getLogsByDocument({ document: id });
                setLogs(res.getLogsByDocument);
            } catch (e) {
                console.error('Impossibile ottenere logs: ', e);
                enqueueSnackbar("Impossibile ottenere i logs", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            } finally {
                setLoadingData(false);
            }
        }

        caricaDocumento();
        caricaLogs();
    }, [])

    return (
        <>
            <Helmet>
                <title>Documenti: Dettagli</title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >

                        <Grid item>
                            <Grid item>
                                <Typography
                                    color="textPrimary"
                                    variant="h5"
                                >
                                    {
                                        loadingData ? "Loading..." : (documento.description ?? documento.id)
                                    }
                                </Typography>
                            </Grid>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dossier"
                                    variant="subtitle2"
                                >
                                    Dossier
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to={"/dossier/dettagli/" + dossierid}
                                    variant="subtitle2"
                                >
                                    {dossierid}
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {documento.description}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Tabs
                            indicatorColor="primary"
                            onChange={handleTabsChange}
                            scrollButtons="auto"
                            textColor="primary"
                            value={currentTab}
                            variant="scrollable"
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                />
                            ))}
                            <IconButton
                                disabled={isDownloading}
                                aria-label='Download'
                                onClick={() => {
                                    HandleDownloadDocument({ 'id': id }, setIsDownloading)
                                }}
                            >
                                {isDownloading ? <CircularProgress size={18} /> : <CloudDownloadIcon fontSize="small" />}
                            </IconButton>
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 3 }}>
                        {currentTab === 'generale' && <DettagliGenerale documento={documento} logs={logs} loading={loadingData} />}
                        {currentTab === 'modifica' && <DettagliModifica {...documento} />}
                        {currentTab === 'formdata' && <DettagliFormData {...documento} {...props} />}
                        {currentTab === 'tables' && <DettaglioCodice json={true} code={documento.tables || ""} {...props} />}
                        {currentTab === 'textwithgeometry' && <DettaglioCodice json={true} code={documento.rawTextWithGeometry || ""} {...props} />}
                        {currentTab === 'rawtext' && <DettagliRawText {...documento} {...props} />}
                        {currentTab === 'regoleeseguite' && <RegoleEseguite id={id} />}
                        {currentTab === 'regole' && <PaginaRegoleDocumenti id={id} />}
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default PaginaDettagliDocs
