import type { FC } from "react";
import React, { useEffect, useState, ChangeEvent } from "react";
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box, Breadcrumbs,
  Container,
  Divider,
  Grid,
  Link,
  Card,
  CardContent,
  Typography,
  Tabs, Tab, Button, IconButton,
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Document, DocumentRule } from "src/types/generated";
import GraphqlClient from "../../../client/graphql.client";
import { useSnackbar } from "notistack";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const ResultPlayground: FC<{ results?: any }> = (results) => {
  const classes = useStyles();
  const [currentResultTab, setCurrentResultTab] = useState<string>("input0");

  const handleResultTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentResultTab(value);
  };

  const [parsedResults, setParsedResults] = useState([])

  useEffect(() => {
    setParsedResults(JSON.parse(results.results))
  }, [results])

  return (
    <Box m={1}>
      <Grid container>
        <Grid item xs={1}>
          <Tabs
            orientation="vertical"
            indicatorColor="primary"
            onChange={handleResultTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentResultTab}
            variant="scrollable"
            className={classes.tabs}
          >
            {parsedResults.map((obj) => (
              <Tab
                key={obj.input}
                label={obj.input}
                value={obj.input}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={11}>
          {parsedResults.map((obj) => (
            <>
              {currentResultTab === obj.input &&
                <Box m={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        RISULTATO
                      </Typography>
                      <Divider />
                      {obj.errors ?
                        <SyntaxHighlighter
                          customStyle={{ backgroundColor: 'transparent' }}
                          wrapLongLines={true}
                          wrapLines={true}
                          language="json"
                          style={atomOneDark}
                        >
                          {obj.errors}
                        </SyntaxHighlighter> :
                        <SyntaxHighlighter
                          customStyle={{ backgroundColor: 'transparent' }}
                          wrapLongLines={true}
                          wrapLines={true}
                          language="json"
                          style={atomOneDark}
                        >
                          {obj.result}
                        </SyntaxHighlighter>
                      }
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        CONSOLE.LOG
                      </Typography>
                      <Divider />
                      {obj.logs.map((log) => (
                        <SyntaxHighlighter
                          customStyle={{ backgroundColor: 'transparent' }}
                          wrapLongLines={true}
                          wrapLines={true}
                          language="json"
                          style={atomOneDark}
                        >
                          {log}
                        </SyntaxHighlighter>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              }
            </>
          ))
          }
        </Grid>
      </Grid>
    </Box>
  )
}

export default ResultPlayground