import store from "../../store";

import {actions} from './redux.tipodossier';
import GraphqlClient from "../../client/graphql.client";
import {NewDossierType} from "../../types/generated";

export const loadTypes = async () => {
  try {
    const res = await GraphqlClient.dossierTypes();
    store.dispatch(actions.load(res.dossierTypes))
  } catch (error) {
    return { loaded: false, message: error.toString() }
  }
};

export const newType = async (form: NewDossierType) => {
  try {
    const res = await GraphqlClient.createDossierType({input: form});
    store.dispatch(actions.created(res.createDossierType))
    return {data: res.createDossierType}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
};

export const loadType = async (name: string) => {
  try {
    const res = await GraphqlClient.dossierType({name: name})
    return {data: res.dossierType}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}