import { createSlice } from '@reduxjs/toolkit'

const docExecLogs = createSlice({
  name: 'docExecutionLogs',
  initialState: {
    loaded: false,
    data: [],
  },
  reducers: {
    load: (state, action) => {
        state.loaded = true
        state.data = action.payload
    }
  }
})

export const actions = docExecLogs.actions;
export default docExecLogs.reducer;