import { ChangeEvent, FC, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useSettings from "../../../hooks/useSettings";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Card,
  Grid,
  Link,
  IconButton,
  Typography,
  Tooltip,
  TextField,
  Tabs,
  Tab,
  Divider,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { useDebounce } from "use-lodash-debounce";

import {
  Table,
  SelectColumnFilter,
  SelectColumnFilterMethod,
} from "../../../components/Table/Table";
import Scrollbar from "../../../material/Scrollbar";
import ChevronRightIcon from "../../../icons/ChevronRight";
import PlusIcon from "../../../icons/Plus";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowRightIcon from "../../../icons/ArrowRight";
import { useSelector as useReduxSelector } from "../../../store";
import { loadRegoleDossier } from "../../Regole/commands.regole";
import { deleteDossierRule } from "../commands.play";
import DeleteDossierRuleDialog from "./delete.dialog";
import Label from "src/material/Label";
import Upload from "src/icons/Upload";
import ImportDossierRuleDialog from "./import.dialog";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import GraphqlClient from "src/client/graphql.client";
const PlaygroundDossier: FC = (props) => {
  const state = useReduxSelector((state) => state.regoleDossier);
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState<string>("generale");

  const { ...other } = props;

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  /* IMPORT DIALOG */
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const handleOpenImportDialog = () => {
    setOpenImportDialog(true);
  };

  const handleCloseImportDialog = () => {
    setOpenImportDialog(false);
  };

  const setActive = async (id: string, active) => {
    try {
      let res = await GraphqlClient.setDossierRuleActive({ id, active });
      loadRegoleDossier();
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar("Impossibile aggiornare stato", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  /* DELETE DIALOG */
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const handleOpenDeleteDialog = (id: string) => {
    setDeleteID(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteID("");
    setOpenDeleteDialog(false);
  };
  /* ------------- */

  useEffect(() => {
    loadRegoleDossier();
  }, []);

  return (
    <>
      <Helmet>
        <title>Playground </title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <DeleteDossierRuleDialog
          open={openDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          id={deleteID}
        />
        <ImportDossierRuleDialog
          open={openImportDialog}
          handleClose={handleCloseImportDialog}
        />
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Playground Dossier
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Playground Dossier
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  startIcon={<Upload fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={handleOpenImportDialog}
                >
                  Import ZIP
                </Button>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  to="/playground/dossier/nuova"
                >
                  Nuova regola
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Scrollbar>
                <Box>
                  <Table
                    header={
                     
                      <SearchComponent loading={state.loading} callback={(text) => {loadRegoleDossier(text)}}></SearchComponent>
                    }
                    Data={state.data}
                    tableName="dossierRules"
                    idDefaultColumnSort="description"
                    Columns={[
                      {
                        Header: "Description",
                        accessor: "description",
                        // filter: 'fuzzyText',
                      },
                      {
                        Header: "Istituto",
                        accessor: "istitutoAppartenenza",
                      },
                      {
                        Header: "Stato",
                        width: 80,
                        accessor: (row) =>
                          row.valid ? "VALIDA" : "NON VALIDA",
                        Cell: (row) => {
                          switch (row.value) {
                            case "VALIDA":
                              return <Label color="success">VALIDA</Label>;
                            case "NON VALIDA":
                              return <Label color="error">NON VALIDA</Label>;
                          }
                          return row.value;
                        },
                        Filter: SelectColumnFilter,
                        filter: SelectColumnFilterMethod,
                      },
                      {
                        Header: "Gruppo",
                        accessor: "gruppo",
                        id: "gruppo",
                        // disableSortBy: true,
                        Cell: (row) => {
                          if (row.row.original.gruppo != null) {
                            return (
                              <div>
                                {row.row.original.gruppo?.map((item) => {
                                  return (
                                    <Chip
                                      label={item}
                                      style={{ margin: "1px" }}
                                    />
                                  );
                                })}
                              </div>
                            );
                          } else {
                            return "";
                          }
                        },
                      },
                      {
                        Header: "Nota",
                        accessor: "nota",
                        id: "nota",
                        Cell: (row) => {
                          return (
                            <div
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row.row.original.nota}
                            </div>
                          );
                        },
                      },
                      {
                        Header: "Attiva",
                        width: 60,
                        accessor: (row) => (row.active ? "SI" : "NO"),
                        Cell: (row) => {
                          switch (row.value) {
                            case "SI":
                              return <Label color="success">SI</Label>;
                            case "NO":
                              return <Label color="error">NO</Label>;
                          }
                          return row.value;
                        },
                        Filter: SelectColumnFilter,
                        filter: SelectColumnFilterMethod,
                      },
                      {
                        Header: "Azioni",
                        width: 80,
                        Cell: (row) => {
                          return (
                            <>
                              {!row.row.original.active ? (
                                <Tooltip title={"Attive regola"}>
                                  <IconButton
                                    aria-label="Attiva regola"
                                    color="primary"
                                    onClick={() =>
                                      setActive(row.row.original.id, true)
                                    }
                                  >
                                    <PlayCircleOutlineIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title={"Metti regola in pausa"}>
                                  <IconButton
                                    aria-label="Metti regola in pausa"
                                    color="secondary"
                                    onClick={() =>
                                      setActive(row.row.original.id, false)
                                    }
                                  >
                                    <PauseCircleOutlineIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip title={"Elimina"}>
                                <IconButton
                                  aria-label="Elimina"
                                  color="secondary"
                                  onClick={() =>
                                    handleOpenDeleteDialog(row.row.original.id)
                                  }
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"Visualizza dettagli"}>
                                <IconButton
                                  aria-label="Dettagli"
                                  component={RouterLink}
                                  to={`/playground/dossier/${row?.row?.original?.id}/aggiorna`}
                                >
                                  <ArrowRightIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          );
                        },
                      },
                    ]}
                  />
                </Box>
              </Scrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const SearchComponent = ({ callback, loading }) => {
  const [text, setText] = useState("");
  const [init, setInit] = useState(false);
  const debouncedValue = useDebounce(text, 1000);

  useEffect(() => {
    if (!init){
      setInit(true);
      return;
    }
    callback(debouncedValue);
  }, [debouncedValue]);


  return (
    <TextField
      label="Cerca codice"
      fullWidth
      value={text}
      onChange={(e) => setText(e.target.value)}
      disabled={loading}
    />
  );
};

export default PlaygroundDossier;
