import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box, Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox, Container,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid, Link,
  TextField,
  Typography
} from '@material-ui/core';
import useSettings from "../../hooks/useSettings";
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import MonacoEditor from "react-monaco-editor";
import PlusIcon from "../../icons/Plus";
import { createDatatable } from './commands.datatables'
import { NewDataTable } from 'src/types/generated';

const NuovaDataTable: FC = (props) => {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const { ...other } = props;

  let [name, setName] = useState<string>('')
  let [content, setContent] = useState<string>(``)
  let [ordinamento, setOrdinamento] = useState('')

  const options = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    fixedOverflowWidgets: true
  };

  const handleInsert = async () => {
    try {
      JSON.parse(content)
    } catch (error) {
      enqueueSnackbar("Json non valido", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
    
    let validatedJson = JSON.parse(content)
    let escapedJson = JSON.stringify(validatedJson)
    let input: NewDataTable = { name: name, content: escapedJson, ordinamento: Number(ordinamento) }

    const res = await createDatatable(input)
    if (res.error && res.error == true) {
      console.error(res.message);
      enqueueSnackbar("Impossibile salvare la tabella", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    } else {
      enqueueSnackbar('Tabella inserita con successo', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
      navigate("/datatables")
    }
  }

    return (
      <>
        <Helmet>
          <title>Datatables: Nuova </title>
        </Helmet>

        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 8
          }}
        >
          <Container maxWidth={settings.compact ? 'xl' : false}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  variant="h5"
                >
                  Datatables
              </Typography>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRightIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                >
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/"
                    variant="subtitle2"
                  >
                    Dashboard
                </Link>
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/datatables"
                    variant="subtitle2"
                  >
                    Datatables
                </Link>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Nuova
                </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
              <Card>
                <CardContent>
                  <div style={{ minHeight: '600px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ justifyContent: 'space-between', marginBottom: '10px', display: 'flex' }}>
                      <TextField
                        label="Nome"
                        onChange={(e) => { setName(e.target.value) }}
                      />
                      <TextField
                        label="Ordinamento"                        
                        onChange={(e) => { setOrdinamento(e.target.value) }}
                      />

                      <Button
                        color="primary"
                        startIcon={<PlusIcon fontSize="small" />}
                        sx={{ m: 1 }}
                        variant="contained"
                        onClick={handleInsert}
                      >
                        Inserisci
                  </Button>
                    </div>
                    <div>
                      <MonacoEditor
                        height="600px"
                        language="json"
                        theme="vs-dark"
                        options={options}
                        value={content}
                        onChange={content => setContent(content)}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Box>
          </Container>
        </Box>
      </>
    )
  }

  export default NuovaDataTable