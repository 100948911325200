import store from "../../store";

import { actions } from './redux.datatables';
import GraphqlClient from "../../client/graphql.client";
import { NewDataTable, UpdateDataTable } from "../../types/generated";

export const loadDatatables = async () => {
  try {
    const res = await GraphqlClient.dataTables();
    store.dispatch(actions.load(res.dataTables))
  } catch (error) {
    return { loaded: false, message: error.toString() }
  }
};

export const createDatatable = async (input: NewDataTable) => {
  try {
    const res = await GraphqlClient.createDataTable({ input: input })
    return { data: res.createDataTable }
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const updateDatatable = async (name: string, input: UpdateDataTable) => {
  try {
    const res = await GraphqlClient.updateDataTable({name, input})
    return { data: res.updateDataTable }
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const deleteDatatable = async (name: string) => {
  try {
    const res = await GraphqlClient.deleteDataTable({name})
    return { data: res.deleteDataTable }
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}