import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  TextField,
} from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { LocalizationMap, Viewer, Worker,PdfJs } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import it_IT from "@react-pdf-viewer/locales/lib/it_IT.json";
import { truncate } from "lodash";
import React, { FC, useEffect, useState } from "react";
import GraphqlClient from "src/client/graphql.client";
import { MetasCheck, MetaValue } from "src/types/generated";

interface DetailsProps {
  dossierId: string;
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const PageConflitti: FC<DetailsProps> = ({ dossierId }: DetailsProps) => {
  const [conflicts, setConflicts] = useState({} as MetasCheck);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState(null);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [conflictsValue, setConflictsValue] = useState({});
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (!dossierId || dossierId.length == 0) return;
    loadConflicts();
    loadDocuments();
  }, [dossierId]);

  const loadDocument = async (documentId: string) => {
    setLoadingDocument(true);
    try {
      const res = await GraphqlClient.downloadDocument({ id: documentId });
      setDocument(res.downloadDocument);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingDocument(false);
    }
  };

  const loadConflicts = async () => {
    setLoading(true);
    try {
      const response = await GraphqlClient.metasCheck({ id: dossierId });
      setConflicts(response.metasCheck);
    } catch (e) {
      alert("Impossibile caricare conflitti");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const salvaMeta = async () => {
    setSaving(true);
    try {
      // Check if all metas are set
      let fieldsToCheck = conflicts?.conflicts.map((meta) => meta.name);
      fieldsToCheck = [
        ...fieldsToCheck,
        ...conflicts?.missingRequired.map((meta) => meta),
      ];
      let errs = {};

      fieldsToCheck.forEach((name) => {
        if (!conflictsValue[name]) {
          errs = { ...errs, [name]: "Manca il valore" };
        }
      });

      setErrors(errs);

      console.log("FIELDS TO CHECK", fieldsToCheck);
      console.log("ERRS", errs);

      if (Object.keys(errs).length > 0) {
        return;
      }

      let values = [] as MetaValue[];
      for (const key in conflictsValue) {
        if (conflictsValue.hasOwnProperty(key)) {
          const element = conflictsValue[key];
          values.push({
            name: key,
            value: element,
          });
        }
      }

      const response = await GraphqlClient.fixMetas({
        id: dossierId,
        metas: values,
      });
      if (response.fixMetas) {
        setConflictsValue({});
        setErrors({});
        loadConflicts();
      }
      setErrors(errs);
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const loadDocuments = async () => {
    try {
      const response = await GraphqlClient.documentsNameForDossier({
        id: dossierId,
      });
      setDocuments(response.documentsNameForDossier);
      if (response.documentsNameForDossier.length > 0) {
        loadDocument(response.documentsNameForDossier[0].id);
      }
    } catch (e) {
      alert("Impossibile caricare documenti");
      console.error(e);
    } finally {
    }
  };

  if (conflicts.valid) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "40px",
          color: "white",
        }}
      >
        Nessun conflitto nei meta dei documenti.
      </Box>
    );
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid
        xs={12}
        md={6}
        item
        sx={{ display: "flexe", alignItems: "flex-start" }}
      >

        {conflicts?.missingRequired?.length > 0 && (
          <Card>
            <CardHeader title="Meta OBBLIGATORI mancanti" />
            <Divider />
            <CardContent>
              {conflicts?.missingRequired
                ?.sort((e1, e2) => (e1 > e2 ? 1 : -1))
                .map((meta) => (
                  <TextField
                    fullWidth
                    error={Boolean(errors[meta] && errors[meta].length > 0)}
                    helperText={errors[meta] && "Campo necessario"}
                    sx={{ m: 1 }}
                    label={meta}
                    onChange={(e) => {
                      setConflictsValue({
                        ...conflictsValue,
                        [meta]: e.target.value,
                      });
                    }}
                    value={conflictsValue[meta] || ""}
                    variant="outlined"
                  />
                ))}
            </CardContent>
          </Card>
        )}

        {conflicts?.conflicts?.length > 0 && (
          <Card sx={{ mt: 2 }}>
            <CardHeader title="Meta che assumono valori discordanti" />
            <Divider />
            <CardContent>
              {conflicts?.conflicts
                ?.sort((e1, e2) => (e1.name > e2.name ? 1 : -1))
                .map((meta) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <TextField
                      fullWidth
                      error={Boolean(
                        errors[meta.name] && errors[meta.name].length > 0
                      )}
                      helperText={errors[meta.name] && "Campo necessario"}
                      sx={{ m: 1 }}
                      label={meta.name}
                      onChange={(e) => {
                        setConflictsValue({
                          ...conflictsValue,
                          [meta.name]: e.target.value,
                        });
                      }}
                      value={conflictsValue[meta.name] || ""}
                      variant="outlined"
                    />
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <table>
                            <tr>
                              <th>Valore</th>
                              <th>Occorrenze</th>
                            </tr>
                            {meta.conflicts.map((conflict) => (
                              <tr>
                                <td>{conflict.value}</td>
                                <td>{conflict.count}</td>
                              </tr>
                            ))}
                          </table>
                        </React.Fragment>
                      }
                    >
                      <InfoIcon />
                    </HtmlTooltip>
                  </Box>
                ))}
            </CardContent>
          </Card>
        )}

        <Grid xs={12} md={12} item>
          <Card sx={{ mt: 2, p: 2, display: "flex", justifyContent: "center" }}>
            {saving ? (
              <CircularProgress></CircularProgress>
            ) : (
              <Button
                onClick={() => salvaMeta()}
                fullWidth
                sx={{ p: 2 }}
                color="primary"
                variant="contained"
              >
                Salva meta
              </Button>
            )}
          </Card>
        </Grid>
      </Grid>
      {documents?.length > 0 ? (
        <Grid xs={12} md={6} item sx={{ color: "white", height: "100%" }}>
          <Autocomplete
            disabled={documents?.length === 0 || loadingDocument}
            onChange={(_, newValues: any) => {
              loadDocument(newValues.id);
            }}
            id="combo-box-documents"
            options={documents}
            value={document}
            getOptionLabel={(option) => option?.name}
            getOptionSelected={(option, value) => option?.id === value?.id}
            style={{ width: "100%", marginRight: "10px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Seleziona dossier"
                variant="outlined"
              />
            )}
          />
          {loadingDocument && <LinearProgress />}
          {document && !loadingDocument && (
            <DocumentViewer document={document}></DocumentViewer>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};
export default PageConflitti;

const DocumentViewer = React.memo((props: any) => {
  const { document } = props;
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const url = URL.createObjectURL(base64toBlob(document?.base64));
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          height: "650px",
          color: "black",
        }}
      >
        <Viewer
          plugins={[defaultLayoutPluginInstance]}
          localization={it_IT as unknown as LocalizationMap}
          fileUrl={url}
          transformGetDocumentParams={(options: PdfJs.GetDocumentParams) =>
            Object.assign({}, options, {
                disableRange: false,
                disableStream: false,
            })
        }
        />
      </div>
    </Worker>
  );
});

const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};
