import { FC, useEffect, useState } from 'react'
import { Document } from 'src/types/generated'
import {
  Box,
  Grid,
  Card,
  Typography,
  Divider,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core'
import Scrollbar from 'src/material/Scrollbar'
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { TextField } from 'src/components/TextField/TextField';
import Save from 'src/icons/Save';
import Refresh from 'src/icons/Refresh';
import MonacoEditor from 'react-monaco-editor';
import { updateMetas } from '../commands.documenti';
import { useSnackbar } from 'notistack';

const DettagliModifica: FC<Document> = (documento) => {
  const [tags, setTags] = useState("")
  const id = documento.id
  const { enqueueSnackbar } = useSnackbar()

  const options = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    fixedOverflowWidgets: true
  }

  const handleSubmit = async () => {
    const res = await updateMetas(id, tags)
    if (res.error) {
      enqueueSnackbar("Impossibile salvare i tag", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
      console.error(res.message)
      return
    }

    enqueueSnackbar("Tag aggiornati", {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      variant: 'success'
    });
  }

  const handleReset = () => {
    let temp = JSON.parse(documento.metas)
    setTags(JSON.stringify(temp, null, 2))
  }

  useEffect(() => {
    let temp = JSON.parse(documento.metas)
    setTags(JSON.stringify(temp, null, 2))
  }, [])

  return (
    <Card>
      <CardContent style={{ height: '500px' }}>
        <MonacoEditor
          height="98%"
          width="100%"
          language="json"
          theme="vs-dark"
          value={tags}
          options={options}
          onChange={code => setTags(code)}
        />
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          startIcon={<Save fontSize="small" />}
          sx={{ m: 1 }}
          variant="contained"
          onClick={() => handleSubmit()}
        >
          Salva
        </Button>
        <Button
          color="secondary"
          startIcon={<Refresh fontSize="small" />}
          sx={{ m: 1 }}
          variant="contained"
          onClick={() => handleReset()}
        >
          Reset
        </Button>
      </CardActions>
    </Card >
  )
}

export default DettagliModifica;
