import { LoadingButton } from '@material-ui/lab'
import { createSlice } from '@reduxjs/toolkit'

const regoleDossierSlice = createSlice({
  name: 'regoleDossier',
  initialState: {
    loaded: false,
    loading: false,
    data: [],
  },
  reducers: {
    load: (state, action) => {
      state.loading = false
      state.loaded = true
      state.data = action.payload
    },
    loading: (state)  => {
      state.loading = true
    },
    error: (state) => {
      state.loading = false
      state.data = []
    },
  }
})

export const actions = regoleDossierSlice.actions;
export default regoleDossierSlice.reducer;