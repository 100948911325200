import {
  Box, Breadcrumbs, Card, Container, Grid, IconButton, Link, Tooltip, Typography,
  Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField
} from '@material-ui/core';
import type { FC } from "react";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';

import ArrowRightIcon from '../../icons/ArrowRight';
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import Scrollbar from '../../material/Scrollbar';

import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from "notistack";

import { Button } from '../../components/Button/Button';
import Paper from '@material-ui/core/Paper';
import GraphqlClient from 'src/client/graphql.client';


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const PaginaNotifiche: FC = (props) => {
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { ...other } = props;

  const loadData = async () => {
    setLoading(true);
    try {
      const result = await GraphqlClient.notificationReceivers();
      setData(result.notificationReceivers);
    }
    catch (e) {
      enqueueSnackbar("Impossibile caricare le email", { variant: 'error' });
    }
    setLoading(false);

  }

  const deleteEmail = async email => {
    setLoading(true);
    try {
      await GraphqlClient.deleteNotificationReceiver({ receiver: email });
      enqueueSnackbar("Email eliminata", { variant: 'success' });
      loadData();
    }
    catch (e) {
      enqueueSnackbar("Impossibile eliminare l'email", { variant: 'error' });
    }
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <>
      <Helmet>
        <title>Notifiche</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Notifiche
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  notifiche
                </Typography>
              </Breadcrumbs>
            </Grid>

          </Grid>
          <Box sx={{ mt: 3, mb: 3 }}>
            <Box >
              <form onSubmit={async (e) => {
                e.preventDefault();

                // @ts-ignore
                const email = e.target.email?.value;

                if (!email) {
                  enqueueSnackbar("Inserisci un indirizzo email", { variant: "error" });
                  return;
                }

                setLoading(true);

                try {
                  const res = await GraphqlClient.addNotificationReceiver({ receiver: email });
                  setData(res.addNotificationReceiver);
                  // @ts-ignore
                  e.target.reset();
                } catch (e) {
                  enqueueSnackbar("Errore durante l'aggiunta dell'indirizzo email", { variant: "error" });
                } finally {
                  setLoading(false);
                }
              }}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <TextField label="Email" type="email" name="email" style={{ width: "100%" }} variant="outlined"
                  required />
                <Button
                  color="primary"
                  disabled={loading}
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1, minWidth: 200, minHeight: 55 }}
                  variant="contained"
                  type='submit'
                  label='Aggiungi'
                />
              </form>
            </Box>
            <Card {...other} sx={{ mt: 3, mb: 3 }}>
              <Scrollbar>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell align="right">Azioni</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="right">
                            <Button label="Elimina" variant="contained" color="secondary" onClick={() => deleteEmail(row.id)} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default PaginaNotifiche;
