import type { FC } from "react";
import { useEffect, useState } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box, Breadcrumbs, /* Button, */
  Container, Card,
  Grid,
  Link,
  IconButton,
  /* Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, */
  Typography,
} from '@material-ui/core';

import Scrollbar from '../../material/Scrollbar';
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import { useSelector as useReduxSelector } from '../../store'
import { loadTypes } from './commands.tipodossier'
import { DossierType } from "../../types/generated";
import dayjs from 'dayjs';

/*****************************************/
import Button_MUI from '@material-ui/core/Button';
import { Button, TextButton } from '../../components/Button/Button'
import { TextField } from '../../components/TextField/TextField'
import { Table, DatePickerRangeFilter, DatePickerRangeFilterMethod } from '../../components/Table/Table'
/*****************************************/
import moment from 'moment';


const PaginaDossierTypes: FC = (props) => {
  const state = useReduxSelector(state => state.dossiertypes.data)
  const { settings } = useSettings();

  const { ...other } = props;

  useEffect(() => {
    loadTypes();
  }, [])

  return (
    <>
      <Helmet>
        <title>Dossier </title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Tipologie Dossier
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Tipologie Dossier
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <RouterLink to={{
                  pathname: "/tipologie/dossier/nuova",
                }} style={{
                  textDecoration: 'none'
                }}>
                  <Button
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    label='Nuova tipologia'
                  />
                  {/* Nuova tipologia
                  </Button> */}
                </RouterLink>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Scrollbar>
                <Box sx={{ minWidth: 1200 }}>

                  <Table
                    Data={state}
                    Columns={[
                      {
                        Header: 'Nome',
                        accessor: 'name',
                      },
                      {
                        Header: 'Data creazione',
                        accessor: 'createdAt',
                        width: 300,
                        Cell: (row) => {
                          let date = moment(new Date(row.value)).format("DD/MM/YYYY");
                          return date
                        },
                        Filter : DatePickerRangeFilter,
                        filter: DatePickerRangeFilterMethod
                      },
                    ]}
                  />

                </Box>
              </Scrollbar>
            </Card >
          </Box >
        </Container >
      </Box >
    </>
  );
}

export default PaginaDossierTypes
