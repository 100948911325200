import { FC, useCallback, useRef } from "react";
import React, { useEffect, useState } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box, Breadcrumbs, Button,
    Container, Card, Chip,
    Grid,
    Link,
    IconButton,
    Typography,
    Tooltip,
    Collapse, CircularProgress,
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Scrollbar from '../../material/Scrollbar';
import ChevronRightIcon from "../../icons/ChevronRight";
import CodeIcon from "@material-ui/icons/Code"
import PlusIcon from "../../icons/Plus";
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import store, { useSelector as useReduxSelector } from '../../store'
import { deleteDossier, executionsReport, loadDossiers } from './commands.dossier'
import dayjs from 'dayjs';
import { downloadDossier } from "./commands.dossier";
import ArrowRightIcon from '../../icons/ArrowRight';
import {
    Table,
    SelectColumnFilter,
    SelectColumnFilterMethod,
    DatePickerRangeFilter,
    DatePickerRangeFilterMethod
} from '../../components/Table/Table'
import moment from 'moment';
import { useSnackbar } from "notistack";
import GraphqlClient from "src/client/graphql.client";
import CachedIcon from "@material-ui/icons/Cached";
import LoadingScreen from "src/material/LoadingScreen";
import Label from "src/material/Label";
import { fetchAsyncData } from "./redux.dossier";
import useToggle from "src/hooks/useToggle";

export const HandleDownloadDossier = async (id: { id: string }, isDownloadingState) => {
    isDownloadingState(true)
    const file = await downloadDossier(id)
    const bytes = atob(file.base64);
    let length = bytes.length;

    let out = new Uint8Array(length);
    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    let myBlob = new Blob([out], { type: "application/octet-stream" });

    const URLblobObj = window.URL.createObjectURL(myBlob);

    let a = document.createElement("a");
    a.style.display = "none";
    a.href = URLblobObj;
    a.download = file.filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(URLblobObj);
    isDownloadingState(false)
}

const DownloadPDFButton: FC<{ row: any }> = (props) => {
    const row = props.row;
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar()

    return (
        <>
            <Tooltip title={"Scarica dossier"}>
                <IconButton
                    disabled={isDownloading}
                    aria-label='Download ZIP'
                    onClick={() => {
                        HandleDownloadDossier({ 'id': row.row.values.id }, setIsDownloading)
                    }}
                >
                    {isDownloading ? <CircularProgress size={18} /> : <CloudDownloadIcon fontSize="small" />}
                </IconButton>
            </Tooltip>
        </>
    );
}

const PaginaDossier: FC = (props) => {
    const state = useReduxSelector(state => state.dossier)
    const { settings } = useSettings();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const { ...other } = props;

    const deleteEntry = async (id: string) => {
        try {
            let res = await deleteDossier(id)
            enqueueSnackbar("Dossier eliminato", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
        } catch (e) {
            console.error('Impossibile eliminare il dossier: ', e);
            enqueueSnackbar("Impossibile eliminare il dossier", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } finally {
            loadDossiers()
        }
    }


    const eseguiRegole = async (dossierId: string) => {
        try {
            const res = await GraphqlClient.runDossierRules({ id: dossierId })
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Impossibile eseguire le regole", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return
            // Notification
        } finally {
            enqueueSnackbar("Esecuzione avviata", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
        }
    }

    const fetchIdRef = useRef(0)
    const [refresh, toggleRefresh] = useToggle(false)
    const fetchData = useCallback((records, page, orderBy, filters, selectOptions) => {
        const fetchId = ++fetchIdRef.current
        const sumColumns = []
        const avgColumns = []

        if (fetchId === fetchIdRef.current) {
            store.dispatch(fetchAsyncData({ records, page, orderBy, filters, selectOptions, sumColumns, avgColumns }))
        }
    }, [])

    useEffect(() => {
        if (state.error != null) {
            console.log(state.error)
            enqueueSnackbar('Errore', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    }, [state.error])


    useEffect(() => {
        const interval = setInterval(() => {
            toggleRefresh();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    // useEffect(() => {
    //     loadDossiers();
    // }, [])


    return (
        <>
            <Helmet>
                <title>Dossier </title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Dossier
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Dossier
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <IconButton
                                    color="primary"
                                    onClick={() => toggleRefresh()}
                                >
                                    {loading ?
                                        <CircularProgress color={"primary"} size={24} /> :
                                        <CachedIcon fontSize="small" />}
                                </IconButton>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<PlusIcon fontSize="small" />}
                                    sx={{ m: 1 }}
                                    variant="contained"
                                    to="/dossier/import"
                                >
                                    Importa dossier
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Card {...other}>
                            <Scrollbar>
                                <Box sx={{ minWidth: 1200 }}>
                                    <Table
                                        fetchData={fetchData}
                                        refresh={refresh}
                                        pageCount={state.pages}
                                        loading={false}
                                        selectFilterColumns={['state']} // nome della colonna su Postgres su cui applicare il filtro select
                                        selectFilterOptions={[
                                            {
                                                column: 'state',
                                                options: ['processed', 'processing', 'cannot process']
                                            }
                                        ]}
                                        idDefaultColumnSort="createdAt"
                                        Data={state.data ?? []}
                                        Columns={[
                                            {
                                                Header: 'ID',
                                                accessor: 'id',
                                                disableFilters: true,
                                                disableSortBy: true
                                            },
                                            {
                                                Header: 'Stato',
                                                accessor: 'state',
                                                Cell: (row) => {
                                                    switch (row.value) {
                                                        case "processing":
                                                            return (
                                                                <Label color="warning">
                                                                    <CircularProgress
                                                                        classes={{ colorPrimary: "white" }}
                                                                        size={13} />
                                                                    <div style={{ marginLeft: "10px" }}>IN CORSO</div>
                                                                </Label>
                                                            )
                                                        case "processed":
                                                            return <Label color="success">IMPORTATO</Label>
                                                            case "conflicts":
                                                                return <Label color="warning">CONFLITTI</Label>
                                                        case "cannot process":
                                                            return <Label color="error">ERRORE</Label>
                                                    }
                                                    return row.value;
                                                },
                                                id: 'state',
                                                disableSortBy: true,
                                                Filter: SelectColumnFilter,
                                            },
                                            // {
                                            //     Header: 'Tipo',
                                            //     accessor: 'dossierTypeName',
                                            //     id: 'dossier_type_name',
                                            //     disableSortBy: true,
                                            //     Filter: SelectColumnFilter,
                                            //     filter: SelectColumnFilterMethod,
                                            // },
                                            {
                                                Header: 'Data Caricamento',
                                                accessor: 'createdAt',
                                                width: 300,
                                                Cell: (row) => {
                                                    let date = moment(new Date(row.value)).format("DD/MM/YYYY HH:mm");
                                                    return date
                                                },
                                                Filter: DatePickerRangeFilter,
                                            },
                                            {
                                                Header: 'Azioni',
                                                width: 160,
                                                Cell: (row) => {
                                                    return (
                                                        <>
                                                            <Tooltip title={"Elimina"}>
                                                                <IconButton
                                                                    aria-label='Elimina'
                                                                    color="secondary"
                                                                    onClick={() => deleteEntry(row.row.values.id)}
                                                                >
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={"Esegui regole"}>
                                                                <IconButton
                                                                    aria-label='Esegui'
                                                                    onClick={() => {
                                                                        eseguiRegole(row.row.values.id);
                                                                    }}
                                                                >
                                                                    <CodeIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>

                                                            <DownloadPDFButton row={row} />

                                                            <Tooltip title={"Visualizza dettagli"}>
                                                                <IconButton
                                                                    aria-label='Dettagli'
                                                                    component={RouterLink}
                                                                    to={`/dossier/dettagli/${row.row.values.id}`}
                                                                >
                                                                    <ArrowRightIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    )
                                                }
                                            }
                                        ]}

                                    />

                                </Box>
                            </Scrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    );
}

export default PaginaDossier;