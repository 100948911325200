import type { FC } from "react";
import { useEffect, useState, ChangeEvent } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box, Breadcrumbs,
    Container,
    Divider,
    Grid,
    Link,
    Typography,
    Tabs, Tab, IconButton, CircularProgress, Tooltip,
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ChevronRightIcon from "../../icons/ChevronRight";
import { Callback, Dossier, LogDossier } from "src/types/generated";
import GraphqlClient from "../../client/graphql.client";
import { useSnackbar } from "notistack";
import DettagliGenerale from "./components/DettagliGenerale"
import { HandleDownloadDossier } from "./pagina.dossier"
import PaginaDocumenti from "../documenti/pagina.documenti";
import LoadingScreen from "src/material/LoadingScreen";
import LogRegole from "./components/LogRegole";
import VariabiliInput from "./components/VariabiliInput";
import StatusEsecuzione from "./components/StatusEsecuzione";
import RisultatiAggregati from "./components/RisultatiAggregati";
import PageConflitti from "./components/Conflitti";

const tabs = [
    { label: 'Generale', value: 'generale' },
    { label: 'Documenti', value: 'documenti' },
    { label: 'Conflitti', value: 'conflicts' },
    { label: 'Stato esecuzione regole', value: 'status' },
    { label: 'Risultato regole aggregato', value: 'results' },
    { label: 'Variabili di input', value: 'input' },
    { label: 'Logs', value: 'logregole' },
];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function insertUrlParam(key, value) {
    if (window.history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
    }
}

const PaginaDettagliDossier: FC = (props) => {
    const { settings } = useSettings()
    const [dossier, setDossier] = useState({} as Dossier)
    const [callbacks, setCallbacks] = useState([] as Callback[])
    const [isDownloading, setIsDownloading] = useState(false)

    const { ...other } = props;
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [loadingData, setLoadingData] = useState(false);

    const id = params.id

    const query = useQuery();
    let tab = query.get('tab');

    const [currentTab, setCurrentTab] = useState<string>(tab ? tab : 'generale');
    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
        insertUrlParam('tab', value);
    };

    useEffect(() => {
        const caricaDossier = async () => {
            try {
                setLoadingData(true)
                const res = await GraphqlClient.dossier({ id: id });
                setDossier(res.dossier);
            } catch (e) {
                console.error('Impossibile ottenere dossier: ', e);
                enqueueSnackbar("Impossibile ottenere il dossier", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            } finally {
                setLoadingData(false);
            }
        }

        const caricaCallbacks = async () => {
            try {
                const res = await GraphqlClient.dossierCallbacks({ id });
                setCallbacks(res.dossierCallbacks);
            } catch (e) {
                console.error('Impossibile ottenere callback: ', e);
                enqueueSnackbar("Impossibile ottenere le callback", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }
        

        caricaDossier();
        caricaCallbacks();
    }, [])

    return (
        <>
            <Helmet>
                <title>Dossier: Dettagli</title>
            </Helmet>


            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >

                        <Grid item>
                            <Grid item>
                                <Typography
                                    color="textPrimary"
                                    variant="h5"
                                >
                                    {
                                        loadingData ? "Loading..." : (dossier.description ?? dossier.id)
                                    }
                                </Typography>
                            </Grid>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dossier"
                                    variant="subtitle2"
                                >
                                    Dossier
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {dossier.description ?? dossier.id}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Tabs
                            indicatorColor="primary"
                            onChange={handleTabsChange}
                            scrollButtons="auto"
                            textColor="primary"
                            value={currentTab}
                            variant="scrollable"
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                />
                            ))}
                            <Box>
                                <Tooltip title="Download Dossier">
                                    <IconButton
                                        disabled={isDownloading}
                                        aria-label='Download'
                                        onClick={() => {
                                            HandleDownloadDossier({ 'id': id }, setIsDownloading)
                                        }}
                                    >
                                        {isDownloading ? <CircularProgress size={18} /> : <CloudDownloadIcon fontSize="small" />}
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 3 }}>
                        {currentTab === 'generale' && <DettagliGenerale dossier={dossier} callbacks={callbacks} loading={loadingData} />}
                        {currentTab === 'documenti' && <PaginaDocumenti id={dossier?.id} {...props} />}
                        {currentTab === 'logregole' && <LogRegole id={dossier?.id} {...props} />}
                        {currentTab === 'input' && <VariabiliInput input={dossier?.input} {...props} />}
                        {currentTab === 'status' && <StatusEsecuzione dossierID={dossier?.id} {...props} />}
                        {currentTab === 'results' && <RisultatiAggregati dossierID={dossier?.id} {...props} />}
                        {currentTab === 'conflicts' && <PageConflitti dossierId={dossier?.id} {...props} />}
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default PaginaDettagliDossier
