import type { FC } from "react";
import { useEffect, useState } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box, Breadcrumbs, Button,
    Container, Card, Chip,
    Grid,
    Link,
    IconButton,
    /*   Table,
      TableBody,
      TableCell,
      TableHead,
      TableRow, */
    Typography,
    Tooltip,
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Scrollbar from '../../material/Scrollbar';
import ChevronRightIcon from "../../icons/ChevronRight";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightIcon from '../../icons/ArrowRight';

import { useSelector as useReduxSelector } from '../../store'
import { loadRegole } from './commands.regole'
import dayjs from 'dayjs';

/*****************************************/
import { Table,SelectColumnFilter, SelectColumnFilterMethod } from '../../components/Table/Table'
/*****************************************/
import moment from 'moment';

const PaginaRegole: FC = (props) => {
    const state = useReduxSelector(state => state.regole)
    const { settings } = useSettings();

    const { ...other } = props;

    useEffect(() => {
        loadRegole();
    }, [])

    console.log(state)

    return (
        <>
            <Helmet>
                <title>Regole </title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Regole
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Regole
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Card {...other}>
                            <Scrollbar>
                                <Box sx={{ minWidth: 1200 }}>


                                    <Table
                                        Data={state.data}
                                        Columns={[
                                            {
                                                Header: 'ID',
                                                accessor: 'id',
                                                disableFilters:true
                                            },
                                            {
                                                Header: 'Descrizione',
                                                accessor: 'description',
                                            },
                                            {
                                                Header: 'Stato',
                                                accessor: 'state',
                                                Cell: (rows) => {
                                                    if (rows.value === null)
                                                        return '-'
                                                    return rows.value
                                                },
                                                id: 'state',
                                                Filter: SelectColumnFilter,
                                                filter: SelectColumnFilterMethod,
                                            },
                                            {
                                                Header: 'Tipologie',
                                                accessor: 'documentType.name',
                                                id: 'type',
                                                Filter: SelectColumnFilter,
                                                filter: SelectColumnFilterMethod,
                                            },

                                            {
                                                Header: 'Azioni',
                                                width: 60,
                                                Cell: (rows) => {                                                    
                                                    return (
                                                        <div>
                                                            <Tooltip title="Dettagli">
                                                                <IconButton
                                                                    aria-label='Dettagli'
                                                                    component={RouterLink}
                                                                    to={`/regole/dettagli/${rows.row.values.id}`}
                                                                >
                                                                    <ArrowRightIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    )
                                                }
                                            },
                                        ]}
                                    />


                                </Box>
                            </Scrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    );
}

export default PaginaRegole;
