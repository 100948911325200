// @ts-nocheck
import MonacoEditor from "react-monaco-editor";
import { FC, useState, useEffect, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ClearIcon from '@material-ui/icons/Clear';
import Collapse from "@kunukn/react-collapse";
import TerminalIcon from "../../../icons/Terminal";
import SaveIcon from '@material-ui/icons/Save';
import { updateDocumentRule } from '../commands.play'
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import DownloadB64 from "../../../utils/downloadB64";

import {
  /* Autocomplete ,*/
  Button,
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
  Divider,
  Grid,
  List, ListItem, ListItemText,
  Tab, Tabs,
  TextField,
  Tooltip,
  CircularProgress,
  Typography,
  Box,
  IconButton,
  Card,
  CardContent,
  Container,
} from '@material-ui/core';

import GraphqlClient from 'src/client/graphql.client';
import { useSnackbar } from 'notistack';
import { CodeResponse, DataTable, Document, DocumentRule, DocumentType, DossierType } from 'src/types/generated';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { dataTables } from "src/graphql/generated/queries";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { minHeight, width } from "@material-ui/system";
import { loadRegole } from "src/pages/Regole/commands.regole";
import { Autocomplete } from "src/components/Autocomplete/Autocomplete";
import RegoleEsegute from "src/pages/documenti/components/RegoleEsegute";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'rgb(30,30,30)',
    position: 'relative',
    overflow: 'auto',
    maxHeight: '200px',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  button: {
    margin: theme.spacing(1),
  },
  clearBtn: {
    color: '#546e7a'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  btnConsole: {
    borderRadius: 3,
  }
}));

const editorTabs = [
  { label: 'Codice', value: 'codice' },
  { label: "helper.js", value: "helper" },
  { label: 'package.json', value: 'package' },
  { label: 'Risultato', value: 'result' },
  { label: 'Log Esecuzione', value: 'log' },
  { label: 'Modifica', value: 'edit' },
];

const PlaygroundAggiornaDocRule: FC = (props) => {
  const [currentEditorTab, setCurrentEditorTab] = useState<string>('codice');

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [showConsole, setShowconsole] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openDownload, setOpenDownload] = useState(false);

  const handleClickOpenDownload = () => {
    setOpenDownload(true);
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
  };

  const params = useParams()

  const id = params.id

  let [code, setCode] = useState<string>("");
  let [packagecode, setPackageCode] = useState<string>("");

  const options = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    fixedOverflowWidgets: true
  };

  let [types, setTypes] = useState<DocumentType[]>([] as DocumentType[])
  let [selectedType, setSelectedType] = useState<DocumentType>({} as DocumentType);
  let [name, setName] = useState<string>('')
  let [nota, setNota] = useState("")
  let [datatables, setDatatables] = useState<DataTable[]>([] as DataTable[])
  let [selectedDatatables, setSelectedDatatables] = useState<DataTable[]>([] as DataTable[])
  let [documents, setDocuments] = useState<Document[]>([] as Document[])
  let [selectedDocument, setSelectedDocument] = useState<Document>({} as Document)
  let [helperCode, setHelperCode] = useState<string>("");

  let [loadingRule, setLoadingRule] = useState(false)
  let [loadingRunRule, setLoadingRunRule] = useState(false)

  let defaultResponse = {
    Result: "",
    executionId: "",
    logs: [""],
    errorDescription: "",
  }

  let [response, setResponse] = useState(defaultResponse)

  let [rule, setRule] = useState<DocumentRule>();
  const [loadingDocs, setLoadingDocs] = useState(false);

  const handleEditorTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentEditorTab(value);
  };

  const HandleDownloadRule = async (documentID: string, code: string, packagecode: string, datatables: DataTable[]) => {
    try {
      setLoadingRunRule(true);
      const res = await GraphqlClient.downloadDocumentRule({
        id: documentID,
        ruleId: rule.id,
      })

      DownloadB64(res.downloadDocumentRule.name, res.downloadDocumentRule.bsae64Content);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Impossibile scaricare playground", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    } finally {
      setLoadingRunRule(false);
      handleCloseDownload()
      setCurrentEditorTab('result')
    }
  }

  const HandleRunRule = async (documentID: string, code: string, packagecode: string, datatables: DataTable[]) => {
    try {
      setLoadingRunRule(true);
      let datatableNames: string[]
      if (datatables) {
        datatableNames = datatables.map(dt => dt.name)
      } else {
        datatableNames = null
      }
      const res = await GraphqlClient.runTemporaryRule({
        id: documentID,
        ruleID: rule.id,
        ruleName: rule.description,
        code: code,
        packagecode: packagecode,
        dataTables: datatableNames
      })
      let result = JSON.parse(res.runTemporaryRule)
      setResponse(result)
      console.log(result, res.runTemporaryRule)
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Impossibile eseguire la regola", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
      // Notification
    } finally {
      setLoadingRunRule(false);
      handleClose()
      setCurrentEditorTab('result')
    }
  }

  const HandleRuleUpdate = async (
    newcode: string,
    packagecode: string,
    dataTables: DataTable[],
    name: string,
    docType: DocumentType,
    nota: string
  ) => {

    try {
      await GraphqlClient.updateHelperCode({ id: "document", code: helperCode });
    } catch (e) {
      console.log("helper code update error", e);
      enqueueSnackbar("Impossibile salvare helper.js", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }


    var datatables = dataTables?.map(function (o) {
      return o.name
    });

    try {
      let res = await updateDocumentRule(rule.id, {
        description: name,
        code: newcode,
        packageJSON: packagecode,
        dataTables: datatables,
        documentType: selectedType.name,
        nota: nota
      })
      enqueueSnackbar("Regola salvata con successo", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
      loadRegole();
    } catch (e) {
      console.error('Impossibile salvare la rule: ', e);
      enqueueSnackbar("Impossibile salvare la rule", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  }

  useEffect(() => {
    const fetchRule = async (id: string) => {
      try {
        setLoadingRule(true)
        const res = await GraphqlClient.documentRule({ id: id });
        setRule(res.documentRule);
        setCode(res.documentRule.code)
        setSelectedDatatables(res.documentRule.dataTables)
        setPackageCode(res.documentRule.packageJSON)
        setSelectedType(res.documentRule.documentType)
        setName(res.documentRule.description)
        setNota(res.documentRule.nota)
      } catch (e) {
        enqueueSnackbar("Impossibile ottenere la regola", {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      } finally {
        setLoadingRule(false)
      }
    }

    const loadTypes = async () => {
      try {
        const res = await GraphqlClient.documentTypes()
        setTypes(res.documentTypes)
      } catch (e) {
        console.error('Impossibile ottenere tipologie: ', e);
        enqueueSnackbar("Impossibile ottenere le tipologie", {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      }
    }

    const loadDocuments = async () => {
      setLoadingDocs(true);
      try {
        const res = await GraphqlClient.documentsInfo()
        setDocuments(res.documentsInfo)
        setSelectedDocument(res.documentsInfo[0])
      } catch (e) {
        console.error('Impossibile ottenere tipologie: ', e);
        enqueueSnackbar("Impossibile ottenere le tipologie", {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      } finally {
        setLoadingDocs(false);
      }
    }

    const loadDatatables = async () => {
      try {
        const res = await GraphqlClient.dataTables()
        setDatatables(res.dataTables)
      } catch (e) {
        console.error('Impossibile ottenere datatables: ', e);
        enqueueSnackbar("Impossibile ottenere le datatables", {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      }
    }

    const loadHelperCode = async () => {
      try {
        const res = await GraphqlClient.getHelperCode({ id: "document" });
        setHelperCode(res.getHelperCode.code);
      } catch (e) {
        console.error("Impossibile ottenere helper code: ", e);
        enqueueSnackbar("Impossibile ottenere helper code", {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "error",
        });
      }
    }

    loadHelperCode();

    fetchRule(id)
    loadTypes()
    loadDatatables()
    loadDocuments()
  }, [])

  /* NOTA DIALOG */
  const [openNotaDialog, setOpenNotaDialog] = useState(false);

  const handleClickOpenNotaDialog = () => {
    setOpenNotaDialog(true);
  };

  const handleCloseNotaDialog = () => {
    setOpenNotaDialog(false);
  };
  /* ------------- */

  // @ts-ignore
  return (
    <>
      <Helmet><title>Playground documenti</title></Helmet>
      <div style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}>
        <div style={{
          padding: 10,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '70%',
          }}>


            <Card style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%' }}>
              <CardContent>
                <Typography style={{ display: 'inline-block', width: '15%', color: 'grey' }} color='default' variant="subtitle1" >
                  Nome regola:
                </Typography>
                <Typography style={{ display: 'inline-block', width: '80%', }} variant="h6" >
                  {name}
                </Typography>

              </CardContent>
            </Card>


            {/* <TextField
              variant="outlined"
              color="primary"
              label="Nome"
              value={name}
              onChange={e => { setName(e.target.value) }}
              style={{ minWidth: 500, marginRight: '10px', marginBottom: '10px' }}
            />
            <Autocomplete
              onChange={(_, newValues: DocumentType) => {
                setSelectedType(newValues)
              }}
              id="combo-box-tables"
              options={types}
              value={selectedType}
              getOptionLabel={(option) => option?.name}
              getOptionSelected={(option, value) => option?.name === value?.name}
              style={{ width: 300, marginRight: '10px', marginBottom: '10px' }}
              renderInput={(params) => <TextField {...params} label="Seleziona tipologia"
                variant="outlined" />}
            />
            <Autocomplete
              multiple
              onChange={(_, newValues: DataTable[]) => {
                setSelectedDatatables(newValues)
              }}
              id="combo-box-tables"
              options={datatables}
              value={selectedDatatables || []}
              getOptionLabel={(option) => option?.name}
              getOptionSelected={(option, value) => option?.name === value?.name}
              style={{ minWidth: 300, maxWidth: 500, marginRight: '10px', marginBottom: '10px' }}
              renderInput={(params) => <TextField {...params} label="Seleziona datatable"
                variant="outlined" />}
            />
            <TextField
              variant="outlined"
              color="primary"
              label="Nota"
              value={nota}
              onChange={e => { setNota(e.target.value) }}
              style={{ minWidth: 500, marginRight: '10px', marginBottom: '10px' }}
            />
            <Tooltip title={"Espandi nota"}>
              <IconButton
                aria-label='Espandi'
                color='primary'
                onClick={() => { setOpenNotaDialog(true) }}
              >
                <NoteAddIcon fontSize="small" />
              </IconButton>
            </Tooltip> */}

            <Dialog
              open={openNotaDialog}
              onClose={handleCloseNotaDialog}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle id="form-dialog-title">Nota</DialogTitle>
              <DialogContent>
                <TextField
                  label="Contenuto"
                  value={nota}
                  fullWidth
                  minRows={5}
                  multiline
                  onChange={(e) => { setNota(e.target.value) }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseNotaDialog} color="primary">
                  Chiudi
                </Button>
                <Button onClick={handleCloseNotaDialog} color="primary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Tooltip title={"Ripristina le modifiche effettuate"}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                startIcon={<RotateLeftIcon />}
                onClick={() => {
                  setName(rule.description)
                  setCode(rule.code)
                  setPackageCode(rule.packageJSON)
                  setSelectedDatatables(rule.dataTables)
                  setSelectedType(rule.documentType)
                }}
              >
                Reset
              </Button>
            </Tooltip>
            <Tooltip title={"Salva le modifiche effettuate permanentemente"}>
              <Button
                variant="outlined"
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={() => {
                  HandleRuleUpdate(code, packagecode, selectedDatatables, name, selectedType, nota)
                }}
              >
                Salva
              </Button>
            </Tooltip>
            <Tooltip title={"Scarica playground regola"}>
              <Button
                variant="outlined"
                className={classes.button}
                startIcon={<CloudDownloadIcon />}
                onClick={handleClickOpenDownload}
              >
                Playground
              </Button>
            </Tooltip>
            <Tooltip title={"Esegui la regola su un documento"}>
              <Button
                variant="outlined"
                className={classes.button}
                startIcon={<PlayCircleOutlineIcon />}
                onClick={handleClickOpen}
              >
                Esegui
              </Button>
            </Tooltip>
            <Dialog open={openDownload} onClose={handleCloseDownload} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">SELEZIONA DOCUMENTO</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Scarica il playground di una regola per uno specifico documento
                </DialogContentText>
                <Autocomplete
                  disabled={loadingRunRule}
                  onChange={(_, newValues: Document) => {
                    setSelectedDocument(newValues)
                  }}
                  id="combo-box-documents"
                  options={documents}
                  value={selectedDocument}

                  getOptionLabel={(option) => option?.description}
                  getOptionSelected={(option, value) => option?.description === value?.description}
                  groupBy={(option) => option.dossier.description || option.dossier.id}
                  style={{ width: '100%', marginRight: '10px' }}
                  renderInput={(params) => loadingDocs ? <CircularProgress /> : <TextField {...params} label="Seleziona documento"
                    variant="outlined" />}
                />
              </DialogContent>
              <DialogActions>
                {
                  loadingRunRule ? <CircularProgress /> :
                    <>
                      <Button disabled={loadingRunRule} onClick={handleClose} color="primary">
                        Indietro
                      </Button>
                      <Button disabled={loadingRunRule} onClick={() => HandleDownloadRule(selectedDocument.id, code, packagecode, selectedDatatables)} color="primary" variant="contained">
                        Download
                      </Button>
                    </>
                }
              </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">SELEZIONA DOCUMENTO</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Per eseguire la regola, seleziona un documento su cui applicarla.
                </DialogContentText>
                <Autocomplete
                  disabled={loadingRunRule}
                  onChange={(_, newValues: Document) => {
                    setSelectedDocument(newValues)
                  }}
                  id="combo-box-documents"
                  options={documents}
                  value={selectedDocument}
                  filterOptions={(options, state) => {
                    return options.filter((option) => (option.description + option?.dossier?.id).toLowerCase().includes(state.inputValue.toLowerCase()));
                  }}
                  getOptionLabel={(option) => `${option?.description}`}
                  getOptionSelected={(option, value) => option?.description === value?.description}
                  groupBy={(option) => option.dossier.description || option.dossier.id}
                  style={{ width: '100%', marginRight: '10px' }}
                  renderInput={(params) => loadingDocs ? <CircularProgress /> : <TextField {...params} label="Seleziona documento"
                    variant="outlined" />}
                />
              </DialogContent>
              <DialogActions>
                {
                  loadingRunRule ? <CircularProgress /> :
                    <>
                      <Button disabled={loadingRunRule} onClick={handleClose} color="primary">
                        Indietro
                      </Button>
                      <Button disabled={loadingRunRule} onClick={() => HandleRunRule(selectedDocument.id, code, packagecode, selectedDatatables)} color="primary" variant="contained">
                        Esegui
                      </Button>
                    </>
                }
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <Tabs
          indicatorColor="primary"
          onChange={handleEditorTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentEditorTab}
          variant="scrollable"
        >
          {editorTabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
        {currentEditorTab === 'codice' &&
          <div style={{ position: 'relative', flex: 1, background: 'rgb(30,30,30)' }}>
            <Divider style={{ margin: 3 }}></Divider>

            <MonacoEditor
              height="98%"
              width="100%"
              language="javascript"
              theme="vs-dark"
              value={code}
              options={options}
              onChange={code => setCode(code)}
            />
          </div>}
        {currentEditorTab === "helper" && (
          <div
            style={{
              position: "relative",
              flex: 1,
              background: "rgb(30,30,30)",
            }}
          >
            <Divider style={{ margin: 3 }} />

            <MonacoEditor
              height="98%"
              width="100%"
              language="javascript"
              theme="vs-dark"
              value={helperCode}
              options={options}
              onChange={(code) => setHelperCode(code)}
            />
          </div>
        )}
        {currentEditorTab === 'package' &&
          <div style={{ position: 'relative', flex: 1, background: 'rgb(30,30,30)' }}>
            <Divider style={{ margin: 3 }}></Divider>

            <MonacoEditor
              height="98%"
              width="100%"
              language="json"
              theme="vs-dark"
              value={packagecode}
              options={options}
              onChange={packagecode => setPackageCode(packagecode)}
            />
          </div>}
        {currentEditorTab === 'result' &&
          <Box m={1}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  RISULTATO
                </Typography>
                <Divider />
                {response?.errorDescription ?
                  <SyntaxHighlighter
                    customStyle={{ backgroundColor: 'transparent' }}
                    wrapLongLines={true}
                    wrapLines={true}
                    language="json"
                    style={atomOneDark}
                  >
                    {response?.errorDescription}
                  </SyntaxHighlighter> :
                  <SyntaxHighlighter
                    customStyle={{ backgroundColor: 'transparent' }}
                    wrapLongLines={true}
                    wrapLines={true}
                    language="json"
                    style={atomOneDark}
                  >
                    {response.Result && JSON.stringify(JSON.parse(response.Result), null, 2)}
                  </SyntaxHighlighter>
                }
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  CONSOLE.LOG
                </Typography>
                <Divider />
                {response.logs?.map((log) => (
                  <SyntaxHighlighter
                    customStyle={{ backgroundColor: 'transparent' }}
                    wrapLongLines={true}
                    wrapLines={true}
                    language="json"
                    style={atomOneDark}
                  >
                    {log}
                  </SyntaxHighlighter>
                ))}
              </Grid>
            </Grid>
          </Box>}
        {currentEditorTab === 'log' && <RegoleEsegute ruleID={id} {...props} />}
        {currentEditorTab === 'edit' &&
          <div>
            <Container /* maxWidth={settings.compact ? 'xl' : false} */>
              <Box sx={{ mt: 3 }}>
                <Card /* {...other}  */ style={{ height: '100%' }}>
                  <CardContent style={{ height: '100%' }}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      style={{ marginBottom: '10px' }}
                    >
                      Informazioni
                    </Typography>
                    <Grid container spacing={1} justifyContent="center">
                      <Grid item xs={12} lg={6} style={{ padding: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                          <TextField
                            variant="outlined"
                            color="primary"
                            label="Nome"
                            value={name}
                            onChange={e => { setName(e.target.value) }}
                            style={{ width: '100%', marginRight: '10px', marginBottom: '10px' }}
                          />

                        </div>
                      </Grid>
                      <Grid item xs={12} lg={6} style={{ padding: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                          <Autocomplete
                            onChange={(_, newValues: DocumentType) => {
                              setSelectedType(newValues)
                            }}
                            id="combo-box-tables"
                            options={types}
                            value={selectedType}
                            getOptionLabel={(option) => option?.name}
                            getOptionSelected={(option, value) => option?.name === value?.name}
                            style={{ width: '100%', marginRight: '10px', marginBottom: '10px' }}
                            renderInput={(params) => <TextField {...params} label="Seleziona tipologia"
                              variant="outlined" />}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={6} style={{ padding: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                          <Autocomplete
                            style={{ width: '100%', marginRight: '10px', marginBottom: '10px' }}
                            multiple
                            onChange={(_, newValues: DataTable[]) => {
                              setSelectedDatatables(newValues)
                            }}
                            id="combo-box-tables"
                            options={datatables}
                            value={selectedDatatables || []}
                            getOptionLabel={(option) => option?.name}
                            getOptionSelected={(option, value) => option?.name === value?.name}

                            renderInput={(params) => <TextField {...params} label="Seleziona datatable"
                              variant="outlined" />}
                          />

                        </div>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: '5px' }}>
                          <TextField
                            variant="outlined"
                            color="primary"
                            label="Nota"
                            value={nota}
                            onChange={e => { setNota(e.target.value) }}
                            style={{ width: '100%', marginRight: '10px', marginBottom: '10px' }}
                          />
                          <Tooltip title={"Espandi nota"}>
                            <IconButton
                              aria-label='Espandi'
                              color='primary'
                              onClick={() => { setOpenNotaDialog(true) }}
                            >
                              <NoteAddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>

                        </div>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Container>
          </div>
        }
      </div>
    </>
  );
};

export default PlaygroundAggiornaDocRule;
