import store from "../../store";
import { actions } from "./redux.docvalidazione";
import GraphqlClient from "../../client/graphql.client";

export const uploadValidationFile = async (fileName: string, input: string, istitutoAppartenenza: string) => {
    try {
        const res = await GraphqlClient.uploadRuleValidation({ fileName: fileName, file: input, istitutoAppartenenza });
        return { data: res.uploadRuleValidation };
    } catch (err) {
        return { error: true, message: err.toString() }
    }
};

export const getValidations = async () => {
    try {
        const res = await GraphqlClient.ruleValidations({})
        return { data: res.ruleValidations }
    } catch (err) {
        return { error: true, message: err.toString() }
    }
}

export const fetchValidationDocs = async () => {
    try {
        const res = await GraphqlClient.docValidations();
        store.dispatch(actions.load(res.docValidations));
    } catch (err) {
        return { error: true, message: err.toString() };
    }
};

export const removeValidationDocs = async (input: string[]) => {
    try {
        const res = await GraphqlClient.deleteDocValidations({ ids: input });
        return { data: res.deleteDocValidations };
    } catch (err) {
        return { error: true, message: err.toString() };
    }
};

export const downloadText = async (input: string) => {
    try {
        const res = await GraphqlClient.downloadOCR({ id: input })
        return { data: res.downloadOCR };
    } catch (err) {
        return { error: true, message: err.toString() };
    }
}

export const downloadSource = async (input: string) => {
    try {
        const res = await GraphqlClient.downloadPDF({ id: input })
        return { data: res.downloadPDF };
    } catch (err) {
        return { error: true, message: err.toString() };
    }
}

export const deleteDocValidation = async (id: string) => {
    try {
        const res = await GraphqlClient.deleteDocValidations({ ids: [id] })
        return { data: res.deleteDocValidations };
    } catch (err) {
        return { error: true, message: err.toString() };
    }
}

export const runFromDoc = async (id: string) => {
    try {
        const res = await GraphqlClient.runValidation({ idDocValidation: id })
        return { data: res.runValidation };
    } catch (err) {
        return { error: true, message: err.toString() };
    }
}

export const fetchValidationLogs = async (id: string) => {
    try {
        const res = await GraphqlClient.logValidazione({ idValidazione: id })
        return { data: res.logValidazione };
    } catch (err) {
        return { error: true, message: err.toString() };
    }
}

export const diff = async (id: string) => {
    try {
        const res = await GraphqlClient.downloadDiff({ id })
        return { data: res.downloadDiff }
    } catch (err) {
        return { error: true, message: err.toString() };
    }
}

export const requestPutSignedURL = async (filename: string) => {
    try {
        const res = await GraphqlClient.requestSignedURLForValidationDocument({ filename })
        return { data: res.requestSignedURLForValidationDocument }
    } catch (err) {
        return { error: true, message: err.toString() };
    }
}

export const uploadWithSignedURL = async (signedURL: string, file: any) => {
    try {
        const res = fetch(signedURL, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': "application/octet-stream"
            }
        })
        return { data: res }
    } catch (err) {
        return { error: true, message: err };
    }
}

export const createRuleValidation = async (name: string, url: string, istitutoAppartenenza: string, documentID?: string) => {
    try {
        const res = await GraphqlClient.createRuleValidation({ documentID, name, url, istitutoAppartenenza })
        return { data: res.createRuleValidation }
    } catch (err) {
        return { error: true, message: err.toString() };
    }
}