import {FC} from 'react'
import {Document} from 'src/types/generated'
import {
    Box,
    Grid,
    Card,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Divider,
    CardContent,
    TableBody,
    TableFooter
} from '@material-ui/core'
import Scrollbar from 'src/material/Scrollbar'
import SyntaxHighlighter from "react-syntax-highlighter";
import {atomOneDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';

const DettaglioCodice: FC<{ code?: string, json?: boolean }> = ({code, json}) => {
    return (
        <Grid
            container
            spacing={3}
        >
            <Grid item style={{flex: '1'}}>
                <Box p={2}>
                    <SyntaxHighlighter customStyle={{backgroundColor: 'transparent'}} wrapLongLines={true}
                                       wrapLines={true} language="javascript" style={atomOneDark}>
                        {
                            code && code.length > 0 ? (
                                json ? JSON.stringify(JSON.parse(code), null, 4) : code) : ""
                        }
                    </SyntaxHighlighter>
                </Box>
            </Grid>
        </Grid>
    )
}

export default DettaglioCodice;
