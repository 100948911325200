
 const DownloadB64 = async (name, content) => {
    const bytes = atob(content);
    let length = bytes.length;
  
    let out = new Uint8Array(length);
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
  
  
    let myBlob = new Blob([out], { type: "application/octet-stream" });
  
    const URLblobObj = window.URL.createObjectURL(myBlob);
  
    let a = document.createElement("a");
    a.style.display = "none";
    a.href = URLblobObj;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(URLblobObj);
  }  

  export default DownloadB64;