import type { FC } from "react";
import { useEffect, useState } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box, Breadcrumbs,
  Container, Card,
  Grid,
  Dialog, DialogActions, DialogContent, DialogContentText,
  Link,
  IconButton,
  Typography,
  Tooltip,
  DialogTitle,
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Scrollbar from '../../material/Scrollbar';
import ChevronRightIcon from "../../icons/ChevronRight";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightIcon from '../../icons/ArrowRight';
import EditIcon from '@material-ui/icons/Edit';
import PlusIcon from "../../icons/Plus";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { useSelector as useReduxSelector } from '../../store'
import { createDatatable, deleteDatatable, loadDatatables, updateDatatable } from './commands.datatables'
import dayjs from 'dayjs';
import { dataTable } from "src/graphql/generated/queries";
import { DataTable, NewDataTable, UpdateDataTable } from "src/types/generated";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useSnackbar } from "notistack";
import Papa from "papaparse";
import DeleteIcon from '@material-ui/icons/Delete';
import { Table, SelectColumnFilter, SelectColumnFilterMethod, DatePickerRangeFilter, DatePickerRangeFilterMethod } from '../../components/Table/Table'
import PencilAltIcon from '../../icons/PencilAlt';

/*********************/
import { Button } from '../../components/Button/Button'
import { TextField } from '../../components/TextField/TextField'
import { GraphQLClient } from "graphql-request";
/*********************/


const PaginaDatatables: FC = (props) => {
  const state = useReduxSelector(state => state.datatables)
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  const { ...other } = props;

  const [openDialog, setOpenDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [toDelete, setToDelete] = useState("")
  const [csvToUpload, setCsvToUpload] = useState(null)
  const [csvName, setCsvName] = useState<string>(null)

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCsvToUpload(null);
    setCsvName(null);
  };

  const handleOpenDeleteDialog = (name: string) => {
    setOpenDeleteDialog(true)
    setToDelete(name)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
    setToDelete("")
  }

  const deleteEntry = async (name: string) => {
    const res = await deleteDatatable(name)
    if (res.error) {
      handleCloseDeleteDialog()
      enqueueSnackbar("Impossibile eliminare la datatable", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
      console.error(res.message)
      return
    }

    handleCloseDeleteDialog()
    loadDatatables()
    enqueueSnackbar("Datatable eliminata", {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      variant: 'success'
    });
  }

  const handleUpload = () => {
    if (!csvName || !csvToUpload) {
      enqueueSnackbar("Tutti i campi sono obbligatori", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      })
      return
    }

    Papa.parse(csvToUpload, {
      header: true,
      complete: async function (results, file) {
        let input: NewDataTable = { name: csvName, content: JSON.stringify(results.data), ordinamento: 0 }
        const res = await createDatatable(input)
        if (res.error && res.error == true) {
          console.error(res.message);
          enqueueSnackbar(res.message, {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: 'error'
          });
        } else {
          enqueueSnackbar('Tabella inserita con successo', {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: 'success'
          });
          handleCloseDialog()
        }
      },
      error: function (error, file) {
        console.log(error)
        enqueueSnackbar('File CSV non valido', {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      }
    })
  }

  useEffect(() => {
    loadDatatables();
  }, [])

  /* Update DIALOG */
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  let [dataTableSelected, setDataTableSelected] = useState("");
  let [contentSelected, setContentSelected] = useState("");
  //let [newNameTable, setNewNameTable] = useState(""); //non serve piu perche non aggiorno piu il nome
  let [newOrdinamentoTable, setNewOrdinamentoTable] = useState(null);
  

  const handleClickOpenUpdateDialog = (id, ordinamento, content) => {
    setNewOrdinamentoTable(ordinamento) //lo setto con il vecchio 
    setDataTableSelected(id);
    setContentSelected(content);
    setOpenUpdateDialog(true);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  };

  const aggiornaDataTable = async () => {
    let input = { name: dataTableSelected, ordinamento: newOrdinamentoTable, content: contentSelected } as UpdateDataTable
    const res = await updateDatatable(dataTableSelected, input)
    if (res.error) {
      enqueueSnackbar('Aggiornamento dataTable non riuscito', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
      console.error(res.error)
      return
    }
    handleCloseDeleteDialog()
    loadDatatables()
    enqueueSnackbar("Datatable aggiornata", {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      variant: 'success'
    });
    console.log(newOrdinamentoTable, dataTableSelected, contentSelected)
    setOpenUpdateDialog(false) //finito
  }
  /* ------------- */

  return (
    <>
      <Helmet>
        <title>Datatables </title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Datatables
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Datatables
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  to="/datatables/nuova"
                  label='Nuova tabella'
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} aria-labelledby="delete-dialog">
              <DialogContent>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Azione irreversibile, continuare?
                  </Typography>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary" variant="contained" label='Annulla' />
                <Button onClick={() => deleteEntry(toDelete)} color="secondary" variant="contained" label='Elimina definitivamente' />
              </DialogActions>
            </Dialog>
            <Card {...other}>
              <Scrollbar>
                <Box sx={{ minWidth: 1200 }}>
                  <Table
                    Data={state?.data}
                    idDefaultColumnSort="ordinamento"
                    Columns={[
                      {
                        Header: 'Nome',
                        accessor: 'name',
                      },
                      {
                        Header: 'Azioni',
                        width: 30,
                        Cell: (row) => {
                          return (
                            <>
                              <Tooltip title={"Elimina"}>
                                <IconButton
                                  aria-label='Elimina'
                                  color="secondary"
                                  onClick={() => handleOpenDeleteDialog(row.row.values.name)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"Modifica"}>
                                <IconButton
                                  aria-label='Modifica'
                                  onClick={() => { handleClickOpenUpdateDialog(row.row.original.name, row.row.original.ordinamento, row.row.original.content) }}
                                >
                                  <PencilAltIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"Visualizza dettagli"}>
                                <IconButton
                                  aria-label='Dettagli'
                                  component={RouterLink}
                                  to={`/datatables/dettagli/${row.row.values.name}`}
                                >
                                  <ArrowRightIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          )
                        }
                      }
                    ]}
                  />
                  <Dialog
                    open={openUpdateDialog}
                    onClose={handleCloseUpdateDialog}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'sm'}
                  >
                    <DialogTitle id="form-dialog-title">DATA TABLE</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Modifica Datatable: {dataTableSelected}
                      </DialogContentText>
                      {/* <TextField
                        label="Nome"
                        value={newNameTable}
                        fullWidth
                        onChange={(e) => { setNewNameTable(e.target.value) }}
                      /> */}
                      <TextField
                        label="Ordinamento"
                        value={newOrdinamentoTable}
                        fullWidth
                        type='number'
                        onChange={(e) => { setNewOrdinamentoTable(e.target.value) }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseUpdateDialog} color="primary" label="Chiudi" />
                      <Button onClick={() => aggiornaDataTable()} color="primary" label="Aggiorna" />
                    </DialogActions>
                  </Dialog>
                </Box>
              </Scrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default PaginaDatatables;
