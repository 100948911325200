import store from "../../store";

import { actions } from './redux.dossier';
import GraphqlClient from "../../client/graphql.client";
import { Exact, TableInput } from "src/types/generated";

export const loadDossiers = async () => {
  try {
    const res = await GraphqlClient.dossiers();
    store.dispatch(actions.load(res.dossiers))
  } catch (error) {
    return { loaded: false, message: error.toString() }
  }
};

export const downloadDossier = async (id: Exact<{ id: string; }>) => {
  try {
    const res = await GraphqlClient.downloadDossier(id);
    return {
      filename: res.downloadDossier.filename,
      extension: res.downloadDossier.extension,
      base64: res.downloadDossier.base64,
    };
  } catch (error) {
    return { loaded: false, message: error.toString() }
  }
};

export async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    body: JSON.stringify(data)
  });
  return response;
}

export const deleteDossier = async (id: string) => {
  try {
    const res = await GraphqlClient.deleteDossier({ id: id });
    return { data: res.deleteDossier }
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const executionsReport = async (id: string) => {
  try {
    const res = await GraphqlClient.getDossierRulesExecutionCount({ dossierID: id })
    return { data: res.getDossierRulesExecutionCount }
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const fetchAggregateResults = async (id: string) => {
  try {
    const res = await GraphqlClient.getDossierResultAggregate({ dossierID: id })
    return { data: res.getDossierResultAggregate }
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const fetchExecutionsForDossier = async (id: string) => {
  try {
    const res = await GraphqlClient.getDossierRuleExecutionsByDossier({ dossierID: id })
    return { data: res.getDossierRuleExecutionsByDossier }
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const countFailedExecutions = async (id: string) => {
  try {
    const res = await GraphqlClient.getFailedDossierRuleExecs({ dossierID: id })
    return { data: res.getFailedDossierRuleExecs }
  } catch (err) {
    return { error: true, message: err.toString() }
  }
}