import { FC, useEffect, useState } from 'react'
import { DossierRule, DocValidation } from 'src/types/generated'
import { useSnackbar } from 'notistack'
import GraphqlClient from 'src/client/graphql.client'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../../hooks/useSettings';
import { useSelector as useReduxSelector } from '../../../store'
import {
    Autocomplete,
    Box, Breadcrumbs, Button,
    Container, Card, Chip,
    Grid,
    Link,
    IconButton,
    Typography,
    CardContent,
    TextField, Divider,
} from '@material-ui/core';

import ChevronRightIcon from "../../../icons/ChevronRight";
import PlusIcon from "../../../icons/Plus";
import { newDossierRule, testNewRule } from '../commands.play';
import Minus from 'src/icons/Minus';
import { fetchValidationDocs } from 'src/pages/validazione/commands.validazione';
import ChipInput from 'material-ui-chip-input';

const defaultCode = `/*
    @LoanManager
    La variabile "data" contiene:
    {
        metas // metatags dei documenti associati al dossier nel formato {"idDocumento": {"chiave":"valore"}}
        input // variabili di input
    }

    Per resituire un errore utilizzare una tra:
    - console.errore("errore qua...") // in questo caso il codice prosegue l'esecuzione
    - throw("erroe qua") // stoppa anche l'esecuzione del codice
*/

export default (data) => {
    /* Codice regola */
    console.log('Metatags: ', JSON.stringify(data.metas));
    console.log('Variabili in esecuzione: ', JSON.stringify(data.input));
    return {
        success: true 
    }
}`;

const defaultPackageJSON = `{
    "name": "rule",
    "version": "1.0.0",
    "main": "index.js",
    "license": "MIT",
    "type": "module"
}`

const PlaygroundNuovaDossierRule: FC = (props) => {
    const { settings } = useSettings()
    const [loadingData, setLoadingData] = useState(false);
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const validationDocs = useReduxSelector(state => state.docValidation)

    const [name, setName] = useState<string>("")
    const [mustContain, setMustContain] = useState({ values: [""] })
    const [notContain, setNotContain] = useState({ values: [""] })
    const [validate, setValidate] = useState({} as DocValidation)
    const [nota, setNota] = useState("")
    const [gruppo, setGruppo] = useState([])
    const [istitutoAppartenenza, setIstitutoAppartenenza] = useState<string>("")

    const field = (target) => (
        target.values.map((el, i) =>
            <div key={i} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', padding: '15px' }}>
                <TextField
                    value={el || ''}
                    onChange={target == mustContain ? handleMustContainChange.bind(i) : handleNotContainChange.bind(i)}
                    multiline
                    required
                    rows={2}
                    label={target == mustContain ? 'Deve contenere...' : 'Non deve contenere...'}
                    style={{ width: '70%' }}
                />
            </div>
        ))

    function handleMustContainChange(event) {
        let values = [...mustContain.values];
        values[this] = event.target.value;
        setMustContain({ values: values })
    }

    function handleNotContainChange(event) {
        let values = [...notContain.values];
        values[this] = event.target.value;
        setNotContain({ values: values })
    }

    const addClick = (target, dispatch) => {
        dispatch({ values: [...target.values, ''] })
    }

    const removeClick = (target, dispatch) => {
        let vals = [...target.values];
        vals.splice(this, 1);
        dispatch({ values: vals });
    }

    let returnUpdateValue: DossierRule

    const { ...other } = props

    const handleSubmit = async (name: string) => {
        if (!name) {
            enqueueSnackbar("Nome regola obbligatorio", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return;
        }

        if (validate != null && validate != undefined && Object.keys(validate).length !== 0) {
            const isValid = await testNewRule(validate.id, mustContain.values, notContain.values)
            if (isValid.error) {
                enqueueSnackbar("Errore durante la validazione", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
                console.error(isValid.message)
                return;
            }

            if (isValid.data) {
                enqueueSnackbar("Regola valida", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
            } else {
                enqueueSnackbar("Regola non valida", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
                return;
            }
        }

        let res = await newDossierRule({
            istitutoAppartenenza: istitutoAppartenenza,
            dossierType: null,
            description: name,
            code: defaultCode,
            dataTables: [],
            packageJSON: defaultPackageJSON,
            mustContain: mustContain.values,
            notContain: notContain.values,
            nota: nota,
            gruppo: gruppo,
        })
        if (res.error) {
            console.error(res.error);
            enqueueSnackbar("Impossibile salvare la regola", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return
        }

        returnUpdateValue = res.data
        enqueueSnackbar("Regola salvata con successo", {
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
            },
            variant: 'success'
        });

        navigate(`/playground/dossier/${res.data.id}/aggiorna`)
    }

    useEffect(() => {
        fetchValidationDocs();
    }, [])

    return (
        <>
            <Helmet>
                <title>Nuova regola </title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Nuova regola
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/playground/dossier"
                                    variant="subtitle2"
                                >
                                    Playground Dossier
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Nuova regola
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Card {...other} style={{ height: '100%' }}>
                            <CardContent style={{ height: '100%' }}>
                                <Typography
                                    color="textPrimary"
                                    variant="h6"
                                >
                                    Informazioni
                                </Typography>
                                <Grid container spacing={1} justifyContent="center">
                                    <Grid item xs={12} lg={6} style={{ padding: 10 }}>
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                            <TextField
                                                required
                                                value={name}
                                                onChange={e => {
                                                    setName(e.target.value)
                                                }}
                                                label="Nome regola"
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={6} style={{ padding: 10 }}>
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                            <TextField
                                                required
                                                value={istitutoAppartenenza}
                                                onChange={e => {
                                                    setIstitutoAppartenenza(e.target.value)
                                                }}
                                                label="Istituto di appartenenza"
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={6} style={{ padding: 10 }}>
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                            <TextField
                                                //required
                                                value={nota}
                                                onChange={e => {
                                                    setNota(e.target.value)
                                                }}
                                                label="Nota regola"
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: '5px' }}>
                                            <Autocomplete
                                                onChange={(_: any, newValue: DocValidation) => {
                                                    setValidate(newValue);
                                                }}
                                                id="combo-box-validation"
                                                options={validationDocs.data ?? []}
                                                getOptionLabel={(option) => option.description}
                                                getOptionSelected={(option, value) => option?.description === value?.description}
                                                style={{ width: "100%", marginRight:'10px' }}
                                                
                                                renderInput={(params) => <TextField {...params}
                                                    label="Seleziona file di validazione"
                                                    variant="outlined" />}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: '5px' }}>
                                            <ChipInput
                                                value={gruppo}
                                                onAdd={(chip) => {
                                                    gruppo.push(chip)
                                                }}
                                                onDelete={(chip, index) => {
                                                    gruppo.splice(index, 1)
                                                }}
                                                fullWidth
                                                label="Gruppo"
                                                variant="outlined"
                                                style={{ /* minWidth: 500, maxWidth: 500, */ marginRight: '10px', marginBottom: '10px' }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Divider />
                                <Typography
                                    color="textPrimary"
                                    variant="h6"
                                >
                                    Validazione regola
                                </Typography>
                                <Grid container spacing={1} justifyContent="center">
                                    <Grid item xs={6}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            flexDirection: 'row'
                                        }}>
                                            <IconButton
                                                onClick={() => removeClick(mustContain, setMustContain)}
                                            >
                                                <Minus />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => addClick(mustContain, setMustContain)}
                                            >
                                                <PlusIcon />
                                            </IconButton>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignContent: 'center',
                                            flexDirection: 'column'
                                        }}>
                                            {field(mustContain)}
                                        </div>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            flexDirection: 'row'
                                        }}>
                                            <IconButton
                                                onClick={() => removeClick(notContain, setNotContain)}
                                            >
                                                <Minus />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => addClick(notContain, setNotContain)}
                                            >
                                                <PlusIcon />
                                            </IconButton>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignContent: 'center',
                                            flexDirection: 'column'
                                        }}>
                                            {field(notContain)}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider />

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    padding: '25px'
                                }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            handleSubmit(name)
                                        }}
                                        style={{ width: 200 }}
                                    >
                                        Salva
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default PlaygroundNuovaDossierRule
