import store from "../../store";

import {actions} from './redux.tipodoc';
import GraphqlClient from "../../client/graphql.client";
import {NewDocumentType} from "../../types/generated";

export const loadTypes = async () => {
  try {
    const res = await GraphqlClient.documentTypes();
    store.dispatch(actions.load(res.documentTypes))
  } catch (error) {
    return { loaded: false, message: error.toString() }
  }
};

export const newType = async (form: NewDocumentType) => {
  try {
    const res = await GraphqlClient.createDocumentType({input: form});
    store.dispatch(actions.created(res.createDocumentType))
    return {data: res.createDocumentType}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
};

export const loadType = async (name: string) => {
  try {
    const res = await GraphqlClient.documentType({name: name})
    return {data: res.documentType}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const deleteDocType = async (name: string) => {
  try {
    const res = await GraphqlClient.deleteDocumentType({name: name})
    return {data: res.deleteDocumentType}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}