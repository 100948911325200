// @ts-nocheck

import {useRef, useState} from 'react';
import type {FC} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';

import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography
} from '@material-ui/core';

import CogIcon from '../../icons/Cog';
import UserIcon from '../../icons/User';
import {useSelector} from "../../store";
import {logout} from "../../pages/login/commands.login";
import {useTranslation} from "react-i18next";

const AccountPopover: FC = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const {user} = useSelector(state => state.auth)
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = useState<boolean>(false);
    const {t} = useTranslation();

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = async (): Promise<void> => {
        try {
            handleClose();
            await logout();
            navigate('/');
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Unable to logout', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    };

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex'
                }}
            >
                <Avatar
                    src={"TODO"}
                    sx={{
                        height: 32,
                        width: 32
                    }}
                />
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom'
                }}
                getContentAnchorEl={null}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {width: 240}
                }}
            >
                <Box sx={{p: 2}}>
                    <Typography
                        color="textPrimary"
                        variant="subtitle2"
                    >
                        {user?.firstName + " " + user?.lastName || ""}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="subtitle2"
                    >
                        {user?.email}
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={{mt: 2}}>
                    <MenuItem
                        component={RouterLink}
                        to="/account"
                    >
                        <ListItemIcon>
                            <CogIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={(
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    {t("settings")}
                                </Typography>
                            )}
                        />
                    </MenuItem>
                </Box>
                <Box sx={{p: 2}}>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={handleLogout}
                        variant="outlined"
                    >
                        {t("logout")}

                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default AccountPopover;
