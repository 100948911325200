import { FC, useEffect, useState } from 'react'
import { Document, LogDocument } from 'src/types/generated'
import {
    Box,
    Grid,
    Card,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Divider,
    CardContent,
    TableBody,
    TableFooter,
    CardHeader,
    Skeleton
} from '@material-ui/core'
import Scrollbar from 'src/material/Scrollbar'
import GraphqlClient from 'src/client/graphql.client'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom'

interface DetailsProps {
    documento: Document,
    logs: LogDocument[],
    loading: Boolean
}

const DettagliGenerale: FC<DetailsProps> = ({ documento, logs, loading }: DetailsProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid item>
                <Card>
                    <CardHeader
                        title="Dettagli"
                    />
                    <Divider />
                    <CardContent>
                        <Table>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="h6"
                                    >
                                        Descrizione
                                    </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {
                                        loading ? <Skeleton width="361px" height="31px" /> :
                                            <Typography
                                                color="textPrimary"
                                                variant="h5"
                                            >
                                                {documento.description}
                                            </Typography>
                                    }
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="h6"
                                    >
                                        Dossier di appartenenza
                                    </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {
                                        loading ? <Skeleton width="361px" height="31px" /> :
                                            <Typography
                                                color="textPrimary"
                                                variant="h5"
                                            >
                                                {documento.dossier?.description ?? documento.dossier?.id}
                                            </Typography>
                                    }

                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="h6"
                                    >
                                        Tipologia
                                    </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {
                                        loading ? <Skeleton width="361px" height="31px" /> :
                                            <Typography
                                                color="textPrimary"
                                                variant="h5"
                                            >
                                                {documento.documentType?.name ?? "---"}
                                            </Typography>
                                    }

                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="h6"
                                    >
                                        Stato processazione
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="h6"
                                    >
                                        Errore
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {
                                        loading ? <Skeleton width="361px" height="31px" /> :
                                            <Typography
                                                color="textPrimary"
                                                variant="h5"
                                            >
                                                {documento.state}
                                            </Typography>
                                    }
                                </TableCell>
                            </TableRow>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card>
                    <CardHeader
                        title="Logs"
                    />
                    <Divider />
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            Evento
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            Timestamp
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    loading ?
                                        <TableRow>
                                            <TableCell>
                                                <Skeleton width="228px" height="20px" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton width="228px" height="20px" />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        logs?.map((entry) => (
                                            <TableRow key={entry.id}>
                                                <TableCell>
                                                    {loading ? <Skeleton width="228px" height="20px" /> : entry.event}
                                                </TableCell>
                                                <TableCell>
                                                    {loading ? <Skeleton width="228px" height="20px" /> : dayjs(entry.timestamp).format('DD/MM/YYYY HH:mm:ss')}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                }
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default DettagliGenerale
