import React, {useEffect, useState} from "react";
import {Can} from "../../pages/login/authorizer";
import {useSelector} from "../../store";


export interface PermissionProps {
    children?: React.ReactNode;
    resource?: string;
    action?: string;
}

// @ts-ignore
export const PermissionGuard: React.FC<PermissionProps> = ({children, resource, action}) => {
    let utente = useSelector(state => state.auth.user);

    let [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        const checkRole = async () => {
            let can = await Can(action, resource)
            setHasAccess(can);
        }

        checkRole();
    }, [utente])

    if (hasAccess) {
        return children;
    }

    return null;
}

export default PermissionGuard;
