import store from "../../store";

import {actions} from './redux.regole';
import {actions as dossierActions} from './redux.regoleDossier';
import GraphqlClient from "../../client/graphql.client";

export const loadRegole = async () => {
    try {
        const res = await GraphqlClient.documentRules();
        store.dispatch(actions.load(res.documentRules))
    } catch (error) {
        return {loaded: false, message: error.toString()}
    }
};

export const loadRegoleDossier = async (testoDaCercare?: string) => {
    try {
        store.dispatch(dossierActions.loading())

        const res = await GraphqlClient.dossierRules({
            codeToSearch: testoDaCercare
        });
        store.dispatch(dossierActions.load(res.dossierRules))
    } catch (error) {
        store.dispatch(dossierActions.error())

        return {loaded: false, message: error.toString()}
    }
};

export const loadRegola = async (input: string) => {
    try {
        const res = await GraphqlClient.documentRule({id: input})
        return {data: res.documentRule}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};

export const deleteDocumentRule = async (input: string) => {
    try {
        const res = await GraphqlClient.deleteDocumentRule({id: input})
        return {data: res.deleteDocumentRule}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
}
