import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    it: {
        translation: {
            "notifications":"Notifiche",
            'loginHeader': 'Benvenuto',
            'loginDescription': 'Accedi alla piattaforma',
            'loginBtn': 'Accedi',
            'logout': 'Disconnetti',
            'demoLabel': 'Utilizzare',
            'forgotPwd': 'Password dimenticata',
            "requiredField": "Campo obbligatorio",
            "notValidEmail": "Email non valida",
            "profile": "Account",
            "accountGeneral": "Generale",
            "accountSecurity": "Sicurezza",
            "setAvatar": "Imposta avatar",
            "removeAvatar": "Rimuovi avatar",
            "profileT": "Profilo",

            "firstName": "Nome",
            "lastName": "Cognome",
            "save": "Salva",

            "changePwd": "Cambia password",
            "oldPwd": "Vecchia password",
            "password": "Password",
            "confirmPassword": "Conferma password",
            "pwdMismatch": "Le password devono coincidere",
            "pwdUpdated": "Password aggiornata con successo",
            "minLen7": "Lunghezza minima: 7 caratteri",

            "newUser": "Nuovo utente",
            "user_breadcrumb": "Nuovo utente",
            "usersT": "Utenti",
            "newUserTitle": "Informazioni login",
            "newUserTitle2": "Anagrafica",

            /*SETTINGS */
            "theme": "Tema",
            "DARK": "Scuro",
            "LIGHT": "Chiaro",
            "NATURE": "Natura",
            "settings": "Impostazioni",
            "saveSettings": "Salva Impostazioni",
            "rtl": "Cambia direzione testo",
            "responsiveFont": "Grandezza font dinamica",
            "responsiveFontDescription": "Regola la dimensione dei font per device piccoli",
            "compact": "Compatto",
            "compactDescription": "Larghezza fissa in alcune risoluzioni",
            "roundBorders": "Bordi rotondi",
            "roundBordersDescription": "Aumenta il raddio dei bordi",

            /* GENERAL */
            "back": "Indietro",
            "actions": "Azioni",

            /* SIDEBAR */
            "users": "Utenti",
            "list": "Lista",
            "roles": "Ruoli e Permessi",


            /* TABLE */
            "of": "di",
            "tableNoData": "Nessun dato trovato",
            "select": "Seleziona",
            "to": "a",
            "search": "cerca",
            "memoFilters": "Memorizza Filtri",
            "resetFilters": "Reset Filtri",
            "view": "Mostra",
            "page": "Pagina",

            /* DATE FILTER */
            "startDate": "Data Inizio",
            "endDate": "Data Fine",
            "today": "Oggi",
            "yesterday": "Ieri",
            "tomorrow": "Domani",
            "week": "Settimana",
            "month": "Mese",
            "lastMonth": "Mese Scorso",
            "nextMonth": "Mese Prossimo",


            /* YEOMAN IT ANCHOR */
            "RuleVariables": "Variabili regole",
            "ruleVariable_update_save_btn": "Salva variabile",
            "ruleVariable_update_breadcrumb": "Aggiorna variabile",
            "ruleVariable_update_title": "Variabile regola: Aggiorna",
            "ruleVariable_update": "Aggiorna variabile",
            "ruleVariable_create_save_btn": "Salva variabile",
            "ruleVariable_breadcrumb": "Nuovo",
            "ruleVariable_title": "Variabile regola: Nuovo",
            "ruleVariable_new": "Nuova variabile regola",
            "ruleVariable_delete_button": "Elimina variabile",
            "ruleVariable_update_button": "Aggiorna variabile",
            "ruleVariable_new_button": "Nuova variabile",
            "ruleVariable_page_header": "Variabile regola",
        }
    },
    en: {
        translation: {
            "notifications":"Notifications",
            'loginHeader': 'Welcome',
            'loginDescription': 'Log in on the internal platform',
            'loginBtn': 'Log In',
            'demoLabel': 'Use',
            'forgotPwd': 'Recover password',
            "requiredField": "Required field",
            "notValidEmail": "Not valid email",
            'logout': 'Logout',
            "profile": "Account",
            "accountGeneral": "Overview",
            "accountSecurity": "Security",
            "setAvatar": "Set avatar",
            "removeAvatar": "Remove avatar",
            "profileT": "Profile",
            "firstName": "First name",
            "lastName": "Last name",
            "save": "Save",

            "changePwd": "Change password",
            "oldPwd": "Old password",
            "password": "Password",
            "confirmPassword": "Confirm password",
            "pwdMismatch": "Password must be identical",
            "pwdUpdated": "Password updated",
            "minLen7": "Min len: 7 characters",

            "newUser": "New user",
            "user_breadcrumb": "New user",
            "usersT": "Users",
            "newUserTitle": "Login credentials",
            "newUserTitle2": "Overview",

            /*ROLES*/
            "role": "Roles",
            "policy": "Policy",
            "groups": "Groups",
            "newRole": "New role",
            "roleInfo": "Role Info",
            "newPolicy": "New policy",
            "newGroup": "New group",
            "policySettings": "Policy settings",
            "policySettings1": "Policy settings",

            /*SETTINGS */
            "theme": "Theme",
            "DARK": "Dark",
            "LIGHT": "Light",
            "NATURE": "Nature",
            "settings": "Settings",
            "saveSettings": "Save settings",
            "rtl": "Change text direction",
            "responsiveFont": "Responsive font sizes",
            "responsiveFontDescription": "Adjust font for small devices",
            "compact": "Compact",
            "compactDescription": "Fixed width on some screens",
            "roundBorders": "Rounded Corners",
            "roundBordersDescription": "Increase border radius",

            /* GENERAL */
            "back": "Back",
            "actions": "Actions",

            /* SIDEBAR */
            "users": "Users",
            "list": "List",
            "roles": "Roles and permissions",

            /* TABLE */
            "of": "of",
            "tableNoData": "No data was found",
            "select": "Select",
            "to": "a",
            "memoFilters": "Remember Filters",
            "resetFilters": "Reset Filters",
            "view": "View",
            "page": "Page",

            /* DATE FILTER */
            "startDate": "Start Date",
            "endDate": "End Date",
            "today": "Today",
            "yesterday": "Yesterday",
            "tomorrow": "Tomorrow",
            "week": "Week",
            "month": "Month",
            "lastMonth": "Last Month",
            "nextMonth": "Next Month",



            /* YEOMAN EN ANCHOR */
            "ruleVariable_update_save_btn": "Save ruleVariable",
            "ruleVariable_update_breadcrumb": "Update ruleVariable",
            "ruleVariable_update_title": "ruleVariable: Update",
            "ruleVariable_update": "Update ruleVariable",
            "ruleVariable_create_save_btn": "Save ruleVariable",
            "ruleVariable_breadcrumb": "New ruleVariable",
            "ruleVariable_title": "ruleVariable: New",
            "ruleVariable_new": "New ruleVariable",
            "ruleVariable_delete_button": "Delete ruleVariable",
            "ruleVariable_update_button": "Update ruleVariable",
            "ruleVariable_new_button": "New ruleVariable",
            "ruleVariable_page_header": "ruleVariable",
            "RuleVariables": "Rules variables",


        }
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: window.localStorage.getItem('lang'),
        fallbackLng: 'it',
        interpolation: {
            escapeValue: false
        }
    });
