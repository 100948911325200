import type { FC } from "react";
import { useEffect, useState } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box, Breadcrumbs, Button,
    Container, Card,
    Grid,
    Link,
    IconButton,
    /* Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, */
    Typography,
    Tooltip,
    Chip,
    CircularProgress,
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Scrollbar from '../../material/Scrollbar';
import ChevronRightIcon from "../../icons/ChevronRight";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector as useReduxSelector } from '../../store'
import { deleteDocument, loadDocuments } from './commands.documenti'
import { downloadDocument } from "./commands.documenti";
import { Document, Exact } from "src/types/generated";
import ArrowRightIcon from '../../icons/ArrowRight';
import CodeIcon from '@material-ui/icons/Code';
import { useSnackbar } from "notistack";
import GraphqlClient from "src/client/graphql.client";

import {
    Table,
    SelectColumnFilter,
    SelectColumnFilterMethod,
    DatePickerRangeFilter,
    DatePickerRangeFilterMethod
} from '../../components/Table/Table'
import Label from "src/material/Label";

export const HandleDownloadDocument = async (id: { id: string }, isDownloadingState) => {
    isDownloadingState(true)
    const file = await downloadDocument(id)
    const bytes = atob(file.base64);
    let length = bytes.length;

    let out = new Uint8Array(length);
    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    let myBlob = new Blob([out], { type: "application/octet-stream" });


    const URLblobObj = window.URL.createObjectURL(myBlob);

    let a = document.createElement("a");
    a.style.display = "none";
    a.href = URLblobObj;
    a.download = file.filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(URLblobObj);
    isDownloadingState(false)
}

const DownloadPDFButton: FC<{ row: any }> = (props) => {
    const row = props.row;
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar()

    return (
        <>
            <Tooltip title={"Scarica documento"}>

                <IconButton
                    disabled={isDownloading}
                    aria-label='Download'
                    onClick={() => {
                        HandleDownloadDocument({ 'id': row.cell.row.original.id }, setIsDownloading)
                    }}
                >
                    {isDownloading ? <CircularProgress size={18} /> : <CloudDownloadIcon fontSize="small" />}
                </IconButton>
            </Tooltip>
        </>
    );
}

const PaginaDocumenti: FC<{ id?: string }> = (props: any) => {
    const state = useReduxSelector(state => state.documenti)
    const { settings } = useSettings();
    const { enqueueSnackbar } = useSnackbar()
    const { ...other } = props;

    console.log('STATE', state);
    const deleteEntry = async (id: string) => {
        try {
            let res = await deleteDocument(id)
            enqueueSnackbar("Documento eliminato", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
        } catch (e) {
            console.error('Impossibile eliminare il documento: ', e);
            enqueueSnackbar("Impossibile eliminare il documento", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } finally {
            loadDocuments(props.id)
        }
    }

    const eseguiRegole = async (documentID: string) => {
        try {
            enqueueSnackbar("Regole in esecuzione", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'warning'
            });
            const res = await GraphqlClient.runDocumentRules({ id: documentID })
            enqueueSnackbar("Regole eseguite", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
        } catch (e) {
            console.log(JSON.stringify(e));
            enqueueSnackbar("Impossibile eseguire le regole:" + e.response.errors[0].message, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            // Notification
        } finally {

        }
    }

    const isError = (documento: Document) => {
        if (documento.error) {
            return (
                <Tooltip title={documento.error}>
                    <ErrorIcon fontSize="medium" color="secondary"></ErrorIcon>
                </Tooltip>
            )
        } else {
            return (
                <CheckCircleIcon fontSize="medium" color="primary" />
            )
        }
    }
    useEffect(() => {
        loadDocuments(props.id);
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            loadDocuments(props.id);
        }, 3000);
        return () => clearInterval(interval);
    }, []);


    return (
        <Box sx={{ mt: 3 }}>
            <Card {...other}>
                <Scrollbar>
                    <Box sx={{ minWidth: 1200 }}>

                        <Table
                            Data={state.data || []}
                            Columns={
                                [
                                    {
                                        Header: 'Descrizione',
                                        accessor: 'description',
                                    },
                                    {
                                        Header: 'Stato',
                                        accessor: (row) => {
                                            switch (row.state) {
                                                case "processed":
                                                    return "processato"
                                                case "extracted":
                                                    return "in corso"
                                                case "uploaded":
                                                    return "caricato"
                                                case "cannot process":
                                                    return "errore"
                                                default:
                                                    return "-"
                                            }
                                        },
                                        Cell: (row) => {
                                            switch (row.value) {
                                                case "in corso":
                                                    return (
                                                        <Label color="warning">
                                                            <CircularProgress
                                                                classes={{ colorPrimary: "white" }}
                                                                size={13} />
                                                            <div style={{ marginLeft: "10px" }}>IN CORSO</div>
                                                        </Label>
                                                    )
                                                case "caricato":
                                                    return <Label color="warning">IMPORTATO</Label>
                                                case "processato":
                                                    return <Label color="success">REGOLE ESEGUITE</Label>
                                                case "errore":
                                                    return <Label color="error">ERRORE</Label>
                                            }
                                            return row.value;
                                        },
                                        id: 'state',
                                        Filter: SelectColumnFilter,
                                        filter: SelectColumnFilterMethod,
                                    },
                                    {
                                        Header: 'Tipo',
                                        accessor: 'documentType.name',
                                        id: 'type',
                                        Filter: SelectColumnFilter,
                                        filter: SelectColumnFilterMethod,
                                    },
                                    {
                                        Header: 'Metas',
                                        accessor: 'metas',
                                        Cell: (row) => {
                                            let metasArray = [];
                                            if (row.row.values.metas != null) {
                                                let metasObj = JSON.parse(row.row.values.metas)
                                                for (let i in metasObj) {
                                                    metasArray.push([i + ":" + metasObj[i]])
                                                }
                                                return (
                                                    <div>
                                                        {
                                                            metasArray.map(
                                                                tag => {
                                                                    return (
                                                                        <Label color="primary" sx={{ margin: '3px' }}>{tag}</Label>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </div>

                                                )

                                            } else {
                                                return 'no metatag'
                                            }
                                        }
                                    },
                                    {
                                        Header: 'Azioni',
                                        Cell: (row) => {

                                            return (
                                                <>
                                                    <Tooltip title={"Elimina"}>
                                                        <IconButton
                                                            aria-label='Elimina'
                                                            color="secondary"
                                                            onClick={() => deleteEntry(row.row.values.id)}
                                                        >
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Esegui regole"}>

                                                        <IconButton
                                                            aria-label='Esegui'
                                                            onClick={() => {
                                                                eseguiRegole(row.cell.row.original.id)
                                                            }}
                                                        >
                                                            <CodeIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <DownloadPDFButton row={row} />
                                                    <Tooltip title={"Visualizza dettagli documento"}>

                                                        <IconButton
                                                            aria-label='Dettagli'
                                                            component={RouterLink}
                                                            to={`/dossier/dettagli/${props.id}/documenti/${row.cell.row.original.id}`}
                                                        >
                                                            <ArrowRightIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            )
                                        }
                                    }
                                ]
                            }

                        />
                    </Box>
                </Scrollbar>
            </Card>
        </Box>
    );
}

export default PaginaDocumenti;
