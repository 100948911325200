import { createSlice } from '@reduxjs/toolkit'

const documentsSlice = createSlice({
  name: 'documenti',
  initialState: {
    loaded: false,
    data: [],
  },
  reducers: {
    load: (state, action) => {
        state.loaded = true
        state.data = action.payload
    }
  }
})

export const actions = documentsSlice.actions;
export default documentsSlice.reducer;
