import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
    Box, Breadcrumbs,
    /* Button, */
    Card,
    CardContent,
    CardHeader,
    Checkbox, CircularProgress, Container,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid, IconButton, Link,
    makeStyles,
    Tooltip,
    /* TextField, */
    Typography
} from '@material-ui/core';
import useSettings from "../../hooks/useSettings";
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import { DocumentType } from "../../types/generated";

/*****************************************/
import Button_MUI from '@material-ui/core/Button';
import { Button } from '../../components/Button/Button'
import { TextField } from '../../components/TextField/TextField'
import { FilePond } from "react-filepond";
import { createRuleValidation, requestPutSignedURL, runFromDoc, uploadValidationFile, uploadWithSignedURL } from "./commands.validazione";
import { FilePondErrorDescription, FilePondFile } from "filepond";
import { Table, DatePickerRangeFilter, DatePickerRangeFilterMethod } from 'src/components/Table/Table';
import { useSelector as useReduxSelector } from '../../store'
import PlayArrow from '@material-ui/icons/PlayArrow';
import dayjs from 'dayjs';
/*****************************************/

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    border: {
        borderBottom: "1px solid lightgray",
        width: "30%"
    },
    content: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        color: "lightgray"
    }
}));

const NuovaValidazione: FC = (props) => {
    const state = useReduxSelector(state => state.docValidation)
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { enqueueSnackbar } = useSnackbar();

    let filereader: FileReader;
    const [file, setFile] = useState([])
    const [fileBuffer, setFileBuffer] = useState<ArrayBuffer>(null)
    const [istitutoApp, setIstitutoApp] = useState('')
    const [fileError, setFileError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const DividerWithText = ({ children }) => {
        const classes = useStyles();
        return (
            <div className={classes.container}>
                <div className={classes.border} />
                <span className={classes.content}>{children}</span>
                <div className={classes.border} />
            </div>
        );
    };

    const handleValidation = async () => {
        setIsLoading(true)
        const signedURL = await requestPutSignedURL(file[0].filename)
        if (signedURL.error) {
            setIsLoading(false)
            enqueueSnackbar(signedURL.message, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                }, variant: "error"
            })
            return
        }

        const response = await uploadWithSignedURL(signedURL.data.url, fileBuffer)
        if (response.error) {
            setIsLoading(false)
            enqueueSnackbar("Impossibile caricare il file", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                }, variant: "error"
            })
            console.error(response.message)
            return
        }

        const url = "https://storage.googleapis.com/loan_import/" + signedURL.data.name
        const res = await createRuleValidation(file[0].filename, url, istitutoApp, signedURL.data.name)
        if (res.error) {
            setIsLoading(false)
            enqueueSnackbar("Impossibile avviare validazione", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                }, variant: "error"
            })
            console.error(res.message)
            return
        }

        setFile([])
        setFileBuffer(null)
        setIsLoading(false)
        enqueueSnackbar("Validazione avviata", {
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
            },
            variant: 'success'
        });
        navigate("/validazione");
    }

    const handleAdd = (error: FilePondErrorDescription, fpFile: FilePondFile) => {
        setFile([fpFile])
        filereader = new FileReader()
        filereader.readAsArrayBuffer(fpFile.file)
        filereader.onload = () => {
            const buffer = filereader.result as ArrayBuffer
            setFileBuffer(buffer)
        }
    }

    async function runValidationFromDocument(id: string) {
        const res = await runFromDoc(id)
        if (res.error) {
            console.error(res.message)
            enqueueSnackbar("Impossibile avviare la validazione", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return
        }

        enqueueSnackbar("Validazione avviata", {
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
            },
            variant: 'success'
        });
        navigate("/validazione")
    }

    return (
        <>
            <Helmet>
                <title>Validazione Regole: Nuova</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}>

                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}>
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Validazione Regole
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/validazione"
                                    variant="subtitle2"
                                >
                                    Validazione Regole
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Nuova
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowLeftIcon fontSize="small" />}
                                    sx={{ mt: 1 }}
                                    to="/validazione"
                                    variant="outlined"
                                    label='Indietro'
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ p: 3 }}>

                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <div>
                                            {isLoading ?
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                    padding: '15px',
                                                    marginBottom: '16px'
                                                }}>
                                                    <CircularProgress />
                                                </div>
                                                :
                                                <Box>
                                                    <TextField
                                                        required
                                                        label="Istituto Appartenenza"
                                                        onChange={(e) => setIstitutoApp(e.target.value)}
                                                        value={istitutoApp}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    <FilePond
                                                        files={file}
                                                        allowMultiple={false}
                                                        onaddfile={handleAdd}
                                                        onremovefile={() => setFile([])}
                                                        onerror={() => setFileError(true)}
                                                        allowReplace={false}
                                                        name="criteri"
                                                        acceptedFileTypes={['application/pdf']}
                                                        labelIdle='Trascina e lascia il documento o <span class="filepond--label-action">Cerca</span>'
                                                    />
                                                </Box>
                                            }

                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                disabled={file.length == 0 || istitutoApp.length == 0 || fileError || isLoading}
                                                variant="contained"
                                                onClick={() => handleValidation()}
                                                label="Avvia Validazione"
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                    <DividerWithText>
                        <Typography variant="subtitle2">
                            o utilizza un file esistente
                        </Typography>
                    </DividerWithText>
                    <Box sx={{ p: 3 }}>
                        <Card>
                            <CardContent>
                                <Table
                                    Data={state.data}
                                    Columns={[
                                        {
                                            Header: 'Descrizione',
                                            accessor: 'description',
                                        },
                                        {
                                            Header: 'Istituto',
                                            accessor: 'istitutoAppartenenza',
                                        },
                                        {
                                            Header: 'Caricato il',
                                            accessor: 'createdAt',
                                            Cell: (rows) => {
                                                return dayjs.unix(rows.row.values.createdAt).format('DD/MM/YYYY')
                                            },
                                            Filter: DatePickerRangeFilter,
                                            filter: DatePickerRangeFilterMethod
                                        },
                                        {
                                            Header: 'Azioni',
                                            Cell: (rows) => {
                                                return (
                                                    <div>
                                                        <Tooltip title="Avvia Validazione">
                                                            <IconButton
                                                                disabled={rows.row.original.urlOCR ? false : true}
                                                                aria-label='Avvia Validazione'
                                                                onClick={() => runValidationFromDocument(rows.row.original.id)}
                                                            >
                                                                <PlayArrow fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            }
                                        },
                                    ]}
                                />
                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default NuovaValidazione;
