import {useEffect, useState} from 'react';
import type {FC} from 'react';
import {Link as RouterLink, useNavigate, useLocation, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useSnackbar} from 'notistack';
import {
    Box, Breadcrumbs,
    Button,
    Card,
    CardContent,
    Skeleton,
    CardHeader,
    Checkbox, Container, Divider,
    FormControlLabel,
    FormHelperText,
    Grid, Link,
    TextField,
    Typography
} from '@material-ui/core';
import useSettings from "../../hooks/useSettings";
import {Helmet} from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import {createUser} from "./commands.user";
import wait from "../../utils/wait";
import GraphqlClient from "../../client/graphql.client";
import {User} from "src/types/generated";

const UpdateUser: FC = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const {settings} = useSettings();
    const {enqueueSnackbar} = useSnackbar();
    const [user, setUser] = useState({} as User);

    const [loadingData, setLoadingData] = useState(false);

    const id = params.id;

    useEffect(() => {

        const caricaUtente = async () => {
            try {
                setLoadingData(true)
                const res = await GraphqlClient.user({email: id});
                setUser(res.user);
            } catch (e) {
                console.error('Impossibile ottenere utente: ', e);
                enqueueSnackbar("Impossibile ottenere l'utente", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            } finally {
                setLoadingData(false);
            }
        }

        caricaUtente();
    }, [])

    return (
        <>
            <Helmet>
                <title>Dashboard: Modifica utente </title>
            </Helmet>

            <Box

                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Modifica utente: {id}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small"/>}
                                sx={{mt: 1}}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/users"
                                    variant="subtitle2"
                                >
                                    users
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Modifica utente
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{m: -1}}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowLeftIcon fontSize="small"/>}
                                    sx={{mt: 1}}
                                    to="/users"
                                    variant="outlined"
                                >
                                    Indietro
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{p: 3}}>

                        <Grid
                            container
                            md={12}
                            xs={12}
                            spacing={3}
                        >

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        password: '',
                                        confermaPassword: '',
                                        submit: null
                                    }}
                                    validationSchema={
                                        Yup
                                            .object()
                                            .shape({
                                                password: Yup.string().min(7, 'Lunghezza minima: 7 caratteri').max(20).required('Campo obbligatorio'),
                                                confermaPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Le password devono coincidere').max(30).required('Campo obbligatorio'),

                                            })
                                    }
                                    onSubmit={async (values, {
                                        setErrors,
                                        setStatus,
                                        setSubmitting
                                    }): Promise<void> => {
                                        try {
                                            setLoadingData(true);
                                            await GraphqlClient.updateUserPassword({email: user.email, newPassword: values.password})
                                            setStatus({success: true});
                                            setSubmitting(false);
                                            enqueueSnackbar('Password aggiornata', {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'success'
                                            });

                                        } catch (err) {
                                            console.error(err);
                                            const reqRes = JSON.parse(JSON.stringify(err))
                                            const message = reqRes.response.errors[0].message;
                                            setStatus({success: false});
                                            setErrors({submit: message});
                                            setSubmitting(false);
                                            setLoadingData(false);

                                        }finally {
                                            setLoadingData(false);
                                        }

                                    }}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          isSubmitting,
                                          setFieldValue,
                                          touched,
                                          values
                                      }): JSX.Element => (
                                        <form
                                            onSubmit={handleSubmit}
                                            {...props}
                                        >


                                            <Card>
                                                <CardContent>
                                                    {
                                                        loadingData ? <Skeleton width={300} height={30}/>
                                                            :
                                                            <CardHeader title="Cambio password"/>
                                                    }
                                                    <Divider/>
                                                    <Box sx={{mt: 2}}>
                                                        {
                                                            loadingData ? <Skeleton height={80}/>
                                                                :
                                                                <TextField
                                                                    error={Boolean(touched.password && errors.password)}
                                                                    fullWidth
                                                                    helperText={touched.password && errors.password}
                                                                    label="Password"
                                                                    name="password"
                                                                    type="password"

                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.password}
                                                                    variant="outlined"
                                                                />
                                                        }
                                                    </Box>
                                                    <Box sx={{mt: 2}}>
                                                        {
                                                            loadingData ? <Skeleton height={80}/>
                                                                :
                                                                <TextField
                                                                    error={Boolean(touched.confermaPassword && errors.confermaPassword)}
                                                                    fullWidth
                                                                    helperText={touched.confermaPassword && errors.confermaPassword}
                                                                    label="Conferma password"
                                                                    name="confermaPassword"
                                                                    type="password"

                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.confermaPassword}
                                                                    variant="outlined"
                                                                />}
                                                    </Box>
                                                </CardContent>
                                                <Divider/>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        p: 2
                                                    }}
                                                >
                                                    {
                                                        loadingData ? <Skeleton width={200} height={40}/>
                                                            :
                                                            <Button
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                                type="submit"
                                                                variant="contained"
                                                            >
                                                                Cambia password
                                                            </Button>
                                                    }
                                                </Box>
                                                {errors.submit && (
                                                    <Box sx={{p: 2}}>
                                                        <FormHelperText error>
                                                            {errors.submit}
                                                        </FormHelperText>
                                                    </Box>
                                                )}
                                            </Card>

                                        </form>
                                    )}
                                </Formik>
                            </Grid>

                            {/*Anagrafica*/}
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        nome: user.firstName,
                                        cognome: user.lastName,
                                        submit: null
                                    }}
                                    validationSchema={
                                        Yup
                                            .object()
                                            .shape({
                                                nome: Yup.string().max(30).required('Campo obbligatorio'),
                                                cognome: Yup.string().max(30).required('Campo obbligatorio'),
                                            })
                                    }
                                    onSubmit={async (values, {
                                        setErrors,
                                        setStatus,
                                        setSubmitting
                                    }): Promise<void> => {
                                        setSubmitting(true);

                                        let {cognome, nome} = values;
                                        try {
                                            await GraphqlClient.updateUser({
                                                email: id,
                                                user: {firstName: nome, lastName: cognome}
                                            });
                                            setStatus({success: true});
                                            setSubmitting(false);
                                            enqueueSnackbar('Utente aggiornato con successo', {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'success'
                                            });
                                            navigate('/users');
                                        } catch (e) {
                                            setStatus({success: false});
                                            setSubmitting(false);
                                            enqueueSnackbar(e.toString(), {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'error'
                                            });
                                            return;
                                        }
                                    }}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          isSubmitting,
                                          setFieldValue,
                                          touched,
                                          values
                                      }): JSX.Element => (
                                        <form
                                            onSubmit={handleSubmit}
                                            {...props}
                                        >


                                            <Card>
                                                <CardContent>
                                                    {
                                                        loadingData ? <Skeleton width={300} height={30}/>
                                                            :
                                                            <CardHeader title="Anagrafica"/>
                                                    }
                                                    <Divider/>
                                                    <Box sx={{mt: 2}}>
                                                        {
                                                            loadingData ? <Skeleton height={80}/>
                                                                :
                                                                <TextField
                                                                    error={Boolean(touched.nome && errors.nome)}
                                                                    fullWidth
                                                                    helperText={touched.nome && errors.nome}
                                                                    label="Nome"
                                                                    name="nome"

                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.nome}
                                                                    variant="outlined"
                                                                />
                                                        }
                                                    </Box>
                                                    <Box sx={{mt: 2}}>
                                                        {
                                                            loadingData ? <Skeleton height={80}/>
                                                                :
                                                                <TextField
                                                                    error={Boolean(touched.cognome && errors.cognome)}
                                                                    fullWidth
                                                                    helperText={touched.cognome && errors.cognome}
                                                                    label="Cognome"
                                                                    name="cognome"

                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.cognome}
                                                                    variant="outlined"
                                                                />
                                                        }
                                                    </Box>

                                                </CardContent>
                                                <Divider/>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        p: 2
                                                    }}
                                                >
                                                    {
                                                        loadingData ? <Skeleton width={200} height={40}/>
                                                            :
                                                            <Button
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                                type="submit"
                                                                variant="contained"
                                                            >
                                                                Salva
                                                            </Button>
                                                    }
                                                </Box>
                                            </Card>

                                        </form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Box>

                </Container>

            </Box>
        </>
    );
};

export default UpdateUser;
