import { FC } from 'react'
import { Document } from 'src/types/generated'
import {
  Box,
  Grid,
  Card,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  CardContent,
  TableBody,
  TableFooter,
  TextField,
  FilledInput
} from '@material-ui/core'
import Scrollbar from 'src/material/Scrollbar'
import SyntaxHighlighter from "react-syntax-highlighter";

const DettagliRawText: FC<Document> = (documento, props) => {
  console.log(documento.rawText)
  return (
    <Grid
      container
      spacing={3}
      {...props}
    >
      <Grid item style={{ flex: '1' }}>
        <Card>
          <CardContent>
            {documento.rawText}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default DettagliRawText
