import { createSlice } from '@reduxjs/toolkit'

const dossierExecLogs = createSlice({
  name: 'dossierExecutionLogs',
  initialState: {
    loaded: false,
    data: [],
  },
  reducers: {
    load: (state, action) => {
        state.loaded = true
        state.data = action.payload
    }
  }
})

export const actions = dossierExecLogs.actions;
export default dossierExecLogs.reducer;