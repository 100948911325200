import MonacoEditor from "react-monaco-editor";
import { FC, useState, useEffect, ChangeEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SaveIcon from "@material-ui/icons/Save";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/light";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  CircularProgress,
  IconButton,
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
} from "@material-ui/core";

import GraphqlClient from "src/client/graphql.client";
import { useSnackbar } from "notistack";
import { Dossier, DossierRule, UpdateDossierRule } from "src/types/generated";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { loadRegoleDossier } from "src/pages/Regole/commands.regole";
import LogRegole from "src/pages/dossier/components/LogRegole";
import ResultPlayground from "./result.play";
import ChipInput from "material-ui-chip-input";
import Scrollbar from "src/material/Scrollbar";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';


export const DownloadB64 = async (name, content) => {
  const bytes = atob(content);
  let length = bytes.length;

  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  let myBlob = new Blob([out], { type: "application/octet-stream" });


  const URLblobObj = window.URL.createObjectURL(myBlob);

  let a = document.createElement("a");
  a.style.display = "none";
  a.href = URLblobObj;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(URLblobObj);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "rgb(30,30,30)",
    position: "relative",
    overflow: "auto",
    maxHeight: "200px",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  button: {
    margin: theme.spacing(1),
  },
  clearBtn: {
    color: "#546e7a",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  btnConsole: {
    borderRadius: 3,
  },
}));

const editorTabs = [
  { label: "Codice", value: "codice" },
  { label: "helper.js", value: "helper" },
  { label: "package.json", value: "package" },
  { label: "Deve contenere", value: "mustContain" },
  { label: "Non deve contenere", value: "notContain" },
  { label: "Risultato", value: "result" },
  { label: "Log Esecuzione", value: "log" },
  { label: "Modifica", value: "edit" },
];

const PlaygroundAggiornaDossierRule: FC = (props) => {
  const [currentEditorTab, setCurrentEditorTab] = useState<string>("codice");

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [showConsole, setShowconsole] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const params = useParams();

  const id = params.id;

  let [code, setCode] = useState<string>("");
  let [packagecode, setPackageCode] = useState<string>("");
  let [helperCode, setHelperCode] = useState<string>("");
  let [mustContain, setMustContain] = useState("");
  let [notContain, setNotContain] = useState("");
  let [nota, setNota] = useState("");
  let [gruppo, setGruppo] = useState([]);

  const options = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    fixedOverflowWidgets: true,
  };
  const [name, setName] = useState<string>("");
  const [istitutoAppartenenza, setIstitutoAppartenenza] = useState<string>("");
  const [dossiers, setDossiers] = useState<Dossier[]>([] as Dossier[]);
  const [selectedDossier, setSelectedDossier] = useState<Dossier>({} as Dossier);
  const [selectedDossierInput, setSelectedDossierInput] = useState("");
  const [loadingRule, setLoadingRule] = useState(false);
  const [loadingRunRule, setLoadingRunRule] = useState(false);

  useEffect(() => {
    if (selectedDossier && selectedDossier.input) {
      const temp = JSON.parse(selectedDossier.input);
      setSelectedDossierInput(JSON.stringify(temp, null, 2));
    }
  }, [selectedDossier]);

  const [openDownload, setOpenDownload] = useState(false);

  const handleClickOpenDownload = () => {
    setOpenDownload(true);
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
  };


  const [response, setResponse] = useState("");

  const [rule, setRule] = useState<DossierRule>();

  const handleEditorTabsChange = (
    event: ChangeEvent<{}>,
    value: string
  ): void => {
    setCurrentEditorTab(value);
  };

  /* NOTA DIALOG */
  const [openNotaDialog, setOpenNotaDialog] = useState(false);

  const handleClickOpenNotaDialog = () => {
    setOpenNotaDialog(true);
  };

  const handleCloseNotaDialog = () => {
    setOpenNotaDialog(false);
  };

  const HandleDownloadRule = async (id) => {
    try {
      setLoadingRunRule(true);
      const res = await GraphqlClient.downloadDossierRule({
        id: id,
        ruleId: rule.id,
      })

      DownloadB64(res.downloadDossierRule.name, res.downloadDossierRule.bsae64Content);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Impossibile scaricare playground", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    } finally {
      setLoadingRunRule(false);
      handleCloseDownload()
      setCurrentEditorTab('result')
    }
  }

  const HandleRunRule = async (
    dossierID: string,
    code: string,
    packagecode: string
  ) => {
    try {
      setLoadingRunRule(true);
      const res = await GraphqlClient.runTemporaryDossierRule({
        id: dossierID,
        ruleID: rule.id,
        ruleName: rule.description,
        code: code,
        packagecode: packagecode,
      });
      setResponse(res.runTemporaryDossierRule);
      setCurrentEditorTab("result");
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Impossibile eseguire la regola", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
      // Notification
    } finally {
      setLoadingRunRule(false);
      handleClose();
      setShowconsole(true);
    }
  };

  const HandleRuleUpdate = async (
    newcode: string,
    packagecode: string,
    name: string,
    mustContain: string,
    notContain: string,
    notaToUpdate: string,
    gruppo: string[],
    istitutoAppartenenza: string,
  ) => {

    try {
      await GraphqlClient.updateHelperCode({ id: "dossier", code: helperCode });
    } catch (e) {
      console.log("helper code update error", e);
      enqueueSnackbar("Impossibile salvare helper.js", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }



    try {
      console.log("gruppo settato-> ", gruppo);
      let res = await GraphqlClient.updateDossierRule({
        id: rule.id,
        input: {
          istitutoAppartenenza: istitutoAppartenenza,
          description: name,
          code: newcode,
          packageJSON: packagecode,
          mustContain: JSON.parse(mustContain),
          notContain: JSON.parse(notContain),
          nota: notaToUpdate,
          gruppo: gruppo,
        } as UpdateDossierRule,
      });

      enqueueSnackbar("Regola salvata con successo", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "success",
      });
      loadRegoleDossier();
    } catch (e) {
      console.error("Impossibile salvare la rule: ", e);
      enqueueSnackbar("Impossibile salvare la rule", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const fetchRule = async (id: string) => {
      try {
        setLoadingRule(true);
        const res = await GraphqlClient.dossierRule({ id: id });
        setRule(res.dossierRule);
        setCode(res.dossierRule.code);
        setPackageCode(res.dossierRule.packageJSON);
        setName(res.dossierRule.description);
        setIstitutoAppartenenza(res.dossierRule.istitutoAppartenenza);
        setMustContain("[\n\t" + res.dossierRule.mustContain.join(",") + "\n]");
        setNotContain("[\n\t" + res.dossierRule.notContain.join(",") + "\n]");
        setNota(res.dossierRule.nota);
        setGruppo(res.dossierRule.gruppo ? res.dossierRule.gruppo : []);
      } catch (e) {
        enqueueSnackbar("Impossibile ottenere la regola", {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "error",
        });
      } finally {
        setLoadingRule(false);
      }
    };

    const loadDossiers = async () => {
      try {
        const res = await GraphqlClient.dossiers();
        setDossiers(res.dossiers);
        setSelectedDossier(res.dossiers[0]);
      } catch (e) {
        console.error("Impossibile ottenere tipologie: ", e);
        enqueueSnackbar("Impossibile ottenere le tipologie", {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "error",
        });
      }
    };

    const loadHelperCode = async () => {
      try {
        const res = await GraphqlClient.getHelperCode({ id: "dossier" });
        setHelperCode(res.getHelperCode.code);
      } catch (e) {
        console.error("Impossibile ottenere helper code: ", e);
        enqueueSnackbar("Impossibile ottenere helper code", {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "error",
        });
      }
    }

    loadHelperCode();
    fetchRule(id);
    loadDossiers();
  }, []);

  // @ts-ignore
  return (
    <>
      <Helmet>
        <title>Playground Dossier</title>
      </Helmet>
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            padding: 10,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "70%",
            }}
          >
            <Card
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                width: "100%",
              }}
            >
              <CardContent>
                <Typography
                  style={{
                    display: "inline-block",
                    width: "15%",
                    color: "grey",
                  }}
                  color="default"
                  variant="subtitle1"
                >
                  Nome regola:
                </Typography>
                <Typography
                  style={{ display: "inline-block", width: "80%" }}
                  variant="h6"
                >
                  {name}
                </Typography>
                {/* <Typography style={{ display: 'inline-block', width: '15%', color: 'grey' }} variant="subtitle1">
                                    Nota:
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '80%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', }} variant="h6">
                                    {nota}
                                </Typography> */}
                {/* <Typography style={{ display: 'inline-block', width: '15%' }} variant="subtitle1">
                                    Gruppo:
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '80%' }} variant="h6">
                                    {gruppo?.map(item => {
                                        return (<Chip label={item} variant='outlined' />)
                                    })}
                                </Typography> */}
              </CardContent>
            </Card>
            <Dialog
              open={openNotaDialog}
              onClose={handleCloseNotaDialog}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              maxWidth={"sm"}
            >
              <DialogTitle id="form-dialog-title">Nota</DialogTitle>
              <DialogContent>
                <TextField
                  label="Contenuto"
                  value={nota}
                  fullWidth
                  minRows={5}
                  multiline
                  onChange={(e) => {
                    setNota(e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseNotaDialog} color="primary">
                  Chiudi
                </Button>
                <Button onClick={handleCloseNotaDialog} color="primary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Tooltip title={"Ripristina le modifiche effettuate"}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                startIcon={<RotateLeftIcon />}
                onClick={() => {
                  setName(rule.description);
                  setCode(rule.code);
                  setPackageCode(rule.packageJSON);
                  setMustContain(JSON.stringify(rule.mustContain, null, 2));
                  setNotContain(JSON.stringify(rule.notContain, null, 2));
                  setNota(rule.nota);
                }}
              >
                Reset
              </Button>
            </Tooltip>
            <Tooltip title={"Salva le modifiche effettuate permanentemente"}>
              <Button
                variant="outlined"
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={() => {
                  HandleRuleUpdate(
                    code,
                    packagecode,
                    name,
                    mustContain,
                    notContain,
                    nota,
                    gruppo,
                    istitutoAppartenenza
                  );
                }}
              >
                Salva
              </Button>
            </Tooltip>
            <Tooltip title={"Scarica playground regola"}>
              <Button
                variant="outlined"
                className={classes.button}
                startIcon={<CloudDownloadIcon />}
                onClick={handleClickOpenDownload}
              >
                Playground
              </Button>
            </Tooltip>
            <Tooltip title={"Esegui la regola su un dossier"}>
              <Button
                variant="outlined"
                className={classes.button}
                startIcon={<PlayCircleOutlineIcon />}
                onClick={handleClickOpen}
              >
                Esegui
              </Button>
            </Tooltip>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                SELEZIONA DOSSIER
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Per eseguire la regola, seleziona un dossier su cui
                  applicarla.
                </DialogContentText>
                <Autocomplete
                  disabled={loadingRunRule}
                  onChange={(_, newValues: Dossier) => {
                    setSelectedDossier(newValues);
                  }}
                  id="combo-box-documents"
                  options={dossiers}
                  value={selectedDossier}
                  getOptionLabel={(option) => option?.id}
                  getOptionSelected={(option, value) =>
                    option?.id === value?.id
                  }
                  style={{ width: "100%", marginRight: "10px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Seleziona dossier"
                      variant="outlined"
                    />
                  )}
                />
                <Card variant="outlined" sx={{ mt: 1 }}>
                  <Scrollbar>
                    <Box sx={{ maxHeight: "260px" }}>
                      <SyntaxHighlighter
                        customStyle={{ backgroundColor: "transparent" }}
                        wrapLongLines={true}
                        wrapLines={true}
                        language="json"
                        style={atomOneDark}
                      >
                        {selectedDossierInput}
                      </SyntaxHighlighter>
                    </Box>
                  </Scrollbar>
                </Card>
              </DialogContent>
              <DialogActions>
                {loadingRunRule ? (
                  <CircularProgress size={35} />
                ) : (
                  <>
                    <Button
                      disabled={loadingRunRule}
                      onClick={handleClose}
                      color="primary"
                    >
                      Indietro
                    </Button>
                    <Button
                      disabled={loadingRunRule}
                      onClick={() =>
                        HandleRunRule(selectedDossier.id, code, packagecode)
                      }
                      color="primary"
                      variant="contained"
                    >
                      Esegui
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDownload}
              onClose={handleCloseDownload}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                SELEZIONA DOSSIER
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Seleziona dossier per cui scaricare il playground
                </DialogContentText>
                <Autocomplete
                  disabled={loadingRunRule}
                  onChange={(_, newValues: Dossier) => {
                    setSelectedDossier(newValues);
                  }}
                  id="combo-box-documents"
                  options={dossiers}
                  value={selectedDossier}
                  getOptionLabel={(option) => option?.id}
                  getOptionSelected={(option, value) =>
                    option?.id === value?.id
                  }
                  style={{ width: "100%", marginRight: "10px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Seleziona dossier"
                      variant="outlined"
                    />
                  )}
                />
                <Card variant="outlined" sx={{ mt: 1 }}>
                  <Scrollbar>
                    <Box sx={{ maxHeight: "260px" }}>
                      <SyntaxHighlighter
                        customStyle={{ backgroundColor: "transparent" }}
                        wrapLongLines={true}
                        wrapLines={true}
                        language="json"
                        style={atomOneDark}
                      >
                        {selectedDossierInput}
                      </SyntaxHighlighter>
                    </Box>
                  </Scrollbar>
                </Card>
              </DialogContent>
              <DialogActions>
                {loadingRunRule ? (
                  <CircularProgress size={35} />
                ) : (
                  <>
                    <Button
                      disabled={loadingRunRule}
                      onClick={handleClose}
                      color="primary"
                    >
                      Indietro
                    </Button>
                    <Button
                      disabled={loadingRunRule}
                      onClick={() =>
                        HandleDownloadRule(selectedDossier.id)
                      }
                      color="primary"
                      variant="contained"
                    >
                      Esegui
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <Tabs
          indicatorColor="primary"
          onChange={handleEditorTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentEditorTab}
          variant="scrollable"
        >
          {editorTabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        {currentEditorTab === "codice" && (
          <div
            style={{
              position: "relative",
              flex: 1,
              background: "rgb(30,30,30)",
            }}
          >
            <Divider style={{ margin: 3 }} />

            <MonacoEditor
              height="98%"
              width="100%"
              language="javascript"
              theme="vs-dark"
              value={code}
              options={options}
              onChange={(code) => setCode(code)}
            />
          </div>
        )}
        {currentEditorTab === "helper" && (
          <div
            style={{
              position: "relative",
              flex: 1,
              background: "rgb(30,30,30)",
            }}
          >
            <Divider style={{ margin: 3 }} />

            <MonacoEditor
              height="98%"
              width="100%"
              language="javascript"
              theme="vs-dark"
              value={helperCode}
              options={options}
              onChange={(code) => setHelperCode(code)}
            />
          </div>
        )}
        {currentEditorTab === "package" && (
          <div
            style={{
              position: "relative",
              flex: 1,
              background: "rgb(30,30,30)",
            }}
          >
            <Divider style={{ margin: 3 }} />

            <MonacoEditor
              height="98%"
              width="100%"
              language="json"
              theme="vs-dark"
              value={packagecode}
              options={options}
              onChange={(packagecode) => setPackageCode(packagecode)}
            />
          </div>
        )}
        {currentEditorTab === "mustContain" && (
          <div
            style={{
              position: "relative",
              flex: 1,
              background: "rgb(30,30,30)",
            }}
          >
            <Divider style={{ margin: 3 }} />

            <MonacoEditor
              height="98%"
              width="100%"
              language="json"
              theme="vs-dark"
              value={mustContain}
              options={options}
              onChange={(input) => setMustContain(input)}
            />
          </div>
        )}
        {currentEditorTab === "notContain" && (
          <div
            style={{
              position: "relative",
              flex: 1,
              background: "rgb(30,30,30)",
            }}
          >
            <Divider style={{ margin: 3 }} />

            <MonacoEditor
              height="98%"
              width="100%"
              language="json"
              theme="vs-dark"
              value={notContain}
              options={options}
            />
          </div>
        )}
        {currentEditorTab === "log" && <LogRegole ruleID={id} {...props} />}
        {response && currentEditorTab === "result" && (
          <ResultPlayground results={response} />
        )}
        {currentEditorTab === "edit" && (
          <div>
            <Container /* maxWidth={settings.compact ? 'xl' : false} */>
              <Box sx={{ mt: 3 }}>
                <Card /* {...other}  */ style={{ height: "100%" }}>
                  <CardContent style={{ height: "100%" }}>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      style={{ marginBottom: "10px" }}
                    >
                      Informazioni
                    </Typography>
                    <Grid container spacing={1} justifyContent="center">
                      <Grid item xs={12} lg={6} style={{ padding: 10 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <TextField
                            required
                            variant="outlined"
                            color="primary"
                            label="Nome"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            style={{
                              width: "100%",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={6} style={{ padding: 10 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <TextField
                            required
                            variant="outlined"
                            color="primary"
                            label="Istituto di appartenenza"
                            value={istitutoAppartenenza}
                            onChange={(e) => {
                              setIstitutoAppartenenza(e.target.value);
                            }}
                            style={{
                              width: "100%",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            marginBottom: "5px",
                          }}
                        >
                          <ChipInput
                            value={gruppo}
                            onAdd={(chip) => {
                              gruppo?.push(chip);
                            }}
                            onDelete={(chip, index) => {
                              gruppo?.splice(index, 1);
                            }}
                            label="Gruppo"
                            variant="outlined"
                            style={{
                              width: "100%",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={6} style={{ padding: 10 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            color="primary"
                            label="Nota"
                            value={nota}
                            onChange={(e) => {
                              setNota(e.target.value);
                            }}
                            style={{
                              width: "100%",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          />
                          <Tooltip title={"Espandi nota"}>
                            <IconButton
                              aria-label="Espandi"
                              color="primary"
                              onClick={() => {
                                setOpenNotaDialog(true);
                              }}
                            >
                              <NoteAddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Container>
          </div>
        )}
      </div>
    </>
  );
};

export default PlaygroundAggiornaDossierRule;
