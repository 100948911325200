// @ts-nocheck
import type { FC } from "react";
import { useEffect, useState } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box, Breadcrumbs, Button,
    Container, Card, Chip,
    Grid,
    Link,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Tooltip,
    CardContent,
    Divider,
    Stepper, Step, StepLabel, CardActions, CardHeader, Autocomplete,
} from '@material-ui/core';

import ChevronRightIcon from "../../icons/ChevronRight";
import { postData } from './commands.dossier'
import { useSnackbar } from "notistack";
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import { FilePondErrorDescription, FilePondFile } from "filepond";
import JSZip from 'jszip'
import GraphqlClient from "src/client/graphql.client";
import MonacoEditor from "react-monaco-editor";

// filepond plugin
/*
registerPlugin(FilePondPluginFileValidateType);
*/

function getSteps() {
    return ['Seleziona l\'archivio', 'Associa le tipologie', 'Inserisci gli input', 'Callback URL', 'Riepilogo'];
}

const mockInput = `[
    {
      "data_decorrenza_finanziamento": "",
      "durata_finanziamento": 24,
      "tipo_pratica": ""
    },
    {
      "data_decorrenza_finanziamento": "",
      "durata_finanziamento": 36,
      "tipo_pratica": ""
    },
    {
      "data_decorrenza_finanziamento": "",
      "durata_finanziamento": 48,
      "tipo_pratica": ""
    },
    {
      "data_decorrenza_finanziamento": "",
      "durata_finanziamento": 60,
      "tipo_pratica": ""
    },
    {
      "data_decorrenza_finanziamento": "",
      "durata_finanziamento": 72,
      "tipo_pratica": ""
    },
    {
      "data_decorrenza_finanziamento": "",
      "durata_finanziamento": 84,
      "tipo_pratica": ""
    },
    {
      "data_decorrenza_finanziamento": "",
      "durata_finanziamento": 96,
      "tipo_pratica": ""
    },
    {
      "data_decorrenza_finanziamento": "",
      "durata_finanziamento": 108,
      "tipo_pratica": ""
    },
    {
      "data_decorrenza_finanziamento": "",
      "durata_finanziamento": 120,
      "tipo_pratica": ""
    }
  ]`

const ImportDossier: FC = (props) => {
    const { settings } = useSettings();
    const [fileToUpload, setFileToUpload] = useState<string>()
    const [selectedTipoDossier, setSelectedTipoDossier] = useState<string>("cessione")
    const [dossierTypes, setDossierTypes] = useState([])
    const [docTypes, setDocTypes] = useState([])
    const [filenames, setFilenames] = useState([])
    const [file, setFile] = useState([])
    const [input, setInput] = useState(mockInput)
    const [callback, setCallback] = useState("")
    const [id, setID] = useState<string>()
    const [uploadOK, setUploadOK] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();

    const { ...other } = props;

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const options = {
        selectOnLineNumbers: true,
        automaticLayout: true,
        fixedOverflowWidgets: true
    };

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            handleSubmit()
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 1 || activeStep === 0) {
            setFilenames([])
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setFile([])
        setFilenames([])
        setID(null)
        setSelectedTipoDossier("cessione")
        setFileToUpload(null)
        setUploadOK(false)
        setActiveStep(0);
        setInput(mockInput)
        setCallback("")
    };

    const handleSubmit = async () => {
        let parsedInput: string
        try {
            const temp = JSON.parse(input)
            parsedInput = JSON.stringify(temp)
        } catch (err) {
            console.error(err)
            enqueueSnackbar("Errore sintassi nell'input", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            setActiveStep(1)
            return
        }

        // build json
        let object = {
            idValutazione: "",
            tipoContratto: selectedTipoDossier,
            base64zip: fileToUpload,
            files: filenames,
            input: parsedInput,
            callbackUrl: callback
        }

        console.log(object)
        const url = process.env.REACT_APP_ENDPOINT + '/import_dossier'

        try {
            await postData(url, object);
            enqueueSnackbar("Dossier caricato", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
            handleReset();

        } catch (e) {
            enqueueSnackbar("Errore" + e.toString(), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const getZipFiles = (error: FilePondErrorDescription, fpFile: FilePondFile) => {
        if (error) {
            console.log(error)
            enqueueSnackbar("Errore nello zip", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return
        }
        var zip = new JSZip();
        const zipFile = fpFile.file
        zip.loadAsync(zipFile)
            .then(zip => {
                zip.forEach((relativePath, zipEntry) => {
                    if (zipEntry.name.indexOf("__MACOSX") >= 0) return;
                    setFilenames(filenames => [...filenames, { nomeFile: zipEntry.name, tipoDocumento: "" }])
                })
            })

        getBase64(zipFile)
            .then(data => {
                let base64result = data as string
                let base64string = base64result.split(',')[1]
                setFileToUpload(base64string)
            })

        setFile([fpFile])
        setUploadOK(true)
    }

    const removeFile = (error: FilePondErrorDescription, fpFile: FilePondFile) => {
        if (error) {
            console.log(error)
            enqueueSnackbar("Errore nello zip", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return
        }
        setFile([])
        setFilenames([])
        setFileToUpload(null)
        setUploadOK(false)
    }

    const setTipoDoc = (value, index) => {
        let files = [...filenames]
        let entry = { ...files[index] }
        entry.tipoDocumento = value
        files[index] = entry
        setFilenames(files)
    }

    useEffect(() => {
        const loadTypes = async () => {
            try {
                const res = await GraphqlClient.documentTypes()
                const docs = res.documentTypes.map(doc => ({ ...doc, type: "Documento" }))
                setDocTypes(docs)

            } catch (e) {
                console.error('Impossibile ottenere tipologie: ', e);
                enqueueSnackbar("Impossibile ottenere le tipologie", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }

            try {
                const res = await GraphqlClient.dossierTypes()
                setDossierTypes(res.dossierTypes);
            } catch (e) {
                console.error('Impossibile ottenere tipologie: ', e);
                enqueueSnackbar("Impossibile ottenere le tipologie", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }

        loadTypes()
    }, [])

    return (
        <>
            <Helmet>
                <title>Import </title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Dossier
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dossier"
                                    variant="subtitle2"
                                >
                                    Dossier
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Import
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Card {...other}>
                            <CardContent>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </CardContent>
                            <Divider />
                            <CardContent>
                                {activeStep === steps.length ? (
                                    <div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "100px"
                                        }}>
                                            <Typography
                                                variant="h4"
                                            >
                                                Attendi la notifica...
                                            </Typography>
                                        </div>
                                        <Button onClick={handleReset}>Reset</Button>
                                    </div>
                                ) : (
                                    <div>
                                        {activeStep === 0 ? (
                                            <div>
                                                <FilePond
                                                    files={file}
                                                    allowMultiple={false}
                                                    onaddfile={getZipFiles}
                                                    onremovefile={removeFile}
                                                    name="archivio"
                                                    credits={false}
                                                    acceptedFileTypes={['application/zip', 'application/x-compressed', 'application/x-zip-compressed', 'multipart/x-zip']}
                                                    fileValidateTypeLabelExpectedTypes="{allTypes}"
                                                    labelIdle='Trascina e lascia lo zip o <span class="filepond--label-action">Cerca</span>'
                                                />
                                            </div>
                                        ) : activeStep === 1 ? (
                                            <Container>
                                                <Grid container spacing={0}>
                                                    {/* <Grid item xs={12} style={{ display: 'flex' }}>
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            width: "100%",
                                                        }}>
                                                            <Autocomplete
                                                                id="combo-box-dossier"
                                                                options={dossierTypes}
                                                                getOptionLabel={(option) => option.name}
                                                                style={{ width: 300 }}
                                                                onChange={(_, newValue) => {
                                                                    setSelectedTipoDossier(newValue.name)
                                                                }}
                                                                renderInput={(params) => <TextField {...params}
                                                                    label="Tipologia dossier"
                                                                    variant="outlined" />}
                                                            />
                                                        </div>
                                                    </Grid> */}
                                                    {filenames?.map((file, i) => (
                                                        <>
                                                            <Grid item xs={6}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100%",
                                                                    width: "100%",
                                                                }}>
                                                                    <div style={{ width: '100%', textAlign: "center" }}>
                                                                        <Typography
                                                                            key={"file_" + i}
                                                                            color="textPrimary"
                                                                            variant="h6"
                                                                            style={{ wordWrap: 'break-word' }}
                                                                        >
                                                                            {file.nomeFile}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                                <Divider />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100%",
                                                                    width: "100%",
                                                                    paddingTop: "5px",
                                                                    paddingBottom: "5px"
                                                                }}>
                                                                    <Autocomplete
                                                                        id={"combo-box-doc-" + i}
                                                                        options={docTypes}
                                                                        getOptionLabel={(option) => option.name}
                                                                        style={{ width: 300 }}
                                                                        onChange={(_, newValue) => {
                                                                            setTipoDoc(newValue.name, i)
                                                                        }}
                                                                        renderInput={(params) => <TextField {...params}
                                                                            label="Tipologia documento"
                                                                            variant="outlined" />}
                                                                    />
                                                                </div>
                                                                <Divider />
                                                            </Grid>
                                                        </>
                                                    ))}
                                                </Grid>
                                            </Container>
                                        ) : activeStep === 2 ? (
                                            <div style={{ height: '600px' }}>
                                                <MonacoEditor
                                                    height="98%"
                                                    width="100%"
                                                    language="json"
                                                    theme="vs-dark"
                                                    value={input}
                                                    options={options}
                                                    onChange={code => setInput(code)}
                                                />
                                            </div>
                                        ) : activeStep === 3 ? (
                                            <Box m={1}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <TextField
                                                        value={callback}
                                                        onChange={e => { setCallback(e.target.value) }}
                                                        label="Callback URL"
                                                        style={{ width: 300 }}
                                                    />
                                                </div>

                                            </Box>
                                        ) : (
                                            <Container>
                                                <Box m={2}>
                                                    <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                                        {/* <Typography variant="subtitle2" color="textSecondary" gutterbottom>
                                                            TIPOLOGIA DOSSIER: <Typography variant="h5" color="textPrimary">{selectedTipoDossier}</Typography>
                                                        </Typography> */}
                                                        <Typography variant="subtitle2" color="textSecondary" gutterbottom>
                                                            CALLBACK URL: <Typography variant="h5" color="textPrimary">{callback}</Typography>
                                                        </Typography>
                                                    </div>
                                                </Box>
                                                <Divider />
                                                <Box m={2}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="center">
                                                                    Documento
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    Tipologia Documento
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {filenames?.map((entry, i) => (
                                                                <TableRow id={"file-" + i}>
                                                                    <TableCell align="center" id={"field-nome-file-" + i}>
                                                                        {entry.nomeFile}
                                                                    </TableCell>
                                                                    <TableCell align="center" id={"field-tipo-file-" + i}>
                                                                        {entry.tipoDocumento}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Container>
                                        )}
                                        <br />
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                            >
                                                Indietro
                                            </Button>
                                            <Button
                                                disabled={!uploadOK}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >
                                                {activeStep === steps.length - 1 ? 'Carica' : 'Avanti'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default ImportDossier
