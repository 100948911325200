import {createSlice} from '@reduxjs/toolkit'

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
        logged: false,
        loading: false,
        accessToken: "",
        user: {},
        tokenRefreshed: false,
        refreshingToken: false,
        userPreImpersonation: {} as any,
        impersonificated: false,
        permissions: ""
    },
    reducers: {
        setAvatar: (state, action) => {
            // @ts-ignore
            state.user.avatar = action.payload
        },
        login: (state, action) => {
            state.logged = true
            state.user = action.payload.user
            state.accessToken = action.payload.accessToken
            state.permissions = action.payload.permissions
            state.tokenRefreshed = true
        },
        refreshingToken: (state) => {
            state.refreshingToken = true
            state.tokenRefreshed = false
            state.logged = false
            state.user = {}
        },
        refreshedToken: (state, action) => {
            state.logged = true
            state.user = action.payload.user
            state.accessToken = action.payload.accessToken
            state.permissions = action.payload.permissions
            state.tokenRefreshed = true
            state.refreshingToken = false
        },
        cannotRefreshToken: (state) => {
            state.refreshingToken = false
        },
        logout: (state) => {
            state.logged = false
            state.loading = false
        },
        impersonates: (state, action) => {
            state.userPreImpersonation = {
                user: state.user,
                accessToken: state.accessToken,
                permissions: state.permissions,
            }

            state.impersonificated = true
            state.user = action.payload.user
            state.accessToken = action.payload.accessToken
            state.permissions = action.payload.permissions

        },
        removeImpersonation: (state) => {
            state.impersonificated = false
            state.user = state.userPreImpersonation.user
            state.accessToken = state.userPreImpersonation.accessToken
            state.permissions = state.userPreImpersonation.permissions

        }
    },
})

export const actions = authenticationSlice.actions;
export default authenticationSlice.reducer;
