import {
  Button,
  Dialog, DialogActions, DialogTitle, DialogContent,
  Grid,
  Typography,
  Box,
  TextField,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Trash from 'src/icons/Trash';
import Upload from 'src/icons/Upload';
import { FilePond, registerPlugin } from 'react-filepond'
// Filepond Locales
import it_IT from 'filepond/locale/it-it.js';
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { importDossierRulesXLSX } from '../commands.play';
// Register the plugins
registerPlugin(FilePondPluginFileEncode)



interface Props {
  open: boolean
  handleClose: any
}

const ImportDossierRuleDialog = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  /* ------FILEPOND------ */
  const [files, setFiles] = useState([])
  const [enableButton, setEnableButton] = useState(false)

  async function handleUpload() {
    const res = await importDossierRulesXLSX(files[0].getFileEncodeBase64String())
    if (res.error) {
      console.error(res.message)
      enqueueSnackbar("Errore", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
      return
    }

    enqueueSnackbar("File caricato", {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      variant: 'success'
    });
    setFiles([])
    props.handleClose()
  }
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>
        Carica file ZIP
      </DialogTitle>
      <DialogContent sx={{ minWidth: '300px' }}>
        <FilePond
          allowFileEncode
          files={files}
          onupdatefiles={setFiles}
          onaddfile={() => setEnableButton(true)}
          onremovefile={() => setEnableButton(false)}
          allowMultiple={false}
          acceptedFileTypes={['zip']}
          maxFiles={1}
          name="import"
          {...it_IT}
        />

      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={props.handleClose}
        >
          Annulla
        </Button>
        <Button
          disabled={!enableButton}
          variant="contained"
          color="primary"
          startIcon={<Upload />}
          onClick={handleUpload}
        >
          Carica
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImportDossierRuleDialog
