import store from "../../store";

import GraphqlClient from "../../client/graphql.client";
import {NewDocumentRule, UpdateDocumentRule, UpdateDossierRule} from "../../types/generated";
import {NewDossierRule} from "../../types/generated";
import { actions as docActions } from "./redux.logDocs";
import { actions as dossierActions } from "./redux.logDossier";

export const newDossierRule = async (input:NewDossierRule) => {
  try {
    const res = await GraphqlClient.createDossierRule({input: input});
    return {data: res.createDossierRule}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const newDocumentRule = async (input:NewDocumentRule) => {
  try {
    const res = await GraphqlClient.createDocumentRule({input: input});
    return {data: res.createDocumentRule}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const updateDocumentRule = async (id: string, input:UpdateDocumentRule) => {
  try {
    const res = await GraphqlClient.updateDocumentRule({id: id, input: input});
    return {data: res.updateDocumentRule}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const updateDossierRule = async (id: string, input:UpdateDossierRule) => {
  try {
    const res = await GraphqlClient.updateDossierRule({id: id, input: input});
    return {data: res.updateDossierRule}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const deleteDocumentRule = async (id: string) => {
  try {
    const res = await GraphqlClient.deleteDocumentRule({id: id});
    return {data: res.deleteDocumentRule}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const deleteDossierRule = async (id: string) => {
  try {
    const res = await GraphqlClient.deleteDossierRule({id: id});
    return {data: res.deleteDossierRule}
  } catch (err) {
    return { error: true, message: err.toString() }
  }
}

export const testNewRule = async (docValidationID: string, mustContain: string[], notContain: string[]) => {
  try {
    const res = await GraphqlClient.testRule({docValidationID, mustContain, notContain});
    return {data: res.testRule}
  } catch (err) {
    return { error: true, message: err.toString() }
  }
}

export const fetchDossierLogs = async () => {
  try {
    const res = await GraphqlClient.getAllDossierCodeExecutions();
    store.dispatch(dossierActions.load(res.getAllDossierCodeExecutions))
  } catch (err) {
    return { error: true, message: err.toString() }
  }
}

export const fetchDocumentLogs = async () => {
  try {
    const res = await GraphqlClient.getAllDocumentCodeExecutions();
    store.dispatch(docActions.load(res.getAllDocumentCodeExecutions))
  } catch (err) {
    return { error: true, message: err.toString() }
  }
}

export const importDossierRulesXLSX = async (input: string) => {
  try {
    const res = await GraphqlClient.importDossierRules({input})
    return {data: res.importDossierRules}
  } catch (err) {
    return { error: true, message: err.toString() }
  }
}