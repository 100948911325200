import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box, Breadcrumbs,
  Button,
  Card,
  Container,
  Grid, Link,
  IconButton,
  Typography,
  Table, TableHead, TableCell, TableRow, TableBody,
  Collapse,
} from '@material-ui/core';
import Scrollbar from '../../material/Scrollbar';
import useSettings from "../../hooks/useSettings";
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { DossierRule, DossierType } from 'src/types/generated';
import { loadType } from './commands.tipodossier'
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function CollapseRow(props: DossierRule) {
  const { id, description, code } = props
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow key={id}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {id}
        </TableCell>
        <TableCell align="left">
          {description}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <SyntaxHighlighter language="javascript" style={atomOneDark}>
                {code}
              </SyntaxHighlighter>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const PaginaDettagliDossierType: FC = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [dostype, setDostype] = useState({} as DossierType);

  const { ...other } = props;

  const [loadingData, setLoadingData] = useState(false);

  const id = params.id;

  useEffect(() => {
    const caricaTipo = async () => {
      try {
        setLoadingData(true)
        const res = await loadType(id)
        setDostype(res.data)
      } catch (e) {
        console.error('Impossibile ottenere tipologia: ', e);
        enqueueSnackbar("Impossibile ottenere la tipologia", {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      } finally {
        setLoadingData(false);
      }
    }

    caricaTipo()
  }, [])

  return (
    <>
      <Helmet>
        <title>Dettagli: {id} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {id}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/tipologie/dossier"
                  variant="subtitle2"
                >
                  Tipologie Dossier
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {id}
                </Typography>
              </Breadcrumbs>

            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<ArrowLeftIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                  to="/tipologie/dossier"
                  variant="outlined"
                >
                  Indietro
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...other}>
              <Scrollbar>
                <Box sx={{ minWidth: 600 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell align="left">
                          ID
                        </TableCell>
                        <TableCell align="left">
                          Descrizione
                        </TableCell>
                        <TableCell align="left">
                          Index
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        dostype.dossierRules?.map((rule: DossierRule) => (
                          <CollapseRow 
                            key={rule.id} 
                            id={rule.id} 
                            code={rule.code} 
                            description={rule.description}
                            packageJSON={rule.packageJSON}
                            valid={rule.valid}
                            mustContain={rule.mustContain}
                            notContain={rule.notContain}
                            istitutoAppartenenza={rule.istitutoAppartenenza}
                          />
                        ))
                      }
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default PaginaDettagliDossierType
