import { Box, Card, CardContent, Chip, CircularProgress, Divider, IconButton, Tab, Tabs, Tooltip } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import JSONTree from 'react-json-tree'
import { fetchAggregateResults } from '../commands.dossier'
import { SelectColumnFilter, SelectColumnFilterMethod, Table } from "../../../components/Table/Table";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import dayjs from "dayjs";
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const tabs = [
    { label: 'Tabella', value: 'table' },
    { label: 'Pretty', value: 'pretty' },
];

const JSONThreeTheme = {
    scheme: "OneDark",
    author: "Lalit Magant",
    base00: "transparent",
    base01: "#353b45",
    base02: "#3e4451",
    base03: "#545862",
    base04: "#565c64",
    base05: "#abb2bf",
    base06: "#b6bdca",
    base07: "#c8ccd4",
    base08: "#e06c75",
    base09: "#d19a66",
    base0A: "#e5c07b",
    base0B: "#98c379",
    base0C: "#56b6c2",
    base0D: "#61afef",
    base0E: "#c678dd",
    base0F: "#be5046",
}

const SubRowEsecuzione: FC<{ row: any }> = (props) => {
    const row = props.row;
    const [currentSubTab, setCurrentSubTab] = useState<string>("input");
    const handleSubTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentSubTab(value);
    };
    return (
        <>
            <Box margin={1}>
                <JSONTree
                    data={row.original.Result}
                    hideRoot={true}
                    theme={JSONThreeTheme}
                    invertTheme={false}
                />
            </Box>
        </>
    );
}

const downloadJSON = (content: string, isDownloadingState) => {
    isDownloadingState(true)
    let myBlob = new Blob([content], { type: 'text/plain' });

    const URLblobObj = window.URL.createObjectURL(myBlob);

    let a = document.createElement("a");
    a.style.display = "none";
    a.href = URLblobObj;
    a.download = `rawAggregato.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(URLblobObj);
    isDownloadingState(false)
}


const RisultatiAggregati: FC<{ dossierID: string }> = (props) => {
    const [results, setResults] = useState([])
    const [stringResults, setStringResults] = useState("")
    const { enqueueSnackbar } = useSnackbar()
    const [currentResultTab, setCurrentResultTab] = useState<string>('table');
    const [isDownloading, setIsDownloading] = useState(false)

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentResultTab(value);
    };

    useEffect(() => {
        const loadAggregateResults = async () => {
            const res = await fetchAggregateResults(props.dossierID)
            if (res.error) {
                console.error(res.message);
                enqueueSnackbar("Impossibile eseguire le regole", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }

            setResults(JSON.parse(res.data))
        }

        loadAggregateResults()
    }, [])

    const getColor = index => {
        return index % 2 === 0 ? "#78909c" : "#546e7a";
    }

    useEffect(() => {
        setStringResults(JSON.stringify(results, null, 2))
    }, [results])

    const collapseRow = React.useCallback(
        ({ row }) => (
            <SubRowEsecuzione row={row} />
        ),
        []
    )

    return (
        <Box m={1}>
            <Card>
                <CardContent>
                    <Tabs
                        indicatorColor="primary"
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        textColor="primary"
                        value={currentResultTab}
                        variant="scrollable"
                    >
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.value}
                                label={tab.label}
                                value={tab.value}
                            />
                        ))}
                        <Box>
                            <Tooltip title="Download RAW.json">
                                <IconButton
                                    disabled={isDownloading}
                                    aria-label='Download'
                                    onClick={() => {
                                        downloadJSON(stringResults, setIsDownloading)
                                    }}
                                >
                                    {isDownloading ? <CircularProgress size={18} /> : <CloudDownloadIcon fontSize="small" />}
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Tabs>
                    <Divider />
                    <Box m={1}>
                        {
                            currentResultTab === "table" && <Table
                                Data={results.sort((a, b) => a.AggregateResult === false ? -1 : 0) || []}
                                RenderRowSubComponent={collapseRow}
                                Columns={[
                                    {
                                        Header: () => null,
                                        id: 'expander',
                                        width: 50,
                                        Cell: ({ row }) => (
                                            <span {...row.getToggleRowExpandedProps()}>
                                                {!row.isExpanded ?
                                                    <IconButton><KeyboardArrowDownIcon fontSize="small" /></IconButton> :
                                                    <IconButton><KeyboardArrowUpIcon fontSize="small" /></IconButton>}
                                            </span>
                                        ),
                                    },
                                    {
                                        Header: 'Input',
                                        accessor: (row) => {
                                            let arr = Object.keys(row.Input).map(key => `${key}:${row.Input[key]}`);
                                            return arr.join("");
                                        },
                                        Cell: ({ row }) => {
                                            let arr = Object.keys(row.original.Input).map(key => [key, `${row.original.Input[key]}`]);
                                            let res = arr.map((el, index) => <Chip
                                                label={<div style={{ display: 'flex' }}>
                                                    <div>{el[0]}</div>
                                                    :
                                                    <div style={{ fontWeight: 'bold' }}>{el[1]}</div>
                                                </div>}
                                                style={{ backgroundColor: getColor(index), marginLeft: '3px' }}
                                            />);

                                            return res;

                                        }
                                    },
                                    {
                                        Header: 'Risultato',
                                        accessor: (row) => {
                                            if (row.AggregateResult) return "Vero";
                                            return "Falso";
                                        },
                                        Cell: (row) => {
                                            if (row.value === "Vero") {
                                                return <Chip
                                                    label={"VERO"}
                                                    style={{
                                                        backgroundColor: 'green',
                                                        fontWeight: 'bold',
                                                        paddingLeft: 20,
                                                        paddingRight: 20
                                                    }}
                                                    icon={<DoneIcon />}
                                                />;
                                            }
                                            return <Chip
                                                label={"FALSO"}
                                                style={{
                                                    backgroundColor: '#c2185b',
                                                    fontWeight: 'bold',
                                                    paddingLeft: 20,
                                                    paddingRight: 20
                                                }}
                                                icon={<ErrorIcon />}
                                            />;

                                        },
                                        Filter: SelectColumnFilter,
                                        filter: SelectColumnFilterMethod,
                                    },

                                ]}
                            />
                        }

                        {currentResultTab === 'pretty' &&
                            <JSONTree
                                data={results}
                                hideRoot={true}
                                theme={JSONThreeTheme}
                                invertTheme={false}
                            />
                        }
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default RisultatiAggregati
