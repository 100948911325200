import type { FC } from "react";
import { useEffect, useState } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box, Breadcrumbs, Button,
    Container, Card, CardContent,
    Grid,
    Link,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';

import ArrowLeftIcon from '../../icons/ArrowLeft';
import ChevronRightIcon from '../../icons/ChevronRight';
import MonacoEditor from "react-monaco-editor";
import PlusIcon from "../../icons/Plus";

import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import { useSelector as useReduxSelector } from '../../store'
import { updateDatatable } from './commands.datatables'

const DettagliDatatables: FC = (props) => {
    const state = useReduxSelector(state => state.datatables)
    const { settings } = useSettings();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();

    const pagename = params.name

    let dt = state.data.find((e) => e.name === pagename)

    const { ...other } = props;

    let [name, setName] = useState<string>(pagename)
    let [content, setContent] = useState<string>(dt.content)

    const options = {
        selectOnLineNumbers: true,
        automaticLayout: true,
        fixedOverflowWidgets: true,
        formatOnPaste: true,
    };

    function editorDidMount(editor, monaco) {
        editor.getAction('editor.action.formatDocument').run();
    }

    const handleUpdate = async () => {
        const update = await updateDatatable(pagename, { name: pagename, content, ordinamento: 1 })
        if (update.error && update.error == true) {
            console.error(update.message);
            enqueueSnackbar("Errore nell'aggiornamento della tabella", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        } else {
            enqueueSnackbar('Tabella aggiornata con successo', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
        }
    }

    return (
        <>
            <Helmet>
                <title>Datatables </title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {name}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/datatables"
                                    variant="subtitle2"
                                >
                                    Datatables
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {name}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                startIcon={<PlusIcon fontSize="small" />}
                                variant="contained"
                                onClick={handleUpdate}
                            >
                                Aggiorna
                            </Button>
                            <Button
                                color="primary"
                                component={RouterLink}
                                startIcon={<ArrowLeftIcon fontSize="small" />}
                                sx={{ ml: 2 }}
                                variant="contained"
                                to="/datatables"
                            >
                                Indietro
                            </Button>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardContent>
                                <div style={{ minHeight: '600px', display: 'flex', flexDirection: 'column' }}>
                                    <div>
                                        <MonacoEditor
                                            height="600px"
                                            language="json"
                                            theme="vs-dark"
                                            options={options}
                                            value={content}
                                            onChange={content => setContent(content)}
                                            editorDidMount={editorDidMount.bind(this)}
                                        />
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default DettagliDatatables
