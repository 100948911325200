import store from "../../store";

import {actions} from './redux.documenti';
import GraphqlClient from "../../client/graphql.client";
import { Exact } from "src/types/generated";

export const loadDocuments = async (dossierId: string) => {
  try {
    const res = await GraphqlClient.documentsByDossier({dossier: dossierId});
    store.dispatch(actions.load(res.documentsByDossier))
  } catch (error) {
    return { loaded: false, message: error.toString() }
  }
};

export const loadDocument = async (id) => {
  try {
    const res = await GraphqlClient.document({id: id});
    store.dispatch(actions.load(res.document))
  } catch (error) {
    return { loaded: false, message: error.toString() }
  }
};

export const downloadDocument = async (id: {id: string}) => {
  try {
    const res = await GraphqlClient.downloadDocument(id);
    return {
      filename: res.downloadDocument.filename,
      extension: res.downloadDocument.extension,
      base64: res.downloadDocument.base64,
    };
  } catch (error) {
    return { loaded: false, message: error.toString() }
  }
};

export const deleteDocument = async (id: string) => {
  try {
    const res = await GraphqlClient.deleteDocument({id: id});
    return {data: res.deleteDocument}
  } catch (error) {
    return { error: true, message: error.toString() }
  }
}

export const updateMetas = async (id: string, metas: string) => {
  try {
    const res = await GraphqlClient.replaceDocumentMetas({id, metas})
    return {data: res.replaceDocumentMetas}
  } catch (err) {
    return {error: true, message: err.toString()}
  }
}
