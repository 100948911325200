import {
  Box,
  Card,
  CardContent,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  DatePickerRangeFilter,
  DatePickerRangeFilterMethod,
  SelectColumnFilter,
  SelectColumnFilterMethod,
  Table,
} from "src/components/Table/Table";
import { CountReport, DossierRuleExecution } from "src/types/generated";
import {
  countFailedExecutions,
  executionsReport,
  fetchExecutionsForDossier,
  loadDossiers,
} from "../commands.dossier";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DettaglioCodice from "src/pages/documenti/components/DettagliCodice";
import dayjs from "dayjs";
import Label from "src/material/Label";
import CachedIcon from "@material-ui/icons//Cached";
import GraphqlClient from "src/client/graphql.client";
import { useSnackbar } from "notistack";
import store, { useSelector } from "src/store";
import { fetchRuleExecsAsyncData } from "./redux/execs.redux";
import useToggle from "src/hooks/useToggle";

const SubRowEsecuzione: FC<{ row: any }> = (props) => {
  const row = props.row;
  const [currentSubTab, setCurrentSubTab] = useState<string>("input");
  const handleSubTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentSubTab(value);
  };

  return (
    <>
      <Box margin={1}>
        <Tabs
          indicatorColor="primary"
          onChange={handleSubTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentSubTab}
          variant="scrollable"
        >
          {["input", "result"].map((title) => (
            <Tab key={title} label={title} value={title} />
          ))}
        </Tabs>
        {currentSubTab === "input" && (
          <DettaglioCodice json={true} code={row.original.input} />
        )}
        {currentSubTab === "result" && (
          <DettaglioCodice json={true} code={row.original.result} />
        )}
      </Box>
    </>
  );
};

const StatusEsecuzione: FC<{ dossierID: string }> = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [report, setReport] = useState<CountReport>({} as CountReport);
  const [ruleExecutions, setRuleExecutions] = useState<DossierRuleExecution[]>(
    []
  );
  
  const [rulesWithError, setRulesWithError] = useState(0);

  const [loadingRunAgain, setLoadingRunAgain] = useState(false);

  const paginatedRuleExecs = useSelector(state => state.dossierRuleExecs)

  const runAgainRulesWithErrors = async () => {
    try {
      setLoadingRunAgain(true);
      await GraphqlClient.runAgainDossierRulesThatHasErrors({
        id: props.dossierID,
      });
    } catch (e) {
      enqueueSnackbar("Impossibile eseguire l'operazione", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      })
      console.error(e);
    } finally {
      setLoadingRunAgain(false);
    }
  };

  const loadReport = async () => {
    const res = await executionsReport(props.dossierID);
    if (res.error) {
      console.error(res.message);
      return;
    }

    setReport(res.data);
  };

  const collapseRow = React.useCallback(
    ({ row }) => <SubRowEsecuzione row={row} />,
    []
  );

  const ruleExecs = async () => {
    const res = await fetchExecutionsForDossier(props.dossierID);
    if (res.error) {
      console.error(res.message);
      return;
    }

    setRuleExecutions(res.data);
    let ruleError = 0;
    res.data?.forEach((execution) => {
      if (execution.state === "error") ruleError += 1;
    });

    setRulesWithError(ruleError);
  };

  const failedRules = async () => {
    const res = await countFailedExecutions(props.dossierID)
    if (res.error) {
      console.error(res.message)
      return
    }

    setRulesWithError(res.data)
  }

  useEffect(() => {
    loadReport();
    failedRules();
  }, [props]);


  const fetchIdRef = useRef(0)
  const [refresh, toggleRefresh] = useToggle(false)
  const fetchData = useCallback((records, page, orderBy, filters, selectOptions) => {
    const fetchId = ++fetchIdRef.current
    const sumColumns = []
    const avgColumns = []

    if (props.dossierID) {
      filters.push({ column: 'dossier_id', value: [props.dossierID], type: 'text' })
    }

    if (fetchId === fetchIdRef.current) {
      store.dispatch(fetchRuleExecsAsyncData({ records, page, orderBy, filters, selectOptions, sumColumns, avgColumns }))
    }
  }, [props])

  useEffect(() => {
    const interval = setInterval(() => {
      toggleRefresh();
      loadReport();
      failedRules();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (paginatedRuleExecs.error != null) {
      console.log(paginatedRuleExecs.error)
      enqueueSnackbar('Errore', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  }, [paginatedRuleExecs.error])

  return (
    <>
      <Box m={1}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h6" color="textSecondary">
                    REGOLE ESEGUITE
                    <Divider />
                    <Typography variant="h5" color="textPrimary">
                      {report?.processed}
                    </Typography>
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h6" color="textSecondary">
                    REGOLE TOTALI
                    <Divider />
                    <Typography variant="h5" color="textPrimary">
                      {report?.total}
                    </Typography>
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box m={1}>
        {rulesWithError > 0 && (
          <Button
            color="secondary"
            startIcon={<CachedIcon fontSize="small" />}
            sx={{ m: 1 }}
            disabled={loadingRunAgain}
            onClick={() => runAgainRulesWithErrors()}
            variant="contained"
          >
            Esegui nuovamente {rulesWithError > 1 ? `${rulesWithError} regole` : `${rulesWithError} regola`}  in stato di errore
          </Button>
        )}

        <Card>
          <Table
            refresh={refresh}
            fetchData={fetchData}
            pageCount={paginatedRuleExecs.pages}
            loading={false}
            selectFilterColumns={['state']} // nome della colonna su Postgres su cui applicare il filtro select
            selectFilterOptions={[
              {
                column: 'state',
                options: ["executed", "error", "created"]
              }
            ]}
            idDefaultColumnSort="createdAt"
            Data={paginatedRuleExecs.data ?? []}
            RenderRowSubComponent={collapseRow}
            Columns={[
              {
                Header: () => null,
                id: "expander",
                width: 50,
                Cell: ({ row }) => (
                  <span {...row.getToggleRowExpandedProps()}>
                    {!row.isExpanded ? (
                      <IconButton>
                        <KeyboardArrowDownIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton>
                        <KeyboardArrowUpIcon fontSize="small" />
                      </IconButton>
                    )}
                  </span>
                ),
              },
              {
                Header: "Stato",
                accessor: 'state',
                Cell: ({ row }) => {
                  switch (row.values.state) {
                    case "error":
                      return <Label color="error">ERRORE</Label>;
                    case "executed":
                      return <Label color="success">ESEGUITA</Label>;
                    case "created":
                      return (
                        <Label color="warning">
                          <CircularProgress
                            classes={{ colorPrimary: "white" }}
                            size={13}
                          />
                          <div style={{ marginLeft: "10px" }}>IN CODA</div>
                        </Label>
                      );
                  }
                  return row.values.Stato;
                },
                Filter: SelectColumnFilter,
                filter: SelectColumnFilterMethod,
              },
              {
                Header: "Regola",
                accessor: "ruleName",
                disableFilters: true,
                disableSortBy: true,
              },
              {
                Header: "Piattaforma",
                accessor: "platform",
                disableFilters: true,
                disableSortBy: true,
              },
              {
                Header: "Avviata alle",
                accessor: "createdAt",
                Cell: (row) => {
                  return dayjs
                    .unix(row.row.original.createdAt)
                    .format("DD/MM/YYYY HH:mm:ss");
                },
                Filter: DatePickerRangeFilter,
              },
              {
                Header: "Terminata alle",
                accessor: "finishedAt",
                Cell: (row) => {
                  return row.row.original.finishedAt ? dayjs
                  .unix(row.row.original.finishedAt)
                  .format("DD/MM/YYYY HH:mm:ss") : "";
                },
                Filter: DatePickerRangeFilter,
              },
              {
                Header: "Note",
                accessor: "error",
                disableFilters: true,
                disableSortBy: true,
              },
            ]}
          />
        </Card>
      </Box>
    </>
  );
};

export default StatusEsecuzione;
