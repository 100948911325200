import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GraphqlClient from 'src/client/graphql.client'
import { TableInput } from 'src/types/generated'

const fetchTableData = createAsyncThunk(
  'table/dossier',
  async (payload: TableInput, thunkAPI) => {
    const current = thunkAPI.getState()
    
    // if (current.dossier.options.length > 0) {
    //   payload.selectOptions = []
    // }

    const response = await GraphqlClient.getPaginatedDossier({ input: payload })
    return response.getPaginatedDossier
  }
)

const dossierSlice = createSlice({
  name: 'dossier',
  initialState: {
    loaded: false,
    data: [],
    pages: 0,
    options: [],
    op: [],
    error: null
  },
  reducers: {
    load: (state, action) => {
        state.loaded = true
        state.data = action.payload
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchTableData.pending, (state, action) => {
      state.error = null
      state.loaded = true
    })

    builder.addCase(fetchTableData.fulfilled, (state, action) => {
      state.data = action.payload.items
      state.pages = action.payload.pages
      // state.options = action.payload.options
      state.op = action.payload.op
      state.loaded = false
    })

    builder.addCase(fetchTableData.rejected, (state, action) => {
      state.error = action.error
      state.loaded = false
    })
  },
})

export const actions = dossierSlice.actions;
export default dossierSlice.reducer;
export const fetchAsyncData = fetchTableData