import { ChangeEvent, FC, useEffect, useState } from 'react'
import { DocumentRule } from 'src/types/generated'
import { useSnackbar } from 'notistack'
import GraphqlClient from 'src/client/graphql.client'
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../../hooks/useSettings';
import {
  Box, Breadcrumbs, Button,
  Container, Card, Chip,
  Grid,
  Link,
  IconButton,
  Typography,
  Tooltip,
  Skeleton,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import Label from "src/material/Label";

import { Table, SelectColumnFilter, SelectColumnFilterMethod } from '../../../components/Table/Table'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Scrollbar from '../../../material/Scrollbar';
import ChevronRightIcon from "../../../icons/ChevronRight";
import PlusIcon from "../../../icons/Plus";
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightIcon from '../../../icons/ArrowRight';
import { useSelector as useReduxSelector } from '../../../store'
import { loadRegole } from '../../Regole/commands.regole'
import { deleteDocumentRule } from '../commands.play';
import LogRegole from 'src/pages/documenti/components/RegoleEsegute';
import DeleteDocRuleDialog from './delete.dialog';
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";

const tabs = [
  { label: 'Generale', value: 'generale' },
  { label: 'Log Regole', value: 'logregole' },
];


const PlaygroundDocumenti: FC = (props) => {
  const state = useReduxSelector(state => state.regole)
  const { settings } = useSettings();
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [rules, setRules] = useState([] as DocumentRule[])
  const { enqueueSnackbar } = useSnackbar();

  const [currentTab, setCurrentTab] = useState<string>('generale');

  const { ...other } = props;

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const setActive = async (id: string, active) => {
    try {
      let res = await GraphqlClient.setDocumentRuleActive({ id, active });
      loadRegole();
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar("Impossibile aggiornare stato", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };


  /* DELETE DIALOG */
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteID, setDeleteID] = useState("")
  const handleOpenDeleteDialog = (id: string) => {
    setDeleteID(id)
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteID("")
    setOpenDeleteDialog(false)
  }
  /* ------------- */

  useEffect(() => {
    loadRegole();
    console.log(state.data)
  }, [])

  return (
    <>
      <Helmet>
        <title>Playground </title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <DeleteDocRuleDialog open={openDeleteDialog} handleClose={handleCloseDeleteDialog} id={deleteID} />
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Playground Documenti
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Playground Documenti
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  to="/playground/documenti/nuova"
                >
                  Nuova regola
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {currentTab === 'logregole' && <LogRegole id={null} {...props} />}
            {currentTab === 'generale' &&
              <Card {...other}>
                <Scrollbar>
                  <Box>
                    <Table
                      Data={state.data}
                      idDefaultColumnSort="description"
                      Columns={
                        [
                          {
                            Header: 'Description',
                            accessor: 'description',
                            filter: 'fuzzyText',                            
                          },
                          {
                            Header: 'Tipologia',
                            accessor: 'documentType.name',
                            id: 'tipologie',
                            Filter: SelectColumnFilter,
                            filter: SelectColumnFilterMethod,
                          },
                          {
                            Header: 'Nota',
                            accessor: 'nota',
                            Cell: (row) => {
                              return (
                                <div style={{textOverflow:'ellipsis', overflow: 'hidden', whiteSpace:'nowrap'}}>
                                  {row.row.original.nota}
                                </div>
                              )
                            }                 
                          },
                          {
                            Header: "Attiva",
                            width: 60,
                            accessor: (row) => (row.active ? "SI" : "NO"),
                            Cell: (row) => {
                              switch (row.value) {
                                case "SI":
                                  return <Label color="success">SI</Label>;
                                case "NO":
                                  return <Label color="error">NO</Label>;
                              }
                              return row.value;
                            },
                            Filter: SelectColumnFilter,
                            filter: SelectColumnFilterMethod,
                          },
                          {
                            Header: 'Azioni',
                            width: 60,
                            Cell: (row) => {
                              return (
                                <>
                                 {!row.row.original.active ? (
                                <Tooltip title={"Attive regola"}>
                                  <IconButton
                                    aria-label="Attiva regola"
                                    color="primary"
                                    onClick={() =>
                                      setActive(row.row.original.id, true)
                                    }
                                  >
                                    <PlayCircleOutlineIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title={"Metti regola in pausa"}>
                                  <IconButton
                                    aria-label="Metti regola in pausa"
                                    color="secondary"
                                    onClick={() =>
                                      setActive(row.row.original.id, false)
                                    }
                                  >
                                    <PauseCircleOutlineIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                                  <Tooltip title={"Elimina"}>
                                    <IconButton
                                      aria-label='Elimina'
                                      color="secondary"
                                      onClick={() => handleOpenDeleteDialog(row.row.original.id)}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={"Visualizza dettagli"}>
                                    <IconButton
                                      aria-label='Dettagli'
                                      component={RouterLink}
                                      to={`/playground/documenti/${row?.row?.original?.id}/aggiorna`}
                                    >
                                      <ArrowRightIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )

                            }
                          },
                        ]
                      }
                    />
                  </Box>
                </Scrollbar>
              </Card>
            }
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default PlaygroundDocumenti
