import React from 'react';
import type { FC } from 'react';
import Autocomplete_MUI from '@material-ui/lab/Autocomplete';
import TextField_MUI from '@material-ui/core/TextField';


export interface AutocompleteProps {
    value?: any;
    onChange?: any;
    id?: string;
    options?: any;
    getOptionLabel?: any;
    getOptionSelected?: any;
    style?: any;
    renderInput?:any;
    groupBy?: any;
    multiple?;
    variant?: 'outlined'|'filled';
    label?: string;
    disabled?: any;
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
    id,
    options,
    variant,
    label,
    ...props
}) => {


    return (
        <Autocomplete_MUI
            id={id}
            options={options}
            renderInput={(params) => (<TextField_MUI {...params} label={label} variant={variant} />)}
            {...props}
        />
    );


}